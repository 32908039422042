import {
  SAVE_BUILDING_CODE,
  REMOVE_BUILDING_CODE,
  REMOVE_FOUNDBUILDING,
} from '../actionTypes';
import { addError } from './errors';

export const saveBuildingStorage = (id, code) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        window.localStorage.setItem('lobbea-buildingId', id);
        window.localStorage.setItem('lobbea-buildingCode', code);
        dispatch({
          type: SAVE_BUILDING_CODE,
          payload: {
            id,
            code,
          },
        });
        resolve();
      } catch (err) {
        dispatch(addError(err?.message));
        reject();
      }
    });
  };
};

export const removeBuildingStorage = () => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        window.localStorage.removeItem('lobbea-buildingId');
        window.localStorage.removeItem('lobbea-buildingCode');
        dispatch({
          type: REMOVE_BUILDING_CODE,
        });
        dispatch({
          type: REMOVE_FOUNDBUILDING,
        });
        resolve();
      } catch (err) {
        dispatch(addError(err?.message));
        reject();
      }
    });
  };
};
