import { SAVE_BUILDING_CODE, REMOVE_BUILDING_CODE } from '../actionTypes';

const initialState = {
  building: {
    id: window.localStorage.getItem('lobbea-buildingId') ?? "",
    code: window.localStorage.getItem('lobbea-buildingCode') ?? "",
  },
};

export const landing = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BUILDING_CODE:
      return {
        ...initialState,
        building: {
          ...initialState.building,
          id: action.payload.id,
          code: action.payload.code,
        },
      };
    case REMOVE_BUILDING_CODE:
      return {
        ...initialState,
        building: {
          ...initialState.building,
          id: "",
          code: "",
        },
      };
    default:
      return state;
  }
};
