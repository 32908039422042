export const SAVE_BUILDING_CODE = 'SAVE_BUILDING_CODE';
export const REMOVE_BUILDING_CODE = 'REMOVE_BUILDING_CODE';
export const REMOVE_FOUNDBUILDING = 'REMOVE_FOUNDBUILDING';

export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const ADD_SUCCESS = 'ADD_SUCCESS';
export const REMOVE_SUCCESS = 'REMOVE_SUCCESS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_IMAGE = 'SET_CURRENT_USER_IMAGE';
export const USER_lOGOUT = 'USER_lOGOUT';
export const LOAD_BUILDINGS = 'LOAD_BUILDINGS';
export const LOAD_FOUNDBUILDING = 'LOAD_FOUNDBUILDING';
export const LOAD_BUILDING = 'LOAD_BUILDING';
export const SEARCH_BUILDINGS = 'SEARCH_BUILDINGS';
export const SEARCH_BUILDING = 'SEARCH_BUILDING';
export const CREATE_BUILDING = 'CREATE_BUILDING';
export const ADD_BUILDINGIMAGE = 'ADD_BUILDINGIMAGE';
export const LOAD_MYBUILDINGS = 'LOAD_MYBUILDINGS';
export const LOAD_MYNEIGHBORS = 'LOAD_MYNEIGHBORS';

export const POST_FEED = 'POST_FEED';
export const EDIT_FEED = 'EDIT_FEED';
export const REMOVE_FEED = 'REMOVE_FEED';
export const LIKE_FEED = 'LIKE_FEED';

export const POST_COMMENT = 'POST_COMMENT';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';

export const LOAD_MYCONVERSATION = 'LOAD_MYCONVERSATION';
export const LOAD_MYMESSAGES = 'LOAD_MYMESSAGES';
export const REMOVE_CONVERSATION = 'REMOVE_CONVERSATION';
export const POST_MESSAGE = 'POST_MESSAGE';
export const LOAD_ITEMS = 'LOAD_ITEMS';
export const POST_ITEM = 'POST_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';

export const LOAD_EVENTS = 'LOAD_EVENTS';
export const LOAD_EVENT_PAGE = 'LOAD_EVENT_PAGE';
export const LOAD_EVENT_FEED = 'LOAD_EVENT_FEED';
export const POST_EVENT = 'POST_EVENT';
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const POSTPARTICIPANT_EVENT = 'POSTPARTICIPANT_EVENT';
export const LIKE_EVENT_FEED = 'LIKE_EVENT_FEED';
export const POST_EVENT_COMMENT = 'POST_EVENT_COMMENT';
export const POST_EVENT_FEED = 'POST_EVENT_FEED';
export const EDIT_EVENT_FEED = 'EDIT_EVENT_FEED';
export const EDIT_EVENT_COMMENT = 'EDIT_EVENT_COMMENT';
export const REMOVE_EVENT_FEED = 'REMOVE_EVENT_FEED';
export const REMOVE_EVENT_COMMENT = 'REMOVE_EVENT_COMMENT';

export const LOAD_BORROWS = 'LOAD_BORROWS';
export const POST_BORROW = 'POST_BORROW';
export const REMOVE_BORROW = 'REMOVE_BORROW';
export const LOAD_PROFILE = 'LOAD_PROFILE';
export const LOAD_PROFILE_IMAGE = 'LOAD_PROFILE_IMAGE';
export const LOAD_PROFILEERROR = 'LOAD_PROFILEERROR';
export const EDIT_USEREMAIL = 'EDIT_USEREMAIL';

export const LOAD_GROUPS = 'LOAD_GROUPS';
export const CREATE_GROUP = 'CREATE_GROUP';
export const POST_GROUPFEED = 'POST_GROUPFEED';
export const POST_GROUPCOMMENT = 'POST_GROUPCOMMENT';
export const LOAD_MYGROUPS = 'LOAD_MYGROUPS';
export const JOIN_GROUP = 'JOIN_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const LOAD_GROUP_PAGE = 'LOAD_GROUP_PAGE';
export const LEAVE_GROUP = 'LEAVE_GROUP';
export const LIKE_GROUPFEED = 'LIKE_GROUPFEED';
export const POST_FORGET = 'POST_FORGET';
export const POST_RESET = 'POST_RESET';
export const REMOVE_GROUP_FEED = 'REMOVE_GROUP_FEED';
export const EDIT_GROUP_FEED = 'EDIT_GROUP_FEED';
export const EDIT_GROUP_COMMENT = 'EDIT_GROUP_COMMENT';
export const REMOVE_GROUP_COMMENT = 'REMOVE_GROUP_COMMENT';

export const LOAD_MYNOTIFICATIONS = 'LOAD_MYNOTIFICATIONS';
export const LOAD_MYMESSAGENOTIFICATIONS = 'LOAD_MYMESSAGENOTIFICATIONS';
export const READ_MESSAGENOTIFICATION = 'READ_MESSAGENOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const FEEDS_OPENED = 'FEEDS_OPENED';
