import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as InboxActions from '../../../store/actions/inbox';
import { FormGroup } from 'reactstrap';
import { AiOutlineLoading } from 'react-icons/ai';

function Compose({
  setModal,
  errors,
  inbox,
  currentUser,
  audienceId,
  sendMessage,
  historyRoute,
  historyState,
  messagePrefix = '',
}) {
  const navigate = useNavigate();
  const [sending, setSending] = useState(false);
  const [theMessage, setTheMessage] = useState('');
  const [attrDisabled, setAttrDisabled] = useState({});

  function handleSentMessage(e) {
    e.preventDefault();
    setSending(true);
    setAttrDisabled({ disabled: true });
    sendMessage({
      userId: currentUser.user.id,
      recipientId: audienceId,
      theMessage: messagePrefix + theMessage,
    });
    setModal(false);
    navigate(`/${historyRoute}`, { state: `${historyState}` });
  }

  useEffect(() => {
    // if (sending && errors.message === null) {
    //   alert('Message sent successfully');
    // }
    setSending(false);
    setAttrDisabled({});
    setTheMessage('');
  }, [inbox.messages]);

  return (
    <div className="compose grid">
      <form className="compose-form" onSubmit={handleSentMessage}>
        <FormGroup>
          {errors.message && (
            <div className="col-12 alert alert-danger">{errors.message}</div>
          )}
          <textarea
            rows="4"
            autoComplete="off"
            className="form-control"
            name="text"
            onChange={e => setTheMessage(e.target.value)}
            value={theMessage}
            {...attrDisabled}
            type="text"
            placeholder="Write your message"
            required
          />
        </FormGroup>
        <button
          type="submit"
          className="col-12 btn btn-primary"
          {...attrDisabled}
        >
          {sending ? <AiOutlineLoading className="spinner" /> : 'Send'}
        </button>
      </form>
    </div>
  );
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  errors: state.errors,
  inbox: state.inbox,
});

const mapDistachToProps = dispatch =>
  bindActionCreators(InboxActions, dispatch);

export default connect(mapStateToProps, mapDistachToProps)(Compose);
