import React from 'react';
import MainGroupPage from './MainGroupPage';
import Navbar from '../../Navbar';
import withAuth from 'hocs/withAuth';

const TheGroup = props => {
  return (
    <>
      <div className="navbarDiv">
        <Navbar />
      </div>
      <div className="content-system">
        <MainGroupPage {...props} />
      </div>
    </>
  );
};

export default withAuth(TheGroup);
