import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { likeFeed, openCommentForm } from '../../store/actions/feeds';
import { likeGroupFeed } from '../../store/actions/groups';
import { likeEventFeed } from '../../store/actions/events';
import { CgComment } from 'react-icons/cg';
import { BiLike } from 'react-icons/bi';

import FeedLikeModal from './FeedLikeModal';

import { ActionsFeed } from '../../styles/groups';
const FeedActions = ({ post }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.currentUser);
  const groupPage = useMatch('/group/:id');
  const eventPage = useMatch('/event/:id');

  const handleLike = () => {
    if (groupPage) {
      dispatch(likeGroupFeed(post._id, user.id));
    } else if (eventPage) {
      dispatch(likeEventFeed(post._id, user.id));
    } else {
      dispatch(likeFeed(post._id, user.id));
    }
  };

  const isLiked = likes => {
    const finding = likes.find(like => user.id === like._id);
    return finding ? true : false;
  };

  return (
    <ActionsFeed>
      <section>
        <FeedLikeModal likeLenght={post.likes.length} authors={post.likes} />
        <span onClick={() => dispatch(openCommentForm(post._id))}>
          {post.comments.length} comments
        </span>
      </section>
      <div
        onClick={() => handleLike(post._id)}
        className={isLiked(post.likes) ? 'like-active' : ''}
      >
        <BiLike /> {isLiked(post.likes) ? 'Liked' : 'Like'}
      </div>
      <div onClick={() => dispatch(openCommentForm(post._id))}>
        <CgComment /> Comment
      </div>
    </ActionsFeed>
  );
};

export default FeedActions;
