import {
  LOAD_EVENTS,
  POST_EVENT,
  LOAD_EVENT_PAGE,
  LIKE_EVENT_FEED,
  LOAD_EVENT_FEED,
  POST_EVENT_FEED,
  POST_EVENT_COMMENT,
  EDIT_EVENT_COMMENT,
  EDIT_EVENT_FEED,
  REMOVE_EVENT_FEED,
  REMOVE_EVENT_COMMENT,
} from "../actionTypes";

import { mountPostFeed, mountLikeFeed, mountRemoveCommentFeed } from "./feeds";

const initialState = {
  all: [],
  opened: {},
};

export const events = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EVENTS:
      return {
        ...state,
        all: [...action.events],
      };
    case POST_EVENT:
      return {
        ...state,
        all: [...state.all, action.newEvent],
      };
    case LOAD_EVENT_PAGE:
      return {
        ...state,
        opened: { ...action.event },
      };
    case LOAD_EVENT_FEED:
      return {
        ...state,
        opened: { ...state.opened, feeds: action.feeds },
      };
    case POST_EVENT_FEED:
      return {
        ...state,
        opened: {
          ...state.opened,
          feeds: [action.feed, ...state.opened.feeds],
        },
      };
    case LIKE_EVENT_FEED:
      return {
        ...state,
        opened: {
          ...state.opened,
          feeds: mountLikeFeed(state.opened.feeds, action),
        },
      };

    case POST_EVENT_COMMENT:
    case EDIT_EVENT_FEED:
    case EDIT_EVENT_COMMENT:
      return {
        ...state,
        opened: {
          ...state.opened,
          feeds: mountPostFeed(state.opened.feeds, action),
        },
      };

    case REMOVE_EVENT_FEED:
      return {
        ...state,
        opened: {
          ...state.opened,
          feeds: state.opened.feeds.filter(feed => feed._id !== action.id),
        },
      };

    case REMOVE_EVENT_COMMENT:
      return {
        ...state,
        opened: {
          ...state.opened,
          feeds: mountRemoveCommentFeed(state.opened.feeds, action),
        },
      };
    default:
      return state;
  }
};
