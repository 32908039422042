import React from 'react';
import BuildingGroups from './BuildingGroups';
import Navbar from '../../Navbar';
import GroupFormsModal from './GroupFormsModal';
import withAuth from 'hocs/withAuth';

const TheBuildingGroups = props => {
  return (
    <>
      <div className="navbarDiv">
        <Navbar modalButton={<GroupFormsModal history={props.history} />} />
      </div>
      <div className="content-system">
        <BuildingGroups {...props} />
      </div>
    </>
  );
};

export default withAuth(TheBuildingGroups);
