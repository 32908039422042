import React from 'react';
import MyProfile from './MyProfile';
import Navbar from '../Navbar';
import withAuth from 'hocs/withAuth';

const TheProfile = props => {
  return (
    <div>
      <div className="navbarDiv">
        <Navbar />
      </div>
      <div className="content-system">
        <MyProfile {...props} />
      </div>
    </div>
  );
};

export default withAuth(TheProfile);
