import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getConversations,
  openConversation,
  archiveConversation,
  openNotificationConversation,
} from '../../store/actions/inbox';
import { messageNotificationClicked } from '../../store/actions/notifications';

import Moment from 'react-moment';
import { Messaging, MsgItem, ArchiveConversation } from './styles';
import { FiTrash } from 'react-icons/fi';
import pi from '../../images/default-profile-image.jpg';
import { Badge } from 'reactstrap';

function Messages({
  openMobileChat,
  currentUser,
  inbox,
  getConversations,
  openConversation,
  archiveConversation,
  myMessageNotifications,
  messageNotificationClicked,
  notificationUserId,
  openNotificationConversation,
}) {
  const [searchBy, setSearchBy] = useState('');
  const [conversation, setConversation] = useState(() => {
    getConversations(currentUser.user.id);
    return [];
  });

  useEffect(() => {
    // const sorted = inbox.messages
    //   .sort(function (a, b) {
    //     const resA = a.messages.slice(-1).pop().createdAt;
    //     const resB = b.messages.slice(-1).pop().createdAt;
    //     return resA.localeCompare(resB);
    //   })
    //   .reverse();
    setConversation(inbox.chats);
    // const lastConversation = { ...sorted[0] };
    // if (notificationUserId !== null && sorted.length > 0) {
    //   const conversationID = sorted.filter(conv => {
    //     const user = conv.users.filter(user => user._id === notificationUserId);
    //     return user.length > 0 ? true : false;
    //   })[0]?._id;
    //   openConversation(conversationID);
    //   openNotificationConversation(null);
    // } else {
    //   if (lastConversation?._id && inbox.openConversationId === null) {
    //     openConversation(lastConversation._id);
    //   }
    // }
    //eslint-disable-next-line
  }, [inbox.messages]);

  // useEffect(() => {
  // const interval = setInterval(() => {
  // if (inbox.openConversationId !== null) {
  // getConversations(currentUser.user.id);
  // }
  // }, 15000);
  // return () => clearInterval(interval);
  // }, [inbox.openConversationId]);

  function searchConversation(e) {
    e.preventDefault();
    const filtered = inbox.messages.filter(item => {
      const res = item.users.filter(author => {
        const name = (
          author.firstName +
          ' ' +
          author.lastName
        ).toLocaleLowerCase();
        return name.indexOf(searchBy) > -1;
      });
      if (res.length > 0) {
        return true;
      }
      return false;
    });

    if (searchBy === '') {
      setConversation(inbox.messages);
    } else {
      setConversation(filtered);
    }
  }

  function handleOpenConversation(id, userId) {
    openConversation(id);
    openMobileChat(true);

    const notificationId = myMessageNotifications.filter(
      notification => notification.author._id === userId,
    );

    if (notificationId.length > 0) {
      messageNotificationClicked(
        notificationId[0]['_id'],
        currentUser.user.id,
        userId,
      );
    }
  }

  function handleArchiveConversation(conversationId) {
    if (
      window.confirm('Are you sure with you want to delete this conversation?')
    ) {
      archiveConversation({
        userId: currentUser.user.id,
        conversationId,
      });
    }
  }

  function unreadConversation(userId) {
    let count = 0;
    myMessageNotifications.map(notification => {
      if (notification.author._id === userId) {
        count++;
      }
      return notification;
    });
    if (count) {
      return <Badge color="info">{count}</Badge>;
    }
    return <></>;
  }

  return (
    <Messaging>
      <h2>Messaging</h2>
      <form onSubmit={searchConversation}>
        <input
          placeholder="Search by"
          type="text"
          defaultValue={searchBy}
          onChange={e => setSearchBy(e.target.value)}
        />
      </form>
      <ol>
        {conversation.length > 0 &&
          conversation.map(item => (
            <MsgItem
              key={item.id}
              className={item.id === inbox.openConversationId ? 'active' : ''}
              onClick={e =>
                item._id !== inbox.openConversationId &&
                handleOpenConversation(item.id, item.user._id)
              }
            >
              <img src={item.user.image || pi} alt={item.user.email} />
              <div>
                <strong>
                  {`${item.user.firstName} ${item.user.lastName}`}{' '}
                  {unreadConversation(item.user._id)}
                </strong>
                <small>
                  <Moment format="lll">
                    {item.messages.slice(-1).pop().createdAt}
                  </Moment>
                </small>
              </div>
              <ArchiveConversation>
                <FiTrash onClick={e => handleArchiveConversation(item.id)} />
              </ArchiveConversation>
            </MsgItem>
          ))}
      </ol>
    </Messaging>
  );
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  inbox: state.inbox,
  errors: state.errors,
  myMessageNotifications: state.myMessageNotifications,
  notificationUserId: state.inbox.notificationUserId,
});

const mapDistachToProps = dispatch =>
  bindActionCreators(
    {
      getConversations,
      openConversation,
      messageNotificationClicked,
      archiveConversation,
      openNotificationConversation,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDistachToProps)(Messages);
