import React from 'react';

import { NavLink, Container } from 'reactstrap';
import { AiFillHeart } from 'react-icons/ai';

function Footer() {
  return (
    <>
      <footer className="footer footer-simple">
        <Container>
          <nav style={{ width: '100%' }}>
            <ul>
              <li>
                <NavLink href="/about">About Us</NavLink>
              </li>
              <li>
                <NavLink href="/terms">Terms & Conditions</NavLink>
              </li>
              <li>
                <NavLink href="/request">Add your building</NavLink>
              </li>
              <li>
                <NavLink href="/contactus">Contact us</NavLink>
              </li>
            </ul>
          </nav>
        </Container>
        <Container>
          <div className="copyright">
            {new Date().getFullYear()}, made with <AiFillHeart />
            &nbsp;here in Toronto by &copy; Weciny Inc.
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
