import styled from 'styled-components';
import navHome from '../images/home-icon.png';
import navInbox from '../images/messages-icon.png';
import navNeighbors from '../images/neighbor-list.png';
import navGroups from '../images/group-icon.png';
import navSell from '../images/market-icon2.png';
import navLendBorrow from '../images/borrow-icon.png';
import navEvents from '../images/events-icon.png';
import navNotifications from '../images/notification-icon.png';
import navMessages from '../images/message-icon3.png';
import navHambergerMenu from '../images/hamberger-menu.png';

export const NavCenterHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  .navbar-brand {
    justify-self: flex-start;
  }

  a,
  .nav-link {
    margin: 0 0.5rem;
    color: #fff !important;
    justify-self: center;
  }

  .nav-tab-main {
    a {
      padding: 10px 30px;
      transition: 0.2s;
    }
    a:hover,
    .active {
      background: #fff;
    }
  }

  .icon-nav {
    width: 34px;
    height: 34px;
    display: inline-block;
    background: no-repeat center center;
    background-size: contain;
  }

  .icon-nav-right {
    width: 22px;
    height: 22px;
    display: inline-block;
    background: no-repeat center center;
    background-size: contain;
  }

  .icon-nav-notifications {
    background-image: url(${navNotifications});
  }

  .icon-nav-messages {
    background-image: url(${navMessages});
  }

  .icon-nav-home {
    background-image: url(${navHome});
  }
  .icon-nav-inbox {
    background-image: url(${navInbox});
  }
  .icon-nav-neighbors {
    background-image: url(${navNeighbors});
  }
  .icon-nav-groups {
    background-image: url(${navGroups});
  }
  .icon-nav-sell {
    background-image: url(${navSell});
  }
  .icon-nav-lend-borrow {
    background-image: url(${navLendBorrow});
  }
  .icon-nav-events {
    background-image: url(${navEvents});
  }
  .icon-nav-hambergerMenu {
    background-image: url(${navHambergerMenu});
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    .nav-hamburger {
      color: #fff;
    }
  }
`;

export const NavHeader = styled.ul`
  display: flex;
  justify-self: flex-end;

  a {
    color: #fff;
    line-height: normal;
    text-decoration: none;

    > svg {
      margin: 0 5px;
    }
  }

  > li {
    display: flex;
    align-items: center;
    position: relative;
  }

  .nav-notification {
    .dropdown-toggle {
      display: flex;
      align-items: center;

      > svg {
        margin-right: 0;
      }
    }

    .dropdown-menu {
      position: absolute;
      .notification-scrolls {
        width: 100%;
        max-height: 300px;
        margin-bottom: 10px;
        overflow-y: scroll;
      }

      &,
      > div a {
        padding: 0.5rem;
      }
    }
  }
`;

export const ClearAllNotifications = styled.a`
  font-size: 0.8rem;
  color: #007bff !important;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
