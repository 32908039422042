import { apiCall } from '../../services/api';
import { addError } from './errors';
import { LOAD_MYBUILDINGS } from '../actionTypes';
// import { LOAD_FEEDS } from "../actionTypes";

export const loadMyBuilding = (myBuilding) => ({
  type: LOAD_MYBUILDINGS,
  myBuilding,
});

export const fetchMyBuilding = (userId) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall('get', `api/building/${userId}`);
        dispatch(loadMyBuilding(res));
        resolve(); // indicate that the API call succeeded
      } catch (err) {
        dispatch(addError(err?.message));
        reject(); // indicate the API call failed
      }
    });
  };
};
