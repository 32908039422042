import React from 'react';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';
import UserAside from '../components/UserAside';
import MessageForms from '../message/Compose';
import {
  ModalBody,
  CardHeader,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
} from 'reactstrap';
import pi from '../../images/default-profile-image.jpg';

function BorrowModal({ borrow }) {
  const currentUser = useSelector(state => state.currentUser);
  const isOwner = currentUser.user.id === borrow.borrower._id;
  if (!borrow) return null;
  return (
    <>
      <ModalBody>
        <Row>
          <Col sm="6">
            <img
              className="img-fluid"
              src={borrow.image || pi}
              alt={borrow.title}
            />
          </Col>
          <Col sm="6">
            <CardHeader>
              <UserAside user={borrow.borrower} id={borrow.borrower._id} />
            </CardHeader>
            <CardBody>
              <p>
                Name:
                <br /> <strong>{borrow.title}</strong>
              </p>
              {borrow.type === 'rent' && (
                <p>
                  Price:
                  <br /> <strong>$ {borrow.price}</strong>
                </p>
              )}
              {(!borrow.type || borrow.type === 'borrow') && (
                <>
                  <p>
                    Needed on:
                    <br />
                    <Moment className="text-muted" format="Do MMM YYYY">
                      {borrow.dateOfBorrow}
                    </Moment>
                  </p>
                  <p>
                    Will be return on:
                    <br />
                    <Moment className="text-muted" format="Do MMM YYYY">
                      {borrow.dateOfReturn}
                    </Moment>
                  </p>
                </>
              )}
              <p>
                Description: <br />
                <span className="text-muted">{borrow.description}</span>
              </p>
              <hr />
              {!isOwner && (
                <>
                  <p>Message the owner</p>
                  <MessageForms
                    currentUserId={currentUser.user.id}
                    audienceId={borrow.borrower._id}
                    conversationId="new"
                    historyRoute="borrows"
                    historyState="Your message is successfully sent"
                    messagePrefix={`Messagem from Rent & Borrow about the item ${borrow.title}: `}
                  />
                </>
              )}
            </CardBody>
          </Col>
        </Row>
      </ModalBody>
    </>
  );
}

export default BorrowModal;
