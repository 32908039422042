import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { editPassword } from '../../store/actions/user';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const schema = yup.object({
  currentPassword: yup.string('').required('Enter your current password'),
  newPassword: yup
    .string('')
    .min(8, 'Password must contain at least 8 characters')
    .required('Enter your new password'),
  confirmNewPassword: yup
    .string('Enter your password')
    .required('Confirm your new password')
    .oneOf([yup.ref('newPassword')], 'Password does not match'),
});

const PasswordModal = props => {
  const { className } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: schema,
    onSubmit: evt => {
      const isValid = schema.validate(evt);
      if (!isValid) {
        return;
      }

      const { id } = props;
      // console.log(id, evt);
      props.editPassword(id, evt);
      // if(req === "password has been changed.") {
      //   props.history.push({ pathname: `/profile`, state: `${id}` });
      // }
    },
  });

  return (
    <>
      <Button color="secondary" size="sm" onClick={toggle}>
        Change Password
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Change your Password</ModalHeader>
        <ModalBody>
          <div className="col-xs-12">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label>Current Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="currentPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentPassword}
                />
                <small>
                  &nbsp;
                  {formik.touched.currentPassword &&
                    formik.errors.currentPassword &&
                    formik.errors.currentPassword}
                </small>
              </div>

              <div className="form-group">
                <label>New Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="newPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                />
                <small>
                  &nbsp;
                  {formik.touched.newPassword &&
                    formik.errors.newPassword &&
                    formik.errors.newPassword}
                </small>
              </div>

              <div className="form-group">
                <label>Re enter the password</label>
                <input
                  className="form-control"
                  type="password"
                  name="confirmNewPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmNewPassword}
                />
                <small>
                  &nbsp;
                  {formik.touched.confirmNewPassword &&
                    formik.errors.confirmNewPassword &&
                    formik.errors.confirmNewPassword}
                </small>
              </div>
              <div className="form-group">
                <Button
                  type="submit"
                  color="primary"
                  size="sm"
                  block
                  onClick={toggle}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, { editPassword })(PasswordModal);
