import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import NavMain from 'views/components/Navbar.jsx';
import Footer3 from '../components/Footer3';
// Core Components

function LoginPage(props) {
  React.useEffect(() => {
    document.body.classList.add('login-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('login-page');
    };
  });
  return (
    <>
      <NavMain type="dark" />
      <div className="section-shaped my-0 skew-separator skew-mini">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                'url(' + require('views/mainPage/Images/1.png') + ')',
            }}
          ></div>
          <Container>
            <div className="header-body text-center">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                  <h1 className="text-white">Privacy</h1>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <Container>
        <Row>
          <Col md="8">
            <h6 className="aboutContentsTitle">Privacy policy:</h6>
            <p className="ml-5 mr-5">
              Weciny Inc. (“Weciny”, “we”, “our” or “us”) is committed to
              protecting and respecting your privacy. This policy explains our
              privacy practices, including what information we collect, how we
              use, who we share with, and how we keep it safe.
            </p>
            <h6 className="ml-3 mt-5 mb-3">1. What Weciny is:</h6>
            <div className="ml-5 mr-5">
              <p>
                Weciny is a private social network for apartment/condo
                buildings, which provides you and your neighbours with a secure
                platform for communication, exchange, and cooperation. In this
                policy, “Weciny” refers to all Weciny websites, apps, services,
                and subsidiaries.
              </p>
            </div>
            <h6 className="ml-3 mt-5 mb-3">
              2. Personal data we collect and why:
            </h6>
            <div className="ml-5 mr-5">
              <p>
                We collect and process data about our users in order to operate
                effectively and provide the best possible experience with our
                products. Some of this data, including server logs, device data,
                and cookies, is collected automatically when you interact with
                us. Other data is provided to us directly when you interact with
                our products, including when you register for an account, rate
                your building, or report a problem.
              </p>
              <p>
                What is collected depends on your interactions with Weciny and
                the choices that you make, such as your privacy settings and
                which products and features you use. When we ask you to provide
                personal data, you can decline. However, where we require the
                personal data to provide the service and you choose not to
                provide such data, you will be unable to benefit from that
                service. Where providing the data is optional, and you choose
                not to share personal data, you will be unable to benefit from
                certain features like personalisation.
              </p>
              <p>
                None of the information we collect is shared with or sold to
                third parties. Your name and profile photo will be accessible
                only to the bona fide users in your apartment building so they
                can identify and communicate with you.
              </p>
            </div>
            <h6 className="ml-3 mt-5 mb-3">
              3. How we use the data we collect from you:
            </h6>
            <div className="ml-5 mr-5">
              <p>Weciny uses the information we collect to;</p>
              <p>● Administer your account and provide our services.</p>
              <p>● Develop and improve our products and services.</p>
              <p>● Personalize your experience and make recommendations.</p>
              <p>
                ● Send you communications, including service updates and
                notifications about activity in your building (e.g. when a
                neighbour invites you to their event).
              </p>
              <p>
                ● Operate our business, including analyzing our performance,
                meeting legal obligations, developing our workforce, and doing
                research.
              </p>
            </div>
            <h6 className="ml-3 mt-5 mb-3">4. Who we share the data with:</h6>
            <div className="ml-5 mr-5">
              <p>
                The other members of your apartment building will be able to see
                your name and profile photo, as well as any content that you
                post publicly to Weciny. None of your information will be shared
                with advertisers or third parties, except to enforce this policy
                or to comply with legal regulations or requests.
              </p>
              <p className="font-italic">
                Aggregate or De-identified Information: We may disclose
                aggregate, anonymous, or de-identified information about users
                for marketing, advertising, research, compliance, or other
                purposes.
              </p>
            </div>
            <h6 className="ml-3 mt-5 mb-3">5. Your rights:</h6>
            <div className="ml-5 mr-5">
              <p>
                Canadian privacy law (PIPEDA) gives you the right to access,
                restrict, or erase the personal information we collect about
                you. You will be able to access and change most of this
                information in your account settings. If you require additional
                access, you may submit a request through the feedback form on
                the right-hand side of your feed. Upon written request, we will
                make available to you a copy of any personal information that we
                have collected about you, utilized or disclosed, to the extent
                permitted or required by law. You also have the right to request
                correction or deletion of this information, which can be done
                through the aforementioned feedback form.
              </p>
            </div>
            <h6 className="ml-3 mt-5 mb-3">
              6. How we verify building residents
            </h6>
            <div className="ml-5 mr-5">
              <p>
                Only verified residents of your specific building will be able
                to see information such as comments, reviews, feedback, and
                messages. To become a verified resident, users must provide
                their home address to Weciny, after which they will be mailed
                their building’s unique code which is then used to verify their
                residency at that address. If your building is not yet covered
                by Weciny, you will not be able to sign up, but we invite you to
                provide your contact information which will be used to notify
                you once service to your building has been established.
              </p>
            </div>
            <h6 className="ml-3 mt-5 mb-3">7. Additional Information:</h6>
            <div className="ml-5 mr-5">
              <p>
                <span className="font-italic">Account Security:</span> Weciny
                has taken technical and organizational measures (such as SSL
                encryption) to protect your personal information. However, we
                cannot guarantee 100% security from interception or other
                improper access. Accordingly, we encourage you to protect
                yourself by, among other things, choosing a strong password and
                keeping your login information private.
              </p>
              <p>
                <span className="font-italic">Amendments to this Policy: </span>
                We may change this policy from time to time, so please review it
                periodically. If significant changes are made to the policy, we
                will take steps to notify you, for example by emailing you. If
                you continue to use Weciny after changes are in effect, you
                thereby consent to the revised policy.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer3 />
    </>
  );
}

export default LoginPage;
