import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as InboxActions from '../../store/actions/inbox';
import Moment from 'react-moment';
import { AiOutlineLoading } from 'react-icons/ai';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';
import {
  ChatBox,
  ChatTitle,
  Messages,
  GroupMessages,
  SentMessage,
} from './styles';

function Chat({
  openMobileChat,
  currentUser,
  inbox,
  getConversations,
  openConversation,
  sendMessage,
}) {
  const [historic, setHistoric] = useState([]);
  const [sending, setSending] = useState(false);
  const [recipientId, setRecipientId] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [theMessage, setTheMessage] = useState('');
  const [attrDisabled, setAttrDisabled] = useState({});
  useEffect(() => {
    setHistoric(() => {
      const filtered = inbox.chats.find(
        msg => msg.id === inbox.openConversationId,
      );
      if (filtered) {
        filtered.messages.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setRecipientId(filtered.user._id);
        setRecipientName(
          filtered.user.firstName + ' ' + filtered.user.lastName,
        );
        setSending(false);
        setAttrDisabled({});
        return filtered.messages;
      }
      return [];
    });
  }, [inbox.openConversationId, inbox.chats]);

  useEffect(() => {
    if (!sending) {
      setTheMessage('');
    }
  }, [sending]);

  function handleSentMessage(e) {
    e.preventDefault();
    if (theMessage) {
      setSending(true);
      setAttrDisabled({ disabled: true });
      sendMessage({
        userId: currentUser.user.id,
        conversationId: inbox.openConversationId,
        recipientId,
        theMessage,
      });
    }
  }

  return (
    <ChatBox>
      {inbox.chats.length > 0 && (
        <ChatTitle>
          {isMobile && (
            <FaRegArrowAltCircleLeft onClick={() => openMobileChat(false)} />
          )}
          <Link to={`/profile/${recipientId}`}>
            <strong>&nbsp;{recipientName}</strong>
          </Link>
        </ChatTitle>
      )}
      <GroupMessages>
        {historic.length > 0 &&
          historic.map(message => (
            <Messages key={message._id}>
              <header>
                <img
                  src={message.author.image || ''}
                  alt={message.author.email}
                />
                <strong>
                  {message.author.firstName + ' ' + message.author.lastName}
                </strong>
                <Moment className="text-muted" format="lll">
                  {message.createdAt}
                </Moment>
              </header>
              <p>{message.text}</p>
            </Messages>
          ))}
      </GroupMessages>
      <SentMessage onSubmit={handleSentMessage}>
        <textarea
          placeholder="Write your message"
          onChange={e => setTheMessage(e.target.value)}
          value={theMessage}
          {...attrDisabled}
        ></textarea>
        <button>{sending ? <AiOutlineLoading /> : 'Sent'}</button>
      </SentMessage>
    </ChatBox>
  );
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  inbox: state.inbox,
  errors: state.errors,
});

const mapDistachToProps = dispatch =>
  bindActionCreators(InboxActions, dispatch);

export default connect(mapStateToProps, mapDistachToProps)(Chat);
