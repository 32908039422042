import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import GroupForms from './GroupFormOld';

class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { history } = this.props;
    return (
      <div>
        <Button block color="success" onClick={this.toggle}>
          Make a Group
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}>
          <ModalBody>
            <GroupForms history={history} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ModalExample;
