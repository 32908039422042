import React from 'react';
import {
	Toast,
	ToastBody,
	ToastHeader,
	Row,
	Col,
	CardBody,
	CardTitle,
	CardText,
	Card,
} from 'reactstrap';
import { Link } from 'react-router-dom';
const Example = (props) => {
	const businesses = {
		name: 'Flora Moradzadeh',
		description: 'Trusted Local Realtor',
		image:
			'https://res.cloudinary.com/mayapics/image/upload/c_fill,h_640,w_800/v1727064424/xadhblidsaahelmiyas2.jpg',
	};
	return (
		<div>
			<div className='p-3 my-2 rounded'>
				<Toast>
					<ToastHeader>Local Businesses</ToastHeader>
					<ToastBody>
						<Row>
							<Col xs='6'>
								<Card>
									<img alt='Sample' src={businesses.image} />

									<Link
										to='https://www.mylocals.shop/business-page/66f0e7df49632a0015db266c'
										target='_blank'>
										<CardBody>
											<CardTitle
												tag='h5'
												style={{
													fontSize: '14px',
												}}>
												{businesses.name}
											</CardTitle>

											<CardText
												style={{
													fontSize: '12px',
												}}>
												{businesses.description}
											</CardText>
										</CardBody>
									</Link>
								</Card>
							</Col>
							<Col xs='6'></Col>
						</Row>
					</ToastBody>
				</Toast>
				<Toast>
					<ToastHeader>What is new!</ToastHeader>
					<ToastBody>Updates about your building</ToastBody>
				</Toast>
			</div>
		</div>
	);
};

export default Example;
