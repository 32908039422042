import { LOAD_BUILDINGS, CREATE_BUILDING } from '../actionTypes';

export const buildings = (state = [], action) => {
  switch (action.type) {
    case LOAD_BUILDINGS:
      return [...action.buildings];
    case CREATE_BUILDING:
      return [...state.concat(action.newBuilding)];
    default:
      return state;
  }
};
