import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postRequest } from '../../store/actions/building';
import NavMain from 'views/components/Navbar';
import Footer from 'views/components/Footer';
// import mainbackground from "../images/zack (40).jpg";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
} from 'reactstrap';

const Request = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [fullname, setFullname] = useState('');
  const [unit, setUnit] = useState('');
  const [text, setText] = useState('');
  const handleSubmit = event => {
    event.preventDefault();
    dispatch(postRequest({ email, address, fullname, unit, text }));
    setEmail('');
    setAddress('');
    setFullname('');
    setUnit('');
    setText('');
    navigate(`/request`);
  };

  return (
    <div>
      <NavMain />
      <Container>
        <Row>
          {/* <img src={mainbackground} className="aboutBackground" /> */}

          <Col md="7" className="requestContentsRight">
            <Form onSubmit={handleSubmit}>
              <FormGroup row>
                <Label for="examplePassword" sm={2}>
                  Full Name
                </Label>
                <Col sm={10}>
                  <Input
                    autoComplete="off"
                    id="name"
                    name="fullname"
                    onChange={e => setFullname(e.target.value)}
                    type="text"
                    value={fullname}
                    placeholder="Full Name"
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="examplePassword" sm={2}>
                  Address
                </Label>
                <Col sm={10}>
                  <Input
                    autoComplete="off"
                    id="address"
                    name="address"
                    onChange={e => setAddress(e.target.value)}
                    type="text"
                    value={address}
                    placeholder="Please Enter your building address"
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="examplePassword" sm={2}>
                  Unit Number
                </Label>
                <Col sm={10}>
                  <Input
                    autoComplete="off"
                    id="unit"
                    name="unit"
                    onChange={e => setUnit(e.target.value)}
                    type="text"
                    value={unit}
                    placeholder="we will send you the building code by mail"
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="examplePassword" sm={2}>
                  Email
                </Label>
                <Col sm={10}>
                  <Input
                    autoComplete="off"
                    id="email"
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    value={email}
                    placeholder="Email"
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleSelect" sm={2}>
                  Your Request
                </Label>
                <Col sm={10}>
                  <Input
                    autoComplete="off"
                    id="text"
                    name="text"
                    onChange={e => setText(e.target.value)}
                    type="textarea"
                    rows="6"
                    value={text}
                    placeholder="We will be happy to hear from you"
                  />
                </Col>
              </FormGroup>
              <FormGroup check row>
                <Button color="info" size="lg">
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </Col>
          <Col md="5">
            <div className="requestLeftSid">
              <h3>
                <small>How does it work</small>
              </h3>
              <br />
              <h5>
                <small>
                  By providing your information, we'll take necessary steps to
                  connect you with your neighbors. Join the thousands of
                  building residents already using Weciny to build strong
                  communities with their neighbors.
                </small>
              </h5>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default Request;
