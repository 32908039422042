import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMyBuilding } from '../../store/actions/myBuilding';
import withAuth from '../../hocs/withAuth';
// import { fetchNotifications, notificationClicked } from '../../store/actions/notifications';
import Navbar from '../Navbar';
import RightSideMyBuilding from './RightSideMyBuilding';
import LeftSide from '../components/LeftSide';
import FeedForm from './FeedForms';
import FeedActions from './FeedActions';
import CommentForm from './CommentForm';
import Comment from './Comment';
import Feed from './Feed';
import { Col } from 'reactstrap';

import { DiscussionFeed } from '../../styles/groups';

const MyBuilding = () => {
  const dispatch = useDispatch();
  const errors = useSelector(state => state.errors);
  const user = useSelector(state => state.currentUser.user);
  const feedsOpened = useSelector(state => state.feedsOpened);
  const { feed, total } = useSelector(state => state.myBuilding);

  useEffect(() => {
    dispatch(fetchMyBuilding(user.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Navbar />
      <div className="content-system">
        <div className="d-flex">
          <LeftSide nav="feed" />
          <Col md="6" className="d-flex justify-content-md-center mt-5">
            <Col md="11">
              {errors.message && (
                <div className="alert alert-danger">{errors.message}</div>
              )}
              <FeedForm />
              <DiscussionFeed>
                {total > 0 &&
                  feed.map(post => (
                    <div key={post._id} className="mainFeedBox">
                      <Feed post={post} />
                      <FeedActions post={post} />

                      {feedsOpened.includes(post._id) && (
                        <>
                          <CommentForm id={post._id} />
                          {post.comments.map(comment => {
                            return (
                              <Comment
                                key={comment._id}
                                comment={{ ...comment, feedId: post._id }}
                              />
                            );
                          })}
                        </>
                      )}
                    </div>
                  ))}
              </DiscussionFeed>
              <div className="mb-5"></div>
            </Col>
          </Col>
          <Col className="d-none d-md-block col-md-3">
            <RightSideMyBuilding />
          </Col>
        </div>
      </div>
    </>
  );
};
export default withAuth(MyBuilding);
