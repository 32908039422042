import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBorrows } from "../../store/actions/borrows";
import BorrowCard from "./card";

const MyItemsGrid = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const borrowsList = useSelector(state => state.borrows);

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchBorrows(currentUser.user.address));
    }
  }, []);

  return (
    <>
      {borrowsList
        .filter(item => item.borrower._id === currentUser.user.id)
        .map(borrow => (
          <BorrowCard key={borrow._id} borrow={borrow} />
        ))}
    </>
  );
};

export default MyItemsGrid;
