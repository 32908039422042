import React from 'react';
import { Link } from 'react-router-dom';

import pi from '../../images/default-profile-image.jpg';
// import { FiEdit } from 'react-icons/fi';
import { UserAsideEdit } from '../../styles/leftside';

const UserAside = ({ user, id, isCorrectUser }) => (
  <UserAsideEdit>
    <Link to={{ pathname: `/profile/${id}` }}>
      <img src={user.image || pi} alt={user.email} />
      <div>
        <strong>{user.firstName}</strong>
        {/* {isCorrectUser && (
          <small>
            <FiEdit /> Edit profile
          </small>
        )} */}
      </div>
    </Link>
  </UserAsideEdit>
);

export default UserAside;
