import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { postReset } from '../../store/actions/resetPassword';
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';

const TheReset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { token } = useParams();
  const reset = useSelector(state => state.reset);
  const [password, setPassword] = useState();
  const [confirm, setConfirm] = useState();

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(postReset(token, { password, confirm }));
    setConfirm('');
    setPassword('');
    navigate(`/reset/${token}`);
  };
  return (
    <div>
      <Row className="container">
        <Col md="2"></Col>
        <Col md="8" className="signinContentsRight">
          {reset && <div className="alert alert-info">{reset}</div>}
          <h2 className="signinContentHeader">Enter your Email please </h2>
          <Form onSubmit={handleSubmit}>
            <FormGroup row>
              <Label for="examplePassword" sm={2}>
                New Password
              </Label>
              <Col sm={10}>
                <Input
                  autoComplete="off"
                  id="password"
                  name="password"
                  onChange={e => setPassword(e.target.value)}
                  type="password"
                  value={password}
                  placeholder="New password"
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="examplePassword" sm={2}>
                Confirm your password
              </Label>
              <Col sm={10}>
                <Input
                  autoComplete="off"
                  id="confirm"
                  name="confirm"
                  onChange={e => setConfirm(e.target.value)}
                  type="password"
                  value={confirm}
                  placeholder="Confirm your password"
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup check row>
              <Button color="info" size="sm">
                Submit
              </Button>
            </FormGroup>
          </Form>
        </Col>
        <Col md="2"></Col>
      </Row>
      {/* <Footer /> */}
    </div>
  );
};

export default TheReset;
