import { LOAD_BORROWS, POST_BORROW, REMOVE_BORROW } from '../actionTypes';

const initialState = [];

export const borrows = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BORROWS:
      return [...action.buildingBorrows];
    case POST_BORROW:
      return [...state.concat(action.newBorrow)];
    case REMOVE_BORROW:
      return [...state.filter((borrow) => borrow._id !== action.id)];
    default:
      return state;
  }
};
