import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postEvent } from '../../../store/actions/events';
import { Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';

const EventForm = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.currentUser.user);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const handleSubmit = e => {
    const buildingId = user.address;
    e.preventDefault();
    dispatch(
      postEvent(buildingId, {
        date,
        title,
        image,
        location,
        time,
        description,
      }),
    );
    setDate('');
    setTime('');
    setTitle('');
    setDescription('');
    setLocation('');
    setImage('');
    navigate('/events');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <Label for="title" sm={2}>
          Title
        </Label>
        <Col sm={10}>
          <Input
            autoComplete="off"
            className="form-control"
            id="title"
            name="title"
            onChange={e => setTitle(e.target.value)}
            type="text"
            value={title}
            placeholder="title"
            required
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="date" sm={2}>
          Date
        </Label>
        <Col sm={10}>
          <Input
            autoComplete="off"
            className="form-control"
            id="date"
            name="date"
            onChange={e => setDate(e.target.value)}
            type="date"
            value={date}
            placeholder="date"
            required
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="time" sm={2}>
          Time
        </Label>
        <Col sm={10}>
          <Input
            autoComplete="off"
            className="form-control"
            id="time"
            name="time"
            onChange={e => setTime(e.target.value)}
            type="time"
            value={time}
            placeholder="time"
            required
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="location" sm={2}>
          Location
        </Label>
        <Col sm={10}>
          <Input
            autoComplete="off"
            className="form-control"
            id="location"
            name="location"
            onChange={e => setLocation(e.target.value)}
            type="text"
            value={location}
            placeholder="location"
            required
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="description" sm={2}>
          Desctiption
        </Label>
        <Col sm={10}>
          <Input
            type="textarea"
            autoComplete="off"
            className="form-control"
            id="description"
            name="description"
            onChange={e => setDescription(e.target.value)}
            value={description}
            placeholder="description"
            required
          />
        </Col>
      </FormGroup>
      <FormGroup check row>
        <Button color="info" size="sm" block>
          Submit
        </Button>
      </FormGroup>
    </Form>
  );
};
export default EventForm;
