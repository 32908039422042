import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { postComment } from '../../store/actions/feeds';
import { postGroupComment } from '../../store/actions/groups';
import { postEventComment } from '../../store/actions/events';

import { Button } from 'reactstrap';
import { AiOutlineLoading } from 'react-icons/ai';

const CommentForm = ({ id }) => {
  const dispatch = useDispatch();
  const postId = id;
  const [text, setText] = useState('');
  const [sending, setSending] = useState(false);
  const [attrDisabled, setAttrDisabled] = useState({ disabled: false });
  const groupPage = useMatch('/group/:id');
  const eventPage = useMatch('/event/:id');
  console.log(groupPage);
  console.log(eventPage);
  const handleSubmit = async e => {
    e.preventDefault();
    setSending(true);
    setAttrDisabled({ disabled: true });

    if (groupPage) {
      await dispatch(postGroupComment(postId, text));
      console.log('this is group page');
    } else if (eventPage) {
      await dispatch(postEventComment(postId, text));
      console.log('this is event page');
    } else {
      await dispatch(postComment(postId, text));
      console.log('this is feed page');
    }

    setText('');
    setSending(false);
    setAttrDisabled({});
  };

  return (
    <div>
      <form className="form-reply" onSubmit={handleSubmit}>
        <textarea
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder="Comment"
          required
        />
        <Button type="submit" className="btn-sm ml-auto" {...attrDisabled}>
          {sending ? <AiOutlineLoading className="spinner" /> : 'Post'}
        </Button>
      </form>
    </div>
  );
};
export default CommentForm;
