import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { fetchItems, removeItem } from '../../../store/actions/items';

import MessageForms from '../../message/Compose';
import { Row, Col, Carousel, CarouselItem } from 'reactstrap';
import { BiMessageEdit } from 'react-icons/bi';
import withAuth from '../../../hocs/withAuth';
import UserAside from '../../components/UserAside';

const TheItem = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector(state => state.currentUser.user);
  const items = useSelector(state => state.items);
  const [item, setItem] = useState({});
  const [gallery, setGallery] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    dispatch(fetchItems(user.address));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItem(() => {
      return items.filter(item => item._id === id)[0];
    });
  }, [items, id]);

  useEffect(() => {
    if (item?.image) {
      setGallery(() => {
        return item?.image.map((image, index) => {
          return { src: image, altText: `Gallery ${index}`, caption: '' };
        });
      });
    }
  }, [item]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === gallery.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? gallery.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  // const goToIndex = newIndex => {
  //   if (animating) return;
  //   setActiveIndex(newIndex);
  // };

  const onDelete = () => {
    if (window.confirm('Do you really wanna delete this item?')) {
      dispatch(removeItem(id));
      navigate('/items');
    }
  };
  return (
    <>
      {item?.hasOwnProperty('_id') && (
        <div className="">
          <Col md="12" className="item-page">
            <Row>
              <Col md="8" className="p-0 carousel-gallery-item">
                <span className="close-item" onClick={() => navigate(-1)}>
                  <i className="icon-nav-close"></i>
                </span>
                <Carousel
                  interval={false}
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  {gallery.map(item => (
                    <CarouselItem
                      onExiting={() => setAnimating(true)}
                      onExited={() => setAnimating(false)}
                      key={item.src + item.altText}
                    >
                      <img src={item.src} alt={item.altText} />
                    </CarouselItem>
                  ))}
                  {item.image.length > 1 && (
                    <>
                      <div className="carousel-control-prev">
                        <span onClick={previous}>
                          <i className="icon-nav-left"></i>
                        </span>
                      </div>
                      <div className="carousel-control-next">
                        <span onClick={next}>
                          <i className="icon-nav-right"></i>
                        </span>
                      </div>
                    </>
                  )}
                </Carousel>
              </Col>
              <Col md="4" className="item-page-content">
                <div className="seller">
                  <p>
                    <strong>Seller Information</strong>
                  </p>
                  <UserAside
                    user={item?.author}
                    id={item?.author._id}
                    isCorrectUser={user.id === item?.author._id}
                  />
                </div>
                <h3>{item?.title}</h3>
                <p>
                  <strong>{item?.price} $</strong>
                </p>
                <p>{item?.description}</p>
                {user.id === item?.author._id && (
                  <button onClick={onDelete} className="btn btn-danger">
                    Delete
                  </button>
                )}
                {!(user.id === item?.author._id) && (
                  <div className="message-to-seller">
                    <p>
                      <strong>
                        <BiMessageEdit /> Send seller a message
                      </strong>
                    </p>
                    <MessageForms
                      itemInfo={item?.title}
                      currentUserId={user.id}
                      audienceId={item?.author._id}
                      conversationId="new"
                      historyRoute="items"
                      historyState="Your message is successfully sent"
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </div>
      )}
    </>
  );
};

export default withAuth(TheItem);
