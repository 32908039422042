import React, { useEffect } from 'react';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Alert } from '@mui/material';

import { fetchBuilding } from '../../store/actions/building';
import NavMain from 'views/components/Navbar.jsx';
// import Header from '../theLanding/components/Header/Header.js';
// import HeaderLinks from '../theLanding/components/Header/HeaderLinks.js';

import SignUpStepper from './stepper';
import SignUpAside from './right-aside';

const SignUpPage = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const buildingId = useSelector(state => state.landing.building.id);

  useEffect(() => {
    if (id) {
      dispatch(fetchBuilding(id));
    }
  }, [id]);

  useEffect(() => {
    if (!id && buildingId) {
      dispatch(fetchBuilding(buildingId));
    }
  }, [buildingId]);

  return (
    <div className="signUpCodeOne">
      <NavMain type="dark" />
      {/* <Header
        absolute
        color="dark"
        brand="Weciny"
        rightLinks={<HeaderLinks />}
      /> */}

      <div className="container content-system">
        <p className="signUpHeader">Sign Up</p>
        <div className="row pt-5 pb-5 ">
          <div className="col-12 col-md-5 signUpBoxOne">
            <h3>Enter building code</h3>
            {props.errors.message && (
              <Alert severity="error">{props.errors?.message}</Alert>
            )}
            <SignUpStepper />
          </div>
          <div className="col-12 col-md-6 d-flex order-first order-md-2 signUpBoxTwo">
            {(id || buildingId) && <SignUpAside />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
