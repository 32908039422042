import { apiCall } from '../../services/api';
import { addError } from './errors';
import {
  LOAD_BUILDINGS,
  CREATE_BUILDING,
  // LOAD_THEBUILDING
  // SEARCH_BUILDINGS
} from '../actionTypes';

export const loadBuildings = buildings => ({
  type: LOAD_BUILDINGS,
  buildings,
});

export const fetchBuildings = () => {
  return dispatch => {
    return apiCall('get', '/api/buildings')
      .then(res => {
        console.log(res);
        dispatch(loadBuildings(res));
      })
      .catch(err => {
        dispatch(addError(err?.message));
      });
  };
};
// CREAT A BUILDING PAGE
export const buildingCreate = newBuilding => ({
  type: CREATE_BUILDING,
  newBuilding,
});
//Action to post a building
export const postBuilding = formData => (dispatch, getState) => {
  const fd = new FormData();
  fd.append('image', formData.image);
  fd.append('code', formData.code);
  fd.append('address', formData.address);
  return apiCall('post', `/api/buildings`, fd)
    .then(res => dispatch(buildingCreate(res)))
    .catch(err => addError(err.message));
};

// BUILDING SEARCH
// export const buildingSearch = newBuildings => ({
//   type: SEARCH_BUILDINGS,
//   newBuildings
// });
// // Action to post a building
// export const SearchFetchBuildings = address => async (dispatch, getState) => {
//   try {
//     // localStorage.clear();
//     const res = await apiCall("post", `/api/buildings/search`, { ...address });
//     dispatch(buildingSearch(res));
//   } catch (err) {
//     return addError(err.message);
//   }
// };
