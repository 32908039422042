import React from 'react';
import BuildingEvents from './BuildingEvents';
import Navbar from '../../Navbar';
import EventFormsModal from '../TheEvent/EventFormsModal';
import withAuth from '../../../hocs/withAuth';

const TheBuildingEvents = props => {
  return (
    <div>
      <div className="navbarDiv">
        <Navbar modalButton={<EventFormsModal />} />
      </div>
      <div className="content-system">
        <BuildingEvents {...props} />
      </div>
    </div>
  );
};

export default withAuth(TheBuildingEvents);
