import React from 'react';
import NeighborList from './NeighborList';
import Navbar from '../Navbar';
import LeftSide from '../components/LeftSide';
import withAuth from 'hocs/withAuth';

const MyNeighbors = props => {
  return (
    <div>
      <div className="navbarDiv">
        <Navbar />
      </div>
      <div className="content-system">
        <div className="d-flex">
          <LeftSide nav="neighbors" />
          <div className="d-flex justify-content-md-center col-md-7">
            <div className="col-md-11">
              <NeighborList {...props} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(MyNeighbors);
