import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { postItem } from '../../../../store/actions/items';

const ItemForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.currentUser);
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [price, setPrice] = useState('');
  const [title, setTitle] = useState('');
  const [files, setFiles] = useState([]);

  const uploadFiles = e => {
    setFiles(validateFiles(e.target.files));
  };
  const validateFiles = thisFiles => {
    return [...files, ...thisFiles];
  };
  console.log(files);
  const handleSubmit = event => {
    event.preventDefault();
    const buildingId = currentUser.user.address;
    dispatch(postItem(buildingId, { description, image, price, title, files }));
    setDescription('');
    setImage('');
    setPrice('');
    setTitle('');
    navigate('/items');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label htmlFor="form-item-title">Title</Label>
        <Input
          id="form-item-title"
          className="form-control"
          name="title"
          type="text"
          value={title}
          onChange={e => setTitle(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="form-item-price">Price</Label>
        <Input
          id="form-item-price"
          className="form-control"
          type="number"
          onChange={e => setPrice(e.target.value)}
          value={price}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="form-item-description">Description</Label>
        <Input
          id="form-item-description"
          className="form-control"
          type="textarea"
          value={description}
          onChange={e => setDescription(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <div className="form-item-preview">
          {files.map(file => (
            <img
              key={file.name}
              src={URL.createObjectURL(file)}
              alt="preview"
            />
          ))}
        </div>
        <label htmlFor="form-item-img" className="btn btn-sm btn-primary mt-3">
          Add Image
        </label>
        <div className="d-none">
          <input
            id="form-item-img"
            type="file"
            multiple
            onChange={uploadFiles}
          />
        </div>

        {/* <Input
          type="file"
          name="file"
          onChange={this.imageSelectedHandler}
          id="exampleFile"
        /> */}
        <FormText color="muted">
          You can add your Items for sale here and you neighbors will be
          notified about it.
        </FormText>
      </FormGroup>
      <Button color="info" size="sm" block>
        Submit
      </Button>
    </Form>
  );
};
export default ItemForm;
