import React from 'react';

import PropTypes from 'prop-types';

export default function Card(props) {
  const { className, children, plain, carousel, ...rest } = props;

  return <div {...rest}>{children}</div>;
}

Card.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  carousel: PropTypes.bool,
  children: PropTypes.node,
};
