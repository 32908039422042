import React from 'react';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';

import UserAside from '../../components/UserAside';

import { ListGroup, ListGroupItem } from 'reactstrap';
import { SideBlock } from '../../../styles/groups';

const RightSide = () => {
  const { page } = useSelector(state => state.groups.opened);

  return (
    <>
      <SideBlock>
        <h5>Info</h5>
        <p>
          Created by {page.admin.firstName} {page.admin.lastName}
        </p>
        <p>
          Created on <Moment format="LL">{page.createdAt}</Moment>
        </p>
      </SideBlock>
      <SideBlock>
        <h5>Members</h5>
        <ListGroup className="members-list">
          {page.members.map(member => (
            <ListGroupItem key={member._id}>
              <UserAside user={member} id={member._id} />
            </ListGroupItem>
          ))}
        </ListGroup>
      </SideBlock>
    </>
  );
};

export default RightSide;
