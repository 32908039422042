import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchGroups, fetchMyGroups } from '../../../store/actions/groups';
import { ListGroups } from '../../../styles/groups';
import { Col } from 'reactstrap';
import LeftSide from './GroupLeftSide';
import Group from './Group';
import GroupForms from './GroupForms';

const BuildingGroups = () => {
  const dispatch = useDispatch();
  const errors = useSelector(state => state.errors);
  const user = useSelector(state => state.currentUser.user);
  const { all, my } = useSelector(state => state.groups);
  const [searchGroup, setSearchGroup] = useState('');
  const [createNew, setCreateNew] = useState(false);
  const [myGroupsId, setMyGroupsId] = useState([]);
  const [attrBtnCreateNew, setAttrBtnCreateNew] = useState({});

  useEffect(() => {
    dispatch(fetchGroups(user.address));
    dispatch(fetchMyGroups(user.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMyGroupsId(my.map(g => g._id));
  }, [my]);

  const handleOpenForm = () => {
    const disabled = (!createNew) ? {disabled: true} : {};
    setCreateNew(!createNew);
    setAttrBtnCreateNew(disabled);
  }

  const handleSearchGroup = (text) => {
    setSearchGroup(text)
  }

  return (
    <div className="d-flex">
      <LeftSide
      myGroups={my}
      handleOpenForm={handleOpenForm}
      handleSearchGroup={handleSearchGroup}
      attrBtnCreateNew={attrBtnCreateNew}
      />
      <Col md="9" className="d-flex justify-content-md-center">
        {createNew ? (
          <Col md="11">
            <GroupForms handleOpenForm={handleOpenForm} />
          </Col>
        ): (
          <Col md="11">
            <h5 className="mb-4 mt-5">Your Building's Groups</h5>

            {errors.message && (
              <div className="alert alert-danger">{errors.message}</div>
            )}
            {/* {pushHistoryMessages && (
              <div className="alert alert-success">{pushHistoryMessages}</div>
            )} */}
            <ListGroups>
              <div className="groups-grid">
                {
                  all
                  .filter(group => group.members.length > 0)
                  .filter(group => {
                    if(searchGroup !== "") {
                      if (group.title.toLowerCase().includes(searchGroup)) {
                        return true;
                      } else{
                        return false;
                      }
                    }
                    return true;
                  })
                  .map(group => {
                  return (
                    <Group
                      key={group._id}
                      id={group._id}
                      image={group.image}
                      title={group.title}
                      members={group.members}
                      mygroup={myGroupsId.includes(group._id) ? true : false}
                      searchGroup={searchGroup}
                    />
                  );
                })}
              </div>
            </ListGroups>
            <div className="mb-5"></div>
          </Col>
        )}
      </Col>
    </div>
  )
}
export default BuildingGroups;
