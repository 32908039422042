import { LOAD_MYNEIGHBORS } from '../actionTypes';

const initialState = { tenants: [] };

export const myNeighbors = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MYNEIGHBORS:
      return { ...action.myBuilding };

    default:
      return state;
  }
};
