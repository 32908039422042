import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';

import LeftSide from '../../components/LeftSide';
import EventPageHeader from './EventPageHeader';
import EventPageRightSide from './EventPageRightSide';
import FeedForm from '../../feed/FeedForms';
import FeedActions from '../../feed/FeedActions';
import CommentForm from '../../feed/CommentForm';
import Comment from '../../feed/Comment';
import Feed from '../../feed/Feed';
import EventFormsModal from './EventFormsModal';

import { DiscussionFeed } from '../../../styles/groups';

import { loadEvent, loadEventFeed } from '../../../store/actions/events';

const EventPageContent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const errors = useSelector(state => state.errors);
  const events = useSelector(state => state.events);
  const page = events.opened;
  const feedsOpened = useSelector(state => state.feedsOpened);

  useEffect(() => {
    dispatch(loadEvent(id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(loadEventFeed(id));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="d-flex">
      <LeftSide nav="events" modalButton={<EventFormsModal />} />
      <Container className="buildingEventsContainer" fluid="md sm">
        {page.hasOwnProperty('_id') ? (
          <Col>
            <EventPageHeader />
            <Row>
              <Col md="8">
                {errors.message && (
                  <div className="alert alert-danger">{errors.message}</div>
                )}
                <FeedForm />
                <div>
                  <DiscussionFeed>
                    {page.feeds
                      .filter(post => post.hasOwnProperty('_id'))
                      .map(post => (
                        <div
                          key={`${post._id}-event-group-feed`}
                          className="mainFeedBox"
                        >
                          <Feed post={post} />
                          <FeedActions post={post} />
                          {feedsOpened.includes(post._id) && (
                            <>
                              <CommentForm
                                id={post._id}
                                key={`${post._id}-event-comment-feed`}
                              />
                              {post.comments.map(comment => {
                                return (
                                  <Comment
                                    key={comment._id}
                                    comment={{ ...comment, feedId: post._id }}
                                  />
                                );
                              })}
                            </>
                          )}
                        </div>
                      ))}
                  </DiscussionFeed>
                  <div className="mb-5"></div>
                </div>
              </Col>
              <Col md="4">
                <EventPageRightSide />
              </Col>
            </Row>
          </Col>
        ) : (
          <>{page === false && <Navigate to="/404" />}</>
        )}
      </Container>
    </div>
  );
};
export default EventPageContent;
