import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global';
import App from './views/App';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/nucleo-svg.css';
import './assets/css/nucleo-icons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/argon-design-system.min.css?v1.0.0';
import './assets/demo/demo.css?v1.0.0';
import reportWebVitals from './reportWebVitals';

// ReactDOM.render(<App />, document.getElementById('root'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
