import React from 'react';

const The404 = props => {
  return (
    <div>
      <div className="PageNotFound ">
        <h1>400</h1>
        <h3>Sorry, the page you requested could not be found.</h3>
        <a href="/feeds">Go to the main page</a>
      </div>
    </div>
  );
};

export default The404;
