import styled from 'styled-components'

export const Filter = styled.header`
  margin-bottom: 1rem;
  label {
    margin-right: 1rem;
  }
  input {
    padding: .2rem;
    padding-left: 2rem;
    color: #495057;
    border: 1px solid #ced4da;
  }

  form div {
    position: relative;
    svg {
      color: #495057;
      position: absolute;
      left: .5rem;
      top: 50%;
      margin-top: -.5rem;
      font-size: 1rem;
    }
  }
`
export const Neighbors = styled.ul`
  display: flex;
  flex-direction: column;

  li {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    & + li {
      padding-top: 1rem;
      border-top: 2px solid #ddd;
    }
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
    }

    a {
      text-align: left;
      padding: 1rem;
    }

    div {
      margin-left: auto;
    }
  }
`
