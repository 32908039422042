import React from 'react';
import BuildingItems from './Sections/BuildingItems';
import Navbar from '../../Navbar';
import ItemFormsModal from './Sections/ItemFormsModal';
import withAuth from '../../../hocs/withAuth';
const TheBuildingItems = props => {
  return (
    <div>
      <div className="navbarDiv">
        <Navbar modalButton={<ItemFormsModal history={props.history} />} />
      </div>
      <div className="content-system">
        <BuildingItems {...props} />
      </div>
    </div>
  );
};

export default withAuth(TheBuildingItems);
