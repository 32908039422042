import {
  LOAD_BUILDING,
  ADD_BUILDINGIMAGE,
  LOAD_FOUNDBUILDING,
  REMOVE_FOUNDBUILDING,
} from '../actionTypes';

const initialState = { image: [] };

export const building = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BUILDING:
      return { ...action.building };
    case ADD_BUILDINGIMAGE:
      return { ...state, image: [...state.image.concat(action.image)] };
    default:
      return state;
  }
};

// REDUCER TO FIND BUILDING
const initialState2 = {};

export const foundBuilding = (state = initialState2, action) => {
  switch (action.type) {
    case LOAD_FOUNDBUILDING:
      return { ...action.building };
    case REMOVE_FOUNDBUILDING:
      return {};
    default:
      return state;
  }
};
