import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Icon, Tab } from '@mui/material';
import Card from './Card/Card.js';
import CardBody from './Card/CardBody.js';
import CardHeader from './Card/CardHeader.js';
export default function CustomTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, value) => {
    setValue(value);
  };
  const { headerColor, plainTabs, tabs, title, rtlActive } = props;
  return (
    <Card plain={plainTabs}>
      <CardHeader color={headerColor} plain={plainTabs}>
        {title !== undefined ? <div>{title}</div> : null}
        <Tabs
          value={value}
          onChange={handleChange}
          // classes={{
          //   root: classes.tabsRoot,
          //   indicator: classes.displayNone,
          // }}
        >
          {tabs.map((prop, key) => {
            var icon = {};
            if (prop.tabIcon) {
              icon = {
                icon:
                  typeof prop.tabIcon === 'string' ? (
                    <Icon>{prop.tabIcon}</Icon>
                  ) : (
                    <prop.tabIcon />
                  ),
              };
            }
            return (
              <Tab
                // classes={{
                //   root: classes.tabRootButton,
                //   label: classes.tabLabel,
                //   selected: classes.tabSelected,
                //   wrapper: classes.tabWrapper,
                // }}
                key={key}
                label={prop.tabName}
                {...icon}
              />
            );
          })}
        </Tabs>
      </CardHeader>
      <CardBody>
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </CardBody>
    </Card>
  );
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
    'rose',
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired,
    }),
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
};
