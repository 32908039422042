// eslint-disable-next-line
/*global localStorage */
import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../store';
import { BrowserRouter as Router } from 'react-router-dom';
import { setAuthorizationToken, setCurrentUser } from '../store/actions/auth';
import jwtDecode from 'jwt-decode';

import GlobalStyle from '../styles/global';
import Main from './Main';
const store = configureStore();

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  try {
    store.dispatch(setCurrentUser(jwtDecode(localStorage.jwtToken)));
  } catch (e) {
    store.dispatch(setCurrentUser({}));
  }
}
const App = () => (
  <>
    <Provider store={store}>
      <Router>
        <Main />
      </Router>
    </Provider>
    <GlobalStyle />
  </>
);
export default App;
