import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import NavMain from 'views/components/Navbar.jsx';
import Footer3 from '../components/Footer3';
// Core Components

function LoginPage(props) {
  React.useEffect(() => {
    document.body.classList.add('login-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('login-page');
    };
  });
  return (
    <>
      <NavMain type="dark" />
      <div className="section-shaped my-0 skew-separator skew-mini">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                'url(' + require('views/mainPage/Images/1.png') + ')',
            }}
          ></div>
          <Container>
            <div className="header-body text-center">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                  <h1 className="text-white">About Us</h1>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>

      <Container>
        <Row>
          <Col md="8">
            <p className="lead mt-5">
              Thousands of building residents are using Weciny to communicate
              with their neighbors, it is a private social network for each
              apartment building that helps its residents to communicate through
              a shared feed wall.
            </p>
            <h6 className="aboutContentsTitle">Who we are:</h6>
            <p className="ml-5">
              Weciny is a private social network for apartment/condo buildings,
              dedicated to offering you and your neighbours a secure space for
              communication, exchange, and cooperation.
            </p>
            <h6 className="aboutContentsTitle">With Weciny you can:</h6>
            <div className="aboutContentsBoletpoints">
              <p>● Meet and build relationships with your neighbours</p>
              <p>● Get and give recommendations/advice</p>
              <p>● Spread the word about lost or found belongings</p>
              <p>● Create or join groups based on your interests</p>
              <p>
                ● Organize events, like a volunteer outing or an orphan
                Thanksgiving
              </p>
              <p>
                ● Find a new gym/yoga buddy or plan a playdate for your kids
              </p>
              <p>
                ● Get updates on community and building news, like a local
                break-in or upcoming repairs
              </p>
            </div>
            <p className="mt-5 ml-5">
              Weciny also lets residents rate and review their buildings, to
              help you find the perfect new apartment/condo when you’re in the
              market.
            </p>
            <p className="mt-5 ml-5">
              Weciny is in the early stages of development, and we look forward
              to offering many more features and benefits as we grow. We rely on
              user feedback to improve the platform and serve you better, so if
              you have any feedback or would like to request service to your
              building, please <a href="/contactus"> contact us</a>
            </p>
            <p className="mt-5 ml-5">
              Weciny is and will always be free for residents to communicate.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer3 />
    </>
  );
}

export default LoginPage;
