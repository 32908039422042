import styled from 'styled-components';
import { lighten } from 'polished';

export const Inbox = styled.div`
  display: flex;

  > div {
    display: flex;
    padding: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Messaging = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: #fff;

  h2,
  form {
    border: 1px solid #ddd;
    border-left: 0;
    border-right: 0;
  }

  h2 {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0.75rem 1.25rem;
    font-size: 1.2rem;
  }

  form,
  ol {
    border-top: 0;
  }

  form {
    input {
      width: 100%;
      height: 39px;
      padding: 0.5rem;
      border: 0;
    }
  }

  ol {
    max-height: 500px;
    margin: 0;
    overflow-y: auto;
  }
`;

export const MsgItem = styled.li`
  padding: 0.75rem 1rem;
  display: flex;
  border-left: 3px solid transparent;
  border-radius: 0 !important;
  cursor: pointer;
  transition: 0.2s;

  & + li {
    border-top: 1px solid ${lighten(0.1, '#ccc')};
  }

  &:hover {
    background-color: ${lighten(0.1, '#ccc')};
  }

  &.active {
    border-left: 3px solid blue;
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
  }

  strong {
    display: block;
  }
`;

export const ArchiveConversation = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  margin-left: auto;
  justify-content: center;
  align-self: center;
  align-items: center;
  opacity: 0.5;
  border-radius: 50%;
  border: 0;
  background: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 1;
    color: #fff;
    background: #d11124;
  }
`;

export const ChatBox = styled.div`
  flex: 1;
  background: #fff;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding-top: 60px;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
`;

export const ChatTitle = styled.div`
  height: 40px;
  padding: 0.75rem 1.25rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
`;

export const GroupMessages = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;

  @media (max-width: 768px) {
    height: auto;
    min-height: 250px;
    flex: 1;
  }
`;

export const Messages = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  transition: 0.2s;

  &:hover {
    background-color: ${lighten(0.1, '#ccc')};
  }

  header {
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    img {
      width: 25px;
      height: auto;
      margin-right: 10px;
      border-radius: 50%;
      object-fit: cover;
    }

    strong {
      font-size: 1rem;
      font-weight: 500;
    }

    time {
      margin-left: 5px;
      font-size: 0.8rem;
    }
  }

  p {
    margin: 0;
    padding-left: 35px;
  }
`;

export const SentMessage = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;

  textarea {
    width: 100%;
    height: 80px;
    padding: 0.5rem 1rem;
    border: 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  button {
    width: 80px;
    height: 32px;
    margin: 0.75rem 1.25rem 0.75rem auto;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 0.25rem;
    color: #fff;
    background: #0073b1;

    svg {
      animation: spinner 1s infinite linear;
    }
  }

  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
