import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { removeError } from '../store/actions/errors';
import Landing from './mainPage/LandingPage';
import AboutPage from './aboutPage';
import TermPage from './termsPage';
import PrivacyPage from './privacyPage';
import ContactUs from './contactPage';
import Request from './requestPage';
import LoginPage from './loginPage/LoginPage';
import SignupPage from './signupPage/page';
import Forgot from './forgetPage';
import TheReset from './resetPage';
import AddBuildings from './addBuilding';
import BuildingPage from './buildingInfo';
//private routes==================================================
import TheBuildingPage from './feed/MyBuilding';
import TheBuildingItems from './items/Items';
import TheItemPage from './items/TheItem';
import TheBuildingEvents from './events/Events';
import EventPage from './events/TheEvent';
import TheBuildingBorrows from './borrows';
import TheBuildingGroups from './groups/buildingGroups';
import TheGroup from './groups/groupDiscussion';
import TheProfile from './profile';
import MyNeighbors from './neighbors';
import TheInbox from './inbox';
import TheFeedback from './feedbackPage';
import NotFound from './404Page';
import ChatGpt from './chatgpt';

const Main = (props) => {
	const currentUser = useSelector((state) => state.currentUser);
	const errors = useSelector((state) => state.errors);
	const { removeError } = props;
	return (
		<>
			<Routes>
				<Route
					exact
					path='/'
					element={
						!currentUser.isAuthenticated ? (
							<Landing errors={errors} removeError={removeError} {...props} />
						) : (
							<Navigate to='/feeds' />
						)
					}
				/>
				<Route
					exact
					path='/about'
					element={
						!currentUser.isAuthenticated ? (
							<AboutPage />
						) : (
							<Navigate to='/feeds' />
						)
					}
				/>
				<Route
					exact
					path='/terms'
					element={
						!currentUser.isAuthenticated ? (
							<TermPage />
						) : (
							<Navigate to='/feeds' />
						)
					}
				/>
				<Route
					exact
					path='/privacy'
					element={
						!currentUser.isAuthenticated ? (
							<PrivacyPage />
						) : (
							<Navigate to='/feeds' />
						)
					}
				/>
				<Route
					exact
					path='/contactus'
					element={
						!currentUser.isAuthenticated ? (
							<ContactUs />
						) : (
							<Navigate to='/feeds' />
						)
					}
				/>
				<Route
					exact
					path='/request'
					element={
						!currentUser.isAuthenticated ? (
							<Request />
						) : (
							<Navigate to='/feeds' />
						)
					}
				/>
				<Route
					exact
					path='/chatgpt'
					element={
						!currentUser.isAuthenticated ? (
							<ChatGpt />
						) : (
							<Navigate to='/feeds' />
						)
					}
				/>
				<Route
					exact
					path='/signin'
					element={
						!currentUser.isAuthenticated ? (
							<LoginPage removeError={removeError} errors={errors} {...props} />
						) : (
							<Navigate to='/feeds' />
						)
					}
				/>
				<Route
					exact
					path='/signup/:id?'
					element={
						!currentUser.isAuthenticated ? (
							<SignupPage
								removeError={removeError}
								errors={errors}
								{...props}
							/>
						) : (
							<Navigate to='/feeds' />
						)
					}
				/>
				<Route
					exact
					path='/forgot'
					element={
						!currentUser.isAuthenticated ? (
							<Forgot removeError={removeError} errors={errors} {...props} />
						) : (
							<Navigate to='/feeds' />
						)
					}
				/>
				<Route
					exact
					path='/reset/:token'
					element={
						!currentUser.isAuthenticated ? (
							<TheReset removeError={removeError} errors={errors} {...props} />
						) : (
							<Navigate to='/feeds' />
						)
					}
				/>
				<Route
					exact
					path='/buildings'
					element={
						<AddBuildings
							{...props}
							errors={errors}
							removeError={removeError}
							currentUser={currentUser}
						/>
					}
				/>

				<Route
					exact
					path='/thebuilding/:id'
					element={<BuildingPage {...props} />}
				/>

				<Route path='/feeds' element={<TheBuildingPage />} />
				<Route path='/items' element={<TheBuildingItems />} />
				<Route path='/item/:id' element={<TheItemPage />} />
				<Route path='/events' element={<TheBuildingEvents />} />
				<Route path='/event/:id' element={<EventPage />} />
				<Route path='/borrows' element={<TheBuildingBorrows />} />
				<Route path='/groups' element={<TheBuildingGroups />} />
				<Route path='/group/:id' element={<TheGroup />} />
				<Route path='/profile/:id' element={<TheProfile />} />
				<Route path='/neighbors' element={<MyNeighbors />} />
				<Route path='/inbox' element={<TheInbox />} />
				<Route path='/feedback' element={<TheFeedback />} />
				{/* <Route path="/mygroup" component={withAuth(TheGroup)} /> */}

				<Route path='*' element={<NotFound />} />
			</Routes>
		</>
	);
};

export default Main;
