import { LOAD_ITEMS, POST_ITEM, REMOVE_ITEM } from '../actionTypes';

const initialState = [];

export const items = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ITEMS:
      return [...action.buildingItems];
    case POST_ITEM:
      return [...state.concat(action.newItem)];
    case REMOVE_ITEM:
      return [...state.filter(item => item._id !== action.id)];
    default:
      return state;
  }
};
