import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authUserSignin } from '../../store/actions/auth';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CardFooter,
} from 'reactstrap';

// Core Components

function LoginCard1() {
  const dispatch = useDispatch();
  const errors = useSelector(state => state.errors);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailFocus, setEmailFocus] = React.useState('');
  const [passwordFocus, setPasswordFocus] = React.useState('');
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(authUserSignin({ email, password }));
    setPassword('');
    setEmail('');
  };
  return (
    <>
      {errors.message && (
        <div className="alert alert-danger">{errors.message}</div>
      )}
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          <Form role="form" autoComplete="off" onSubmit={handleSubmit}>
            <FormGroup className={'mb-3 ' + emailFocus}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  fullWidth
                  required
                  placeholder="Email"
                  type="email"
                  onFocus={() => setEmailFocus('focused')}
                  onBlur={() => setEmailFocus('')}
                ></Input>
              </InputGroup>
            </FormGroup>
            <FormGroup className={passwordFocus}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  name="password"
                  onChange={e => setPassword(e.target.value)}
                  type="password"
                  value={password}
                  fullWidth
                  required
                  onFocus={() => setPasswordFocus('focused')}
                  onBlur={() => setPasswordFocus('')}
                ></Input>
              </InputGroup>
            </FormGroup>
            {/* <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id=" customCheckLogin2"
                type="checkbox"
              ></input>
              <label
                className="custom-control-label"
                htmlFor=" customCheckLogin2"
              >
                <span className="text-default opacity-5">Remember me</span>
              </label>
            </div> */}
            <div className="text-center">
              <Button className="my-4" color="primary" type="submit">
                Login
              </Button>
            </div>
          </Form>
        </CardBody>
        <CardFooter>
          No account? <Link to="/forgot">Sign up</Link>
        </CardFooter>
        <CardFooter>
          <Link to="/forgot">Forgot my password!</Link>
        </CardFooter>
      </Card>
    </>
  );
}

export default LoginCard1;
