import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  fetchNotifications,
  fetchMessageNotifications,
  messageNotificationClicked,
  notificationClicked,
} from '../store/actions/notifications';
import { openNotificationConversation } from '../store/actions/inbox';
import pi from '../images/default-profile-image.jpg';
import {
  Navbar,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  Button,
  Col,
} from 'reactstrap';

import { connect } from 'react-redux';
import { logout } from '../store/actions/auth';
import Logo from '../images/logo.png';
import {
  NavCenterHeader,
  NavHeader,
  // ClearAllNotifications,
} from '../styles/navbar.js';
import LeftSide from './components/LeftSide.js';
import NavBarLogout from './navbarlogout';
class MyNavbar extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleTool = this.toggleTool.bind(this);
    this.toggleToolGroup = this.toggleToolGroup.bind(this);
    this.toggleToolInbox = this.toggleToolInbox.bind(this);
    this.handleClearNotification = this.handleClearNotification.bind(this);
    this.state = {
      navMain: '',
      isOpen: false,
      tooltipOpen: false,
      tooltipOpenGroup: false,
      tooltipOpenInbox: false,
    };
  }
  toggleTool() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }
  toggleToolGroup() {
    this.setState({
      tooltipOpenGroup: !this.state.tooltipOpenGroup,
    });
  }
  toggleToolInbox() {
    this.setState({
      tooltipOpenInbox: !this.state.tooltipOpenInbox,
    });
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  handleClearNotification() {
    // this.props.myNotifications.map(n => notificationClicked(n._id));
  }
  handleGoToNotification(notificationId, userId, currentUserId) {
    this.props.messageNotificationClicked(
      notificationId,
      currentUserId,
      userId,
    );
    this.props.openNotificationConversation(userId);
  }
  toLogout = e => {
    e.preventDefault();
    this.props.logout();
  };
  componentDidMount() {
    if (this.props.currentUser.isAuthenticated) {
      this.props.fetchNotifications(this.props.currentUser.user.id);
      this.props.fetchMessageNotifications(this.props.currentUser.user.id);
    }
  }
  render() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {
      notificationClicked,
      myNotifications,
      myMessageNotifications,
      modalButton,
    } = this.props;
    // eslint-disable-next-line
    var notificationCount = 0;
    // eslint-disable-next-line
    var notificationCountGroups = 0;
    // eslint-disable-next-line
    var notificationCountMessages = 0;
    const myNotificationsArr = [];

    // eslint-disable-next-line
    myNotifications.map(notification => {
      notificationCount++;
      if (notification.isFor === 'groups') {
        notificationCountGroups++;
      } else if (notification.isFor === 'messages') {
        notificationCountMessages++;
      }

      const index = myNotificationsArr.findIndex(n => {
        return (
          n.isFor === notification.isFor &&
          n.author._id === notification.author._id
        );
      });

      if (index > -1) {
        myNotificationsArr[index]['count'] += 1;
      } else {
        notification['count'] = 1;
        myNotificationsArr.push(notification);
      }
    });

    return (
      <Navbar
        color="dark"
        fixed="top"
        dark
        expand="md"
        className={this.state.isOpen ? 'navbar-opened p-0 m-0' : 'p-0 m-0'}
      >
        <Col xs="12">
          <NavCenterHeader>
            <Link
              className="navbar-brand"
              to={{
                pathname: this.props.currentUser.isAuthenticated
                  ? `/feeds`
                  : `/`,
              }}
            >
              <img className="navbarLogo" src={Logo} alt="Lobbea Corp." />
            </Link>

            <div className="nav-tab-main d-none d-md-flex justify-content-center">
              <Link
                className={
                  window.location.pathname === '/feeds' ? 'active' : ''
                }
                to={{ pathname: `/feeds` }}
              >
                <i className="icon-nav icon-nav-home"></i>
              </Link>

              <Link
                className={
                  window.location.pathname === '/items' ? 'active' : ''
                }
                to={{ pathname: `/items` }}
              >
                <i className="icon-nav icon-nav-sell"></i>
              </Link>
              <Link
                className={
                  window.location.pathname === '/borrows' ? 'active' : ''
                }
                to={{ pathname: `/borrows` }}
              >
                <i className="icon-nav icon-nav-lend-borrow"></i>
              </Link>
              <Link
                className={
                  window.location.pathname === '/events' ? 'active' : ''
                }
                to={{ pathname: `/events` }}
              >
                <i className="icon-nav icon-nav-events"></i>
              </Link>
              <Link
                className={
                  window.location.pathname === '/groups' ||
                  window.location.pathname === '/mygroup'
                    ? 'active'
                    : ''
                }
                to={{ pathname: `/groups` }}
              >
                <i className="icon-nav icon-nav-groups"></i>
              </Link>
            </div>

            <NavHeader className="d-flex d-md-flex flex-row align-items-center navbar-nav">
              <UncontrolledDropdown nav inNavbar className="nav-notification">
                <DropdownToggle nav caret>
                  <Badge color="info">{myNotificationsArr.length}</Badge>

                  <i className="icon-nav-right icon-nav-notifications"></i>
                </DropdownToggle>
                {myNotificationsArr.length > 0 && (
                  <DropdownMenu right>
                    <div className="notification-scrolls">
                      {myNotificationsArr.map(notification => {
                        return (
                          <div
                            key={notification._id + 1}
                            onClick={() =>
                              notificationClicked(
                                notification._id,
                                this.props.currentUser.user.id,
                                notification.author._id,
                              )
                            }
                          >
                            <Link
                              to={`/` + notification.isFor}
                              className="dropdown-item"
                            >
                              <div className="userAsideSection">
                                <img
                                  className="userAsideImageNotification"
                                  src={notification.author.image || pi}
                                  alt={notification.author.email}
                                />
                                <div className="userAsideUsernameNotifications">
                                  {notification.author.firstName}{' '}
                                  {notification.text}{' '}
                                  {notification.count > 1 && (
                                    <Badge color="primary" pill>
                                      {notification.count}
                                    </Badge>
                                  )}
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </DropdownMenu>
                )}
              </UncontrolledDropdown>

              <NavBarLogout />

              <NavItem className="nav-hamburger d-md-none text-right ml-3">
                <i
                  onClick={this.toggle}
                  className="icon-nav icon-nav-hambergerMenu"
                ></i>
              </NavItem>
            </NavHeader>
          </NavCenterHeader>
          {this.state.isOpen && (
            <div className="nav-mobile d-md-none">
              <LeftSide mobile={true} modalButton={modalButton} />
              {/* <Button
                outline
                color="secondary"
                size="sm"
                onClick={this.toLogout}
              >
                SignOut
              </Button> */}
            </div>
          )}
        </Col>
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    myNotifications: state.myNotifications,
    myMessageNotifications: state.myMessageNotifications,
    inbox: state.inbox,
  };
}

export default connect(mapStateToProps, {
  logout,
  fetchNotifications,
  fetchMessageNotifications,
  messageNotificationClicked,
  notificationClicked,
  openNotificationConversation,
})(MyNavbar);
