import React from 'react';

import { Container, Row, Col } from 'reactstrap';
// import img1 from 'assets/img/presentation-page/table.png';
import img2 from '../Images/7.png';
import img3 from '../Images/6.jpg';
import img4 from '../Images/4.jpg';
import img5 from 'assets/img/ill/la22.png';
import img6 from '../Images/5.jpg';
const Information = () => {
	return (
		<div className='presentation-page'>
			<section className='section-basic-components'>
				<Row>
					{/* <Col className="mb-md-5" lg="5" md="10">
              <h1 className="display-3">
                3. Section Three: (information)
                <span className="text-primary">
                  The core elements of your website
                </span>
              </h1>
              <p className="lead">
                We re-styled every Bootstrap 4 element to match the Argon Design
                System style.
                <br />
                ● How does it look <br />
                ● How the can they signup
                <br />● Information about our platform
              </p>
            </Col> */}
					<Col lg='12' md='12'>
						<div className='image-container'>
							<img alt='...' className='img1Landing table-img' src={img2}></img>
							<img alt='...' className='coloured-card-btn-img' src={img3}></img>
							<img alt='...' className='coloured-card-img' src={img4}></img>
							<img alt='...' className='img3Landing' src={img6}></img>
							{/* <img alt='...' className='linkedin-btn-img' src={img4}></img> */}
							<img
								alt='...'
								className='w-100 landingSectionTwoImage'
								src={img5}></img>
						</div>
					</Col>
				</Row>
			</section>
		</div>
	);
};

export default Information;
