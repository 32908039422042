import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'react-moment';

import { removeBorrow } from '../../store/actions/borrows';

import pi from '../../images/default-profile-image.jpg';
import {
  Col,
  Card,
  CardImg,
  CardTitle,
  CardBody,
  CardText,
  Button,
  Modal,
  ModalHeader,
} from 'reactstrap';
import BorrowModal from './modal';

const style = {
  card: {
    marginBottom: 30,
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  title: {
    letterSpacing: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  text: {
    fontSize: '0.875rem',
    textAlign: 'center',
  },
  footer: {
    display: 'flex',
    border: 0,
  },
};

const BorrowCard = ({ borrow }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const isOwner = currentUser.user.id === borrow.borrower._id;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(removeBorrow(borrow._id));
    }
  };

  const desc = () => {
    const text = borrow.description;
    if (text.length >= 70) {
      return text.slice(0, 70) + '...';
    }

    return text;
  };

  const handleOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Col lg={3} md={3} sm={6} xs={12}>
        <Card style={style.card}>
          <CardImg
            top
            style={{ height: 140, objectFit: 'contain', padding: '1rem' }}
            src={borrow.image || pi}
            alt="Card image cap"
            onClick={handleOpenModal}
          />
          <CardBody style={{ padding: '0.5rem' }}>
            <CardTitle tag="h5" style={style.title}>
              {borrow.title}
            </CardTitle>
            {!isOwner && <CardText>{desc()}</CardText>}
            {borrow.type === 'rent' && <p>$ {borrow.price}</p>}
            {(!borrow.type || borrow.type === 'borrow') && (
              <footer style={style.footer}>
                <p style={{ ...style.text, marginRight: 'auto' }}>
                  <Moment className="text-muted" format="Do MMM, YY">
                    {borrow.dateOfBorrow}
                  </Moment>
                  <small style={{ display: 'block' }}>Needed on</small>
                </p>
                <p style={style.text}>
                  <Moment className="text-muted" format="Do MMM, YY">
                    {borrow.dateOfReturn}
                  </Moment>
                  <small style={{ display: 'block' }}>Return on</small>
                </p>
              </footer>
            )}
            {isOwner ? (
              <>
                <Button
                  color="danger"
                  size="sm"
                  className="ml-auto"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </>
            ) : (
              <>
                {borrow.type === 'rent' ? (
                  <Button
                    color="link"
                    size="sm"
                    className="btn-link-hover mr-2"
                    onClick={handleOpenModal}
                  >
                    I want it
                  </Button>
                ) : (
                  <Button
                    color="link"
                    size="sm"
                    className="btn-link-hover"
                    onClick={handleOpenModal}
                  >
                    I have it
                  </Button>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Col>
      <Modal isOpen={isModalOpen} backdrop="static" keyboard={false} size="lg">
        <ModalHeader toggle={handleOpenModal}>
          Meet with your neighbors.
        </ModalHeader>
        <BorrowModal borrow={borrow} />
      </Modal>
    </>
  );
};

export default BorrowCard;
