import React, { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function withAuth(ComponentToBeRendered) {
  function Authenticate() {
    const navigate = useNavigate();
    const isAuthenticated = useSelector(
      state => state.currentUser.isAuthenticated,
    );
    // const currentUser = useSelector((state) => state.currentUser);
    useEffect(() => {
      if (isAuthenticated === false) {
        navigate('/signin');
      }
    }, []);
    useEffect(() => {
      return () => {
        if (isAuthenticated === false) {
          navigate('/signin');
        }
      };
    }, []);
    return (
      <div>
        {!isAuthenticated ? (
          <Navigate to="/signin" />
        ) : (
          <ComponentToBeRendered />
        )}
      </div>
    );
  }

  return Authenticate;
}
