import React, { useState } from 'react';
import { connect } from 'react-redux';
import { editProfile } from '../../store/actions/user';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const ModalEditUser = props => {
  const { user, buttonLabel, editProfile } = props;

  const [modal, setModal] = useState(false);
  const [fieldFirstName, setFieldFirstName] = useState(user.firstName);
  const [fieldLastName, setFieldLastName] = useState(user.lastName);
  const [fieldDob, setFieldDob] = useState(user.dob);
  const [fieldInterests, setFieldInterests] = useState(user.interests);
  const [fieldAbout, setFieldAbout] = useState(user.about);
  const toggle = () => {
    setModal(!modal);
  };
  const formSubmit = e => {
    e.preventDefault();
    editProfile(user.id, {
      firstName: fieldFirstName,
      lastName: fieldLastName,
      dob: fieldDob,
      interests: fieldInterests,
      about: fieldAbout,
    });
    setModal(!modal);
  };
  return (
    <>
      <Button color="secondary" size="sm" onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit My Profile</ModalHeader>
        <ModalBody>
          <form onSubmit={e => formSubmit(e)}>
            <div className="form-group">
              <label>First Name</label>
              <input
                className="form-control"
                type="text"
                defaultValue={user.firstName}
                onChange={e => setFieldFirstName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input
                className="form-control"
                type="text"
                defaultValue={user.lastName}
                onChange={e => setFieldLastName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Date of Birth</label>
              <input
                className="form-control"
                type="date"
                max="2010-12-31"
                defaultValue={user.dob}
                onChange={e => setFieldDob(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Interests</label>
              <textarea
                rows="2"
                className="form-control"
                type="text"
                placeholder="Separeted per comma"
                defaultValue={user.interests}
                onChange={e => setFieldInterests(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>About me</label>
              <textarea
                rows="2"
                className="form-control"
                type="text"
                defaultValue={user.about}
                onChange={e => setFieldAbout(e.target.value)}
              />
            </div>
            <div className="form-group text-right">
              <Button type="submit" color="primary">
                submit
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, { editProfile })(ModalEditUser);
