import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';

import { fetchGroupPage, fetchMyGroups } from '../../../store/actions/groups';

import { Row, Col } from 'reactstrap';

import { DiscussionFeed } from '../../../styles/groups';

import LeftSide from '../buildingGroups/GroupLeftSide';
import Header from './Header';
import FeedForm from '../../feed/FeedForms';
import FeedActions from '../../feed/FeedActions';
import CommentForm from '../../feed/CommentForm';
import Comment from '../../feed/Comment';
import Feed from '../../feed/Feed';
import RightSide from './RightSide';

const MainGroupPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector(state => state.currentUser.user);
  const errors = useSelector(state => state.errors);
  const { my } = useSelector(state => state.groups);
  const page = useSelector(state => state.groups.opened.page);
  const feed = useSelector(state => state.groups.opened.feed);
  const feedsOpened = useSelector(state => state.feedsOpened);

  useEffect(() => {
    dispatch(fetchGroupPage(id));
    if (my.length === 0) {
      dispatch(fetchMyGroups(user.id));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="d-flex">
      {page.hasOwnProperty('_id') ? (
        <>
          <LeftSide myGroups={my} groupActiveId={id} internalPage={true} />
          <Col md="9" className="mt-3">
            <Header />
            <Row>
              <Col md="8">
                {errors.message && (
                  <div className="alert alert-danger">{errors.message}</div>
                )}

                <FeedForm />
                <div>
                  <DiscussionFeed>
                    {feed.map(post => (
                      <div key={post._id} className="mainFeedBox">
                        <Feed post={post} />
                        <FeedActions post={post} />
                        {feedsOpened.includes(post._id) && (
                          <>
                            <CommentForm id={post._id} />
                            {post.comments.map(comment => {
                              return (
                                <Comment
                                  key={comment._id}
                                  comment={{ ...comment, feedId: post._id }}
                                />
                              );
                            })}
                          </>
                        )}
                      </div>
                    ))}
                  </DiscussionFeed>
                  <div className="mb-5"></div>
                </div>
              </Col>
              <Col md="4">
                <RightSide />
              </Col>
            </Row>
          </Col>
        </>
      ) : (
        <>{page === false && <Navigate to="/404" />}</>
      )}
    </div>
  );
};
export default MainGroupPage;
