import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';

import {
  FormControl,
  FormControlLabel,
  TextField,
  Checkbox,
} from '@mui/material';
import { Button } from 'reactstrap';
import { AiOutlineLoading } from 'react-icons/ai';
import { authUserSignup } from '../../store/actions/auth';
import { removeBuildingStorage } from '../../store/actions/landing';

const schema = yup.object({
  firstName: yup.string().required('This a required field'),
  lastName: yup.string().required('This a required field'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('This a required field'),
  password: yup
    .string('')
    .min(8, 'Password must contain at least 8 characters')
    .required('Enter your password'),
  confirmPassword: yup
    .string('Enter your password')
    .required('Confirm your password')
    .oneOf([yup.ref('password')], 'Password does not match'),
  code: yup
    .string('')
    .min(4, 'Building code must contain 4 characters')
    .required('This a required field'),
  unit: yup.string().required('This a required field'),
});

const SignUpForm = ({ handleStepper }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { _id } = useSelector(state => state.building);
  const [termChecked, setTermChecked] = useState(false);
  const landing = useSelector(state => state.landing);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      code: landing.building.code,
      unit: '',
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async evt => {
      dispatch(authUserSignup(evt, _id));
      dispatch(removeBuildingStorage());
    },
  });

  useEffect(() => {
    formik.setFieldValue('code', landing.building.code);
  }, [landing.building.code]);

  const handleSubmit = async e => {
    e.preventDefault();
    const isValid = await formik.validateForm();
    if (!Object.keys(isValid).length) {
      formik.handleSubmit();
    }
  };
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <FormControl>
        <div className="row">
          <div className="col-12 mb-2 col-md-6">
            <TextField
              type="text"
              error={formik.errors.firstName}
              onChange={e => {
                formik.setFieldValue('firstName', e.target.value);
                // formik.validateField('firstName');
              }}
              onBlur={() => formik.validateField('firstName')}
              value={formik.values.firstName}
              // defaultValue={formik.values.firstName}
              fullWidth
              label="First Name"
              helperText={formik.errors.firstName}
            />
          </div>
          <div className="col-12 mb-2 col-md-6">
            <TextField
              type="text"
              error={formik.errors.lastName}
              onChange={e => {
                formik.setFieldValue('lastName', e.target.value);
                // formik.validateField('lastName');
              }}
              onBlur={() => formik.validateField('lastName')}
              value={formik.values.lastName}
              // defaultValue={formik.values.lastName}
              fullWidth
              label="Last Name"
              helperText={formik.errors.lastName}
            />
          </div>
          <div className="col-12 mb-2 col-md-12">
            <TextField
              type="email"
              error={formik.errors.email}
              onChange={e => {
                formik.setFieldValue('email', e.target.value);
                // formik.validateField('email');
              }}
              onBlur={() => formik.validateField('email')}
              value={formik.values.email}
              // defaultValue={formik.values.email}
              fullWidth
              label="Email"
              helperText={formik.errors.email}
            />
          </div>
          <div className="col-12 mb-2 col-md-6">
            <TextField
              type="password"
              error={formik.errors.password}
              onChange={e => {
                formik.setFieldValue('password', e.target.value);
                // formik.validateField('password');
              }}
              onBlur={() => formik.validateField('password')}
              value={formik.values.password}
              // defaultValue={formik.values.password}
              fullWidth
              label="Password"
              helperText={formik.errors.password}
            />
          </div>
          <div className="col-12 mb-2 col-md-6">
            <TextField
              type="password"
              error={formik.errors.confirmPassword}
              onChange={e => {
                formik.setFieldValue('confirmPassword', e.target.value);
                // formik.validateField('confirmPassword');
              }}
              onBlur={() => formik.validateField('confirmPassword')}
              value={formik.values.confirmPassword}
              // defaultValue={formik.values.confirmPassword}
              fullWidth
              label="Confirm Password"
              helperText={formik.errors.confirmPassword}
            />
          </div>
          <div className="col-12 mb-2 col-md-6">
            <TextField
              disabled={true}
              type="text"
              error={formik.errors.code}
              //  onChange={e => {
              //   formik.setFieldValue("code", e.target.value);
              //   formik.validateField("code");
              // }}
              onBlur={() => formik.validateField('code')}
              value={formik.values.code}
              // defaultValue={formik.values.code}
              fullWidth
              label="Building Code"
              helperText={formik.errors.code}
            />
          </div>
          <div className="col-12 mb-2 col-md-6">
            <TextField
              type="text"
              error={formik.errors.unit}
              onChange={e => {
                formik.setFieldValue('unit', e.target.value);
                // formik.validateField('unit');
              }}
              onBlur={() => formik.validateField('unit')}
              value={formik.values.unit}
              // defaultValue={formik.values.unit}
              fullWidth
              label="Unit Number"
              helperText={formik.errors.unit}
            />
          </div>

          <div className="col-12 mt-2 mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={termChecked}
                  onChange={() => setTermChecked(!termChecked)}
                  required={true}
                />
              }
              label={
                <>
                  By clicking this, you agree to our{' '}
                  <Link to="/terms">Terms</Link> and{' '}
                  <Link to="/privacy">Privacy Policy</Link>
                </>
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <Button
              color="danger"
              type="button"
              onClick={() => handleStepper(false)}
              style={{ width: '100%' }}
            >
              Back
            </Button>
          </div>
          <div className="col-12 col-md-6">
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              type="submit"
              style={{ width: '100%' }}
            >
              {formik.isSubmitting ? (
                <AiOutlineLoading className="spinner" />
              ) : (
                'Sign Up'
              )}
            </Button>
          </div>
        </div>
      </FormControl>
    </form>
  );
};

export default SignUpForm;
