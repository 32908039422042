import React, { Component } from 'react';
// import UserAside from './UserAside';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AsideColumn } from '../../styles/leftside';
import { Col } from 'reactstrap';

class LeftSide extends Component {
  render() {
    const { nav, modalButton, mobile } = this.props;
    return (
      <Col
        className={mobile ? 'p-0' : 'd-none d-md-block col-3 aside-max-width'}
      >
        <AsideColumn className={mobile ? '' : 'col-3 pt-4 aside-max-width'}>
          <Link to="/" className={nav === 'feed' ? 'active' : ''}>
            <i className="icon-nav icon-nav-home"></i>
            Feed
          </Link>
          <Link to="/inbox">
            <i className="icon-nav icon-nav-inbox"></i>
            Inbox
          </Link>
          <Link to="/neighbors" className={nav === 'neighbors' ? 'active' : ''}>
            <i className="icon-nav icon-nav-neighbors"></i>
            My Neighbors
          </Link>
          <hr />
          <Link to="/items" className={nav === 'items' ? 'active' : ''}>
            <i className="icon-nav icon-nav-sell"></i>
            Sell & Buy
          </Link>
          <Link to="/borrows" className={nav === 'borrows' ? 'active' : ''}>
            <i className="icon-nav icon-nav-lend-borrow"></i>
            Rent & Borrow
          </Link>
          <Link to="/events" className={nav === 'events' ? 'active' : ''}>
            <i className="icon-nav icon-nav-events"></i>
            Events
          </Link>
          <hr />
          <Link to="/groups" className={nav === 'groups' ? 'active' : ''}>
            <i className="icon-nav icon-nav-groups"></i>
            Groups
          </Link>
          {modalButton ? (
            <div>{modalButton}</div>
          ) : (
            <Link to="/feedback">
              <i className="icon-nav icon-nav-feedBack"></i>
              Give Feedback
            </Link>
          )}

          <hr />
        </AsideColumn>
      </Col>
    );
  }
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}
export default connect(mapStateToProps, null)(LeftSide);
