import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import { VscWorkspaceTrusted } from 'react-icons/vsc';
import { FaHandsHelping } from 'react-icons/fa';
import { GiPrivate } from 'react-icons/gi';
import { FaDove } from 'react-icons/fa';

// Core Components

function Feature4() {
  return (
    <>
      <div className="section features-4">
        <Container>
          <Row className="align-items-center">
            <Col className="text-center p-sm-0" lg="7" sm="6">
              <h3 className="display-3">WeCiny is</h3>
              <Row>
                <Col md="6" xs="6">
                  <div className="info info-hover-warning">
                    <div className="icon icon-shape bg-warning shadow rounded-circle text-primary">
                      <VscWorkspaceTrusted />
                      {/* <i className="ni ni-satisfied text-white"></i> */}
                    </div>

                    <h5 className="info-title">Secure and Private</h5>
                    <p className="description opacity-8">
                      We only show your name to your neighbors and all other
                      information will be kept secure and private, and all
                      neighbors are verified for privacy purposes.
                    </p>
                  </div>
                </Col>
                <Col md="6" xs="6">
                  <div className="info info-hover-info">
                    <div className="icon icon-shape bg-info shadow rounded-circle text-primary">
                      {/* <i className="ni ni-palette text-white"></i> */}
                      <FaHandsHelping />
                    </div>
                    <h5 className="info-title">Handy</h5>
                    <p className="description opacity-8">
                      Connect with your neighbors, exchange goods and services,
                      form private groups, and cultivate a sense of community
                      belonging.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-lg-4">
                <Col md="6" xs="6">
                  <div className="info info-hover-danger">
                    <div className="icon icon-shape bg-danger shadow rounded-circle text-primary">
                      {/* <i className="ni ni-user-run text-white"></i> */}
                      <GiPrivate />
                    </div>
                    <h5 className="info-title">Trusted</h5>
                    <p className="description opacity-8">
                      We ensure that our platform is secure and all neighbors
                      are verified for your safety and peace of mind.
                    </p>
                  </div>
                </Col>
                <Col md="6" xs="6">
                  <div className="info info-hover-success">
                    <div className="icon icon-shape bg-success shadow rounded-circle text-primary">
                      {/* <i className="ni ni-glasses-2 text-white"></i> */}
                      <FaDove />
                    </div>
                    <h5 className="info-title">Free!!</h5>
                    <p className="description opacity-8">
                      Our service is free for you! We generate revenue from
                      local businesses and service providers in your area.
                    </p>
                  </div>
                </Col>
                <Col className="mr-auto text-left mt-4" lg="4"></Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature4;
