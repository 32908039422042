import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useMatch } from 'react-router-dom';
import parse from 'html-react-parser';
import { editFeed, removeFeed } from '../../store/actions/feeds';
import FeedDropDown from './FeedDropDown';
import Moment from 'react-moment';
import pi from '../../images/default-profile-image.jpg';
import { ReactTinyLink } from 'react-tiny-link';

const Feed = ({ post }) => {
  const user = useSelector(state => state.currentUser.user);
  const url =
    typeof post.text === 'string'
      ? post.text.match(/(https?:\/\/[^\s]+)/g)
      : null;
  /* eslint-disable no-useless-escape */
  const feedText = post.text
    ? post.text.replace(
        /(\b(https?):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/gim,
        '<a target="_blank" href="$1">$1</a>',
      )
    : '';

  const groupPage = useMatch('/group/:id');
  const eventPage = useMatch('/event/:id');
  let module = 'feed';
  if (groupPage) {
    module = 'group';
  } else if (eventPage) {
    module = 'event';
  }

  return (
    <div className="feedBox">
      <div className="feedBoxHead gridRow">
        <header>
          <img
            className="userAsideImage"
            src={post.author?.image || pi}
            alt={post.author.email}
          />
          <div className="conversation-info">
            <Link
              to={{ pathname: `/profile/${post.author._id}` }}
              className="reactRouterLinks"
            >
              {post.author.firstName}
            </Link>
            <Moment className="text-muted" size="sm" format="Do MMM YYYY">
              {post.createdAt}
            </Moment>
          </div>
        </header>
        {user.id === post.author._id && (
          <div className="FeedDropDown">
            <FeedDropDown
              id={post._id}
              text={post.text}
              onSubmit={editFeed}
              onDelete={removeFeed(post._id, module)}
            />
          </div>
        )}
      </div>
      <div className="feedBoxText">
        <p>{parse(feedText)}</p>
        {post.image === '' && url !== null ? (
          <ReactTinyLink
            cardSize="large"
            showGraphic={true}
            maxLine={2}
            minLine={1}
            url={url[0]}
          />
        ) : (
          post.image && (
            <img className="feedImage" src={post.image} alt={post.image} />
          )
        )}
      </div>
    </div>
  );
};

export default Feed;
