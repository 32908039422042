import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { postBorrow } from '../../store/actions/borrows';
import { AiOutlineLoading } from 'react-icons/ai';
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const BorrowForm = () => {
  const buildingId = useSelector(state => state.currentUser.user.address);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState('');
  const [validIt, setValidIt] = useState(false);
  const toggle = () => setModal(!modal);

  const requiredMessage = 'This a required field';
  const FILE_SIZE = 5e6; // 5bm
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

  const fileSchema = Yup.object({
    image: Yup.mixed()
      .nullable()
      .required(requiredMessage)
      .test(
        'fileSize',
        'File too large, select Images smaller than 1MB',
        value => value && value.size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        'Unsupported Format',
        value => value && SUPPORTED_FORMATS.includes(value.type),
      ),
  });

  const formik = useFormik({
    initialValues: {
      type: '',
      category: '',
      name: '',
      startDate: '',
      endDate: '',
      price: null,
      description: '',
      image: '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(requiredMessage),
      // category: Yup.string().required(requiredMessage),
      name: Yup.string().required(requiredMessage),
      description: Yup.string()
        .max(250, 'Must be 250 characters or less')
        .required(requiredMessage),

      startDate: Yup.date().when('type', {
        is: 'borrow',
        then: Yup.date().required(requiredMessage),
      }),
      endDate: Yup.date().when('type', {
        is: 'borrow',
        then: Yup.date().required(requiredMessage),
      }),
    }),
    onSubmit: async () => {
      if (validIt === true) {
        const theState = {
          title: formik.values.name,
          type: formik.values.type,
          category: formik.values.category,
          dateOfBorrow: formik.values.startDate,
          dateOfReturn: formik.values.endDate,
          price: formik.values.price,
          description: formik.values.description,
          image,
        };
        await dispatch(postBorrow(buildingId, theState));
        setModal(false);
      }
    },
  });

  const ErrorFieldMessage = field => {
    if (formik.errors[field] && formik.touched[field]) {
      return <small className="text-danger">{formik.errors[field]}</small>;
    }
    return null;
  };

  const CategoriesOptions = option => {
    return (
      <option key={option} defaultValue={option}>
        {option}
      </option>
    );
  };

  const handleCategories = e => {
    formik.setFieldValue('category', e.target.value);
  };

  return (
    <>
      <Button style={{ width: 'auto' }} color="success" onClick={toggle}>
        Insert a item
      </Button>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
        <ModalHeader toggle={toggle}>Ask a borrow item</ModalHeader>
        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>Type</Label>
                  <ButtonGroup style={{ display: 'block' }}>
                    <Button
                      color="primary"
                      outline={true}
                      onChange={formik.handleChange}
                      onClick={() => formik.setFieldValue('type', 'rent')}
                      active={formik.values.type === 'rent'}
                    >
                      For Rent
                    </Button>
                    <Button
                      color="primary"
                      outline={true}
                      onClick={() => formik.setFieldValue('type', 'borrow')}
                      active={formik.values.type === 'borrow'}
                    >
                      For Borrow
                    </Button>
                  </ButtonGroup>
                  {ErrorFieldMessage('type')}
                </FormGroup>
              </Col>
              {/* <Col md={6}>
                <FormGroup>
                  <Label>Categories</Label>
                  <select
                    style={{
                      width: "100%",
                      padding: 8,
                      display: "block",
                      borderColor: "#007bff",
                      color: "#007bff",
                    }}
                    defaultValue=""
                    onChange={e => handleCategories(e)}
                  >
                    <option defaultValue="" disabled>
                      Select
                    </option>
                    {["Auto", "Tools", "Garden", "Others"].map(item =>
                      CategoriesOptions(item),
                    )}
                  </select>
                  {ErrorFieldMessage("category")}
                </FormGroup>
              </Col> */}
            </Row>
            <FormGroup>
              <Label>Name of the item</Label>
              <Input
                name="name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {ErrorFieldMessage('name')}
            </FormGroup>
            {formik.values.type === 'borrow' && (
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label>When do you need it?</Label>
                    <Input
                      name="startDate"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.startDate}
                    />
                    {ErrorFieldMessage('startDate')}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>When will you return?</Label>
                    <Input
                      name="endDate"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.endDate}
                    />
                    {ErrorFieldMessage('endDate')}
                  </FormGroup>
                </Col>
              </Row>
            )}
            {formik.values.type === 'rent' && (
              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Price (if you want lend let empty)</Label>
                    <Input
                      name="price"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.price}
                      min={0}
                    />
                    {ErrorFieldMessage('startDate')}
                  </FormGroup>
                </Col>
              </Row>
            )}
            <FormGroup>
              <Label>Description</Label>
              <Input
                name="description"
                type="textarea"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {ErrorFieldMessage('description')}
            </FormGroup>
            <FormGroup>
              <Label>Picture</Label>
              <div className="form-item-preview">
                {image && (
                  <img src={URL.createObjectURL(image)} alt="preview" />
                )}
              </div>
              <label
                htmlFor="form-item-img"
                className="btn btn-sm btn-primary mt-3"
              >
                Add Image
              </label>
              {validIt && <small className="text-danger">{validIt}</small>}
              <div style={{ display: 'none' }}>
                <input
                  id="form-item-img"
                  name="image"
                  type="file"
                  style={{ overflow: 'hidden', textOverFlow: 'ellipsis' }}
                  onChange={e => {
                    setImage(e.target.files[0]);
                    setValidIt(true);
                    fileSchema
                      .validate({ image: e.target.files[0] })
                      .catch(err => {
                        setImage('');
                        setValidIt(err.errors);
                      });
                  }}
                />
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={toggle}
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>{' '}
          <Button
            color="primary"
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            type="submit"
          >
            {formik.isSubmitting ? (
              <AiOutlineLoading className="spinner" />
            ) : (
              'Save'
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BorrowForm;
