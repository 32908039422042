import React from 'react';
import EditModal from './EditProfileModal';
// import MapComponent from './MapComponent';
import Moment from 'react-moment';
import { Row, Col } from 'reactstrap';
import pi from '../../images/default-profile-image.jpg';
import { Link } from 'react-router-dom';
import EditPasswordModal from './EditPasswordModal';
import CustomTabs from './Sections/CustomTabs';

export default function SectionPills(props) {
  const { errors, user, lat, lng } = props;
  return (
    <div className="d-flex">
      <div className="col-sm-8">
        {errors.message && (
          <div className="alert alert-danger">{errors.message}</div>
        )}
        <CustomTabs
          headerColor="primary"
          tabs={[
            {
              tabName: 'About me',
              tabContent: (
                <>
                  {props.currentUser.user.id === user.id ? (
                    <>
                      <h6>About Me: </h6>
                      <p className="profilePTags">{user.about}</p>

                      <h6>My Interestes: </h6>
                      {user.interests !== undefined &&
                        user.interests.length > 0 && (
                          <p className="profilePTags">
                            {user.interests.join(', ')}
                          </p>
                        )}

                      <h6>Email: </h6>
                      <p className="profilePTags">{user.email}</p>

                      <h6>My Unit Number:</h6>
                      <p className="profilePTags">{user.unit}</p>

                      <h6>Birthday: </h6>
                      {user.dob !== undefined && (
                        <p className="profilePTags">
                          <Moment format="Do MMM YYYY">{user.dob}</Moment>
                        </p>
                      )}
                      <h6>Languages: </h6>
                      {user.languages !== undefined &&
                        user.languages.length > 0 && (
                          <>
                            <p className="profilePTags">{user.languages}</p>
                          </>
                        )}

                      <div className="myAccountButtons">
                        <EditPasswordModal id={user.id} />
                        <EditModal user={user} buttonLabel="Edit" />
                      </div>
                    </>
                  ) : (
                    <>
                      <h6>About Me: </h6>
                      <p className="profilePTags">{user.about}</p>
                      <h6>My Interestes: </h6>
                      {user.interests !== undefined &&
                        user.interests.length > 0 && (
                          <p className="profilePTags">
                            {user.interests.join(', ')}
                          </p>
                        )}
                      {user.languages !== undefined &&
                        user.languages.length > 0 && (
                          <>
                            <h6>Languages: </h6>
                            <p className="profilePTags">{user.languages}</p>
                          </>
                        )}
                    </>
                  )}
                </>
              ),
            },

            {
              tabName: 'My Items',
              tabContent: (
                <>
                  <Row>
                    {user.items.map(item => (
                      <Col
                        key={item._id}
                        md="3"
                        sm="6"
                        xs="6"
                        className="sell-item"
                      >
                        <Link to={{ pathname: `/item/${item._id}` }}>
                          <figure className="items-image">
                            <img src={item.image[0] || pi} alt={item.title} />
                          </figure>

                          <strong>{item.price} $</strong>
                          <p>{item.title}</p>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </>
              ),
            },
            {
              tabName: 'My Groups',
              tabContent: (
                <>
                  <Row>
                    {user.groups.map(group => (
                      <Col
                        key={group._id}
                        md="3"
                        sm="6"
                        xs="6"
                        className="sell-item"
                      >
                        <Link
                          to={{ pathname: `/group/${group._id}` }}
                          className="reactRouterLinks"
                        >
                          <figure className="items-image">
                            <img src={group.image || pi} alt={group.title} />
                          </figure>
                          <strong>{group.title}</strong>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </>
              ),
            },
          ]}
        />
      </div>
      <div className="col-sm-4">
        {/* {user.id !== undefined && <MapComponent lat={lat} lng={lng} />} */}
      </div>
    </div>
  );
}
