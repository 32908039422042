import React from 'react';
import './landing.css';

// Core Components
import NavMain from '../components/Navbar.jsx';
import SectionOne from './Sections/SectionOne';
import SectionTwo from './Sections/SectiomTwo';
import SectionThree from './Sections/SectionThree';
import Footer3 from '../components/Footer3';

function LandingPage() {
	React.useEffect(() => {
		document.body.classList.add('landing-page');
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		return function cleanup() {
			document.body.classList.remove('landing-page');
		};
	});
	return (
		<div className='landingMaster'>
			<div className='wrapper'>
				<NavMain />
				<div className='align-items-center sectionLanding sectionOneLanding'>
					<SectionOne />
				</div>
				<div className='sectionTwoLanding'>
					<SectionTwo />
				</div>
				<div className='sectionLanding sectionThreeLanding'>
					<SectionThree />
				</div>
				<div className='sectionFooterLanding'>
					<Footer3 />
				</div>
			</div>
		</div>
	);
}

export default LandingPage;
