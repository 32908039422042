import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { HeaderGroup } from '../../../styles/groups';
import { leaveGroup, removeGroup } from '../../../store/actions/groups';
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user } = useSelector(state => state.currentUser);
  const { page } = useSelector(state => state.groups.opened);

  const handleLeave = async e => {
    e.preventDefault();
    await dispatch(leaveGroup(id, user.id));
    navigate(`/groups`, { state: `You successfully quitted the group` });
  };

  const handleDelete = async e => {
    e.preventDefault();
    await dispatch(removeGroup(id));
    navigate(`/groups`, { state: `You successfully deleted the group` });
  };
  return (
    <HeaderGroup>
      <img src={page.image} alt="cover" className="group-cover" />
      <div>
        <h4>
          {page.title} <br />
          {page.admin._id !== user.id && (
            <button
              onClick={handleLeave}
              className="btn btn-sm p-0 text-danger"
            >
              Leave this group
            </button>
          )}
          {page.admin?._id === user.id && (
            <button
              onClick={handleDelete}
              className="btn btn-sm ml-2 p-0 text-danger"
            >
              Delete this group
            </button>
          )}
        </h4>
      </div>
      <p>{page.description}</p>
    </HeaderGroup>
  );
};

export default Header;
