import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import ItemForms from './ItemForms';

const ItemFormsModal = props => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div>
      <Button color="success" onClick={toggle} block>
        Sell Item
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={props.className}>
        <ModalBody>
          <ItemForms />
        </ModalBody>
      </Modal>
    </div>
  );
};
export default ItemFormsModal;
