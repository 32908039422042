import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchBuildings } from '../../../store/actions/buildings';
import { Button, Container, Row, Col } from 'reactstrap';
import { TextField, Autocomplete, Box } from '@mui/material';

const FindBuilding = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const addresses = useSelector((state) => state.buildings);
	const [address, setAddress] = useState();

	useEffect(() => {
		dispatch(fetchBuildings());
	}, []);

	const handleFindBuilding = () => {
		navigate(`/signup/${address._id}`);
	};
	const findByCodeClick = () => {
		navigate('/signup');
	};
	const requestClick = () => {
		navigate('/request');
	};
	console.log(addresses);
	return (
		<div className='sectionOneMain'>
			<Row>
				{/* <Col lg="2" xs="12"></Col> */}
				<Col md={{ size: 8, offset: 5 }} xs={{ size: '8', offset: 1 }}>
					<p className='landingText1'>Join your building's online community</p>
					<p className='landingText2'>
						Connect with your neighbors to build a strong community. Create
						interest groups, buy and sell locally, hold events and enjoy a sense
						of belonging.
					</p>
					<Row className='row-input'>
						<Col md='8' xs='10'>
							<Autocomplete
								className='landingInput'
								autoHighlight
								size='small'
								// sx={{ width: 500 }}
								sx={window.innerWidth <= 720 ? { width: 300 } : { width: 500 }}
								options={addresses}
								getOptionLabel={(option) => option.address}
								renderOption={(props, option) => (
									<Box
										component='li'
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}>
										<img
											src={option.image[0]}
											alt={option.address}
											style={{
												width: 30,
												height: 30,
												marginRight: 10,
												borderRadius: '50%',
												objectFit: 'cover',
											}}
										/>
										{option.address}
									</Box>
								)}
								onChange={(e, obj) => {
									setAddress(obj);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Find by address'
										variant='outlined'
									/>
								)}
							/>
						</Col>
						<Col className='pl-lg-0' lg='3' xs='6'>
							<Button
								className='landingFindButton'
								block
								color='default'
								type='submit'
								onClick={handleFindBuilding}>
								Find
							</Button>
						</Col>
					</Row>
					<div className='landingButton2'>
						<Row className='row-input'>
							<Col lg='3' xs='12'>
								<Button
									className='btn-round'
									color='secondary'
									type='button'
									block
									onClick={findByCodeClick}>
									Find by code
								</Button>
							</Col>
							<Col lg='3' xs='12'>
								<Button
									className='btn-round'
									color='warning'
									type='button'
									onClick={requestClick}
									block
									// outline
								>
									Can't find it
								</Button>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default FindBuilding;
