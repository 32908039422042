const INITIAL_STATE = {
  messages: [],
  openConversationId: null,
  notificationUserId: null,
  chats: [],
};

export const inbox = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_CONVERSATION':
      return {
        ...state,
        messages: action.messages,
      };
    case 'OPEN_CONVERSATION':
      return {
        ...state,
        openConversationId: action.openConversationId,
      };
    case 'OPEN_BY_NOTIFICATION':
      return {
        ...state,
        notificationUserId: action.userId,
      };
    case 'LOAD_CHATS':
      const chatExist = state.chats.find(
        chat => chat.user._id === action.user._id,
      );
      if (chatExist) {
        return {
          ...state,
          chats: state.chats.map(chat => {
            if (chat.user._id === action.user._id) {
              const newMsg = [...chat.messages, ...action.messages].filter(
                (item, index, array) =>
                  array.findIndex(m => m._id === item._id) === index,
              );
              return {
                ...chat,
                messages: newMsg,
              };
            }
            return chat;
          }),
        };
      } else {
        return {
          ...state,
          chats: [
            ...state.chats,
            {
              id: action.id,
              user: action.user,
              messages: action.messages,
            },
          ],
        };
      }
    default:
      return state;
  }
};
