import {
  LOAD_MYCONVERSATION,
  LOAD_MYMESSAGES,
  POST_MESSAGE,
  LOAD_PROFILE,
  LOAD_PROFILE_IMAGE,
  // LOAD_PROFILEERROR,
  // LOAD_MYGROUPS,
  REMOVE_CONVERSATION,
} from '../actionTypes';

// CONVERSATIONS REDUCER
const initialStateConversation = [];
export const myConversations = (state = initialStateConversation, action) => {
  switch (action.type) {
    case LOAD_MYCONVERSATION:
      return [...action.messages.reverse()];
    case REMOVE_CONVERSATION:
      return [...state.filter(conversation => conversation._id !== action.id)];
    case POST_MESSAGE:
      return [
        {
          ...state,
          messages: [{ ...state.concat(action.newMessage) }],
        },
      ];
    default:
      return state;
  }
};
// MESSAGES REDUCER
const initialStateMessages = { messages: [] };
export const myMessages = (state = initialStateMessages, action) => {
  switch (action.type) {
    case LOAD_MYMESSAGES:
      return { ...action.messages };
    case POST_MESSAGE:
      return [{ ...state.concat(action.newMessage) }];

    default:
      return state;
  }
};

// PROFILE
const initialStateProfile = {
  address: { lat: 43.6426, lng: -79.3871 },
  items: [],
  groups: [],
  page: true
};

export const myProfile = (state = initialStateProfile, action) => {
  switch (action.type) {
    case LOAD_PROFILE:
      return {
        ...action.profile,
      };
    case LOAD_PROFILE_IMAGE:
      return {
        ...state,
        image: action.image,
      };
    default:
      return state;
  }
};
// // USER GROUPS
// const initialStateGroups = [];

// export const myGroups = (state = initialStateGroups, action) => {
//   switch (action.type) {
//     case LOAD_MYGROUPS:
//       return [...action.groups];
//     default:
//       return state;
//   }
// };
