import { apiCall } from '../../services/api';
import { addError, removeError } from './errors';
import {
  LOAD_BUILDING,
  ADD_BUILDINGIMAGE,
  LOAD_FOUNDBUILDING,
} from '../actionTypes';

export const loadBuilding = building => ({
  type: LOAD_BUILDING,
  building,
});

export const fetchBuilding = buildingId => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall('get', `/api/buildings/${buildingId}`);
        dispatch(loadBuilding(res));
        resolve();
      } catch (err) {
        dispatch(addError(err?.message));
        reject();
      }
    });
  };
};
// finding building action using the code
export const loadFoundBuilding = building => ({
  type: LOAD_FOUNDBUILDING,
  building,
});

export const buildingCode = code => {
  return dispatch => {
    dispatch(removeError());
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall('post', '/api/auth/findbuilding', code);

        if (res.errorMessage) {
          dispatch(addError(res.errorMessage));
        } else {
          dispatch(loadFoundBuilding(res));
        }
        resolve(res);
      } catch (err) {
        dispatch(addError(err?.message));
        reject();
      }
    });
  };
};

// adding image to building action
export const buildingImage = image => ({
  type: ADD_BUILDINGIMAGE,
  image,
});
//Action to post a building
export const postBuildingImage =
  (buildingId, formData) => (dispatch, getState) => {
    const fd = new FormData();
    fd.append('image', formData.image);
    return apiCall('post', `/api/buildings/${buildingId}/image`, fd)
      .then(res => dispatch(buildingImage(res)))
      .catch(err => addError(err.message));
  };

//Action to post a Feedback
export const postFeedback =
  (buildingId, userId, feedBackData) => (dispatch, getState) => {
    return apiCall('post', `/api/buildings/feedback/${buildingId}/${userId}`, {
      ...feedBackData,
    })
      .then(res => dispatch(res))
      .catch(err => addError(err.message));
  };
//Action to post a Feedback
export const postRequest = requestData => (dispatch, getState) => {
  return apiCall('post', `/api/buildings/request`, {
    ...requestData,
  })
    .then(res => dispatch(res))
    .catch(err => addError(err.message));
};
//Action to post a contactus
export const postContact = contactData => (dispatch, getState) => {
  return apiCall('post', `/api/buildings/contact`, {
    ...contactData,
  })
    .then(res => dispatch(res))
    .catch(err => addError(err.message));
};
