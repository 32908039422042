import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// ==============================================================
import { fetchEvents } from '../../../store/actions/events';
import LeftSide from '../../components/LeftSide';
import {
	Row,
	Col,
	Alert,
	Card,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
} from 'reactstrap';
import pi from '../../../images/venice.jpeg';

import Moment from 'react-moment';
import Event from './Event';
import EventFormsModal from '../TheEvent/EventFormsModal';

const Events = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const errors = useSelector((state) => state.errors);
	const user = useSelector((state) => state.currentUser.user);
	const events = useSelector((state) => state.events.all);

	const nextEvents = events.filter((event) => {
		var m = moment.utc(event.date, 'YYYY-MM-DD');
		return m.isAfter();
	});

	const previousEvents = events.filter((event) => {
		var m = moment.utc(event.date, 'YYYY-MM-DD');
		return m.isBefore();
	});

	useEffect(() => {
		dispatch(fetchEvents(user.address));
		// eslint-disable-next-line
	}, []);

	return (
		<div className='d-flex'>
			<LeftSide nav='events' modalButton={<EventFormsModal />} />
			<Col md='9' className='d-flex justify-content-md-center mt-5'>
				<Col md='11'>
					{errors.message && (
						<div className='alert alert-danger'>{errors.message}</div>
					)}
					<h3 className='buildingEventsRow' style={{ marginTop: 10 }}>
						Building next events:
					</h3>
					{nextEvents.length === 0 && (
						<Col>
							<p>Unfortunately, we don't have any event coming</p>
						</Col>
					)}
					<Row>
						{nextEvents.map((event) => (
							<Event key={event._id} event={event} />
						))}
					</Row>
					{previousEvents.length > 0 && (
						<>
							<h5 className='buildingEventsRow' style={{ marginTop: 10 }}>
								Your previous events:
							</h5>
							<Row>
								{previousEvents.map((event) => (
									<Card
										className='userEventsCard mb-2'
										onClick={() => navigate(`/event/${event._id}`)}>
										<CardImg
											top
											src={event.image ? event.image : pi}
											alt=''
											style={{ filter: `grayscale(100%)`, objectFit: 'cover' }}
										/>
										<CardBody className='userEventsBody text-center'>
											<CardTitle className='userEventsText text-danger text-center'>
												<Moment className='text-muted' format='llll'>
													{event.date}
												</Moment>
											</CardTitle>
											<CardText className='userEventsText text-center'>
												{event.title}
											</CardText>
										</CardBody>
									</Card>
								))}
							</Row>
						</>
					)}
				</Col>
			</Col>
		</div>
	);
};

export default Events;
