import { combineReducers } from 'redux';
import currentUser from './currentUser';
import errors from './errors';
import success from './success';
import { landing } from './landing';
import { buildings } from './buildings';
import { building, foundBuilding } from './building';
import { myBuilding } from './myBuilding';
import { myNeighbors } from './neighbors';
import { myConversations } from './user';
import { myMessages } from './user';
import { items } from './items';
import { reset } from './resetPassword';
import { events } from './events';
import { borrows } from './borrows';
import { groups } from './groups';
import { myNotifications } from './notifications';
import { myMessageNotifications } from './notifications';
import { myProfile } from './user';
import { inbox } from './inbox';
import { feedsOpened } from './feeds';

const rootReducer = combineReducers({
  currentUser,
  landing,
  errors,
  success,
  buildings,
  building,
  foundBuilding,
  myBuilding,
  myConversations,
  myMessages,
  items,
  events,
  borrows,
  myProfile,
  groups,
  myNotifications,
  myMessageNotifications,
  myNeighbors,
  reset,
  inbox,
  feedsOpened,
});

export default rootReducer;
