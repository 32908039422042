import React from 'react';
// import BuildingInfoMain from './BuildingInfoMain';

const BuildingPage = props => {
  return (
    <div className="container">
      {/* <BuildingInfoMain {...props} /> */}
      Building Info
    </div>
  );
};

export default BuildingPage;
