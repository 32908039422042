import {
  LOAD_MYBUILDINGS,
  POST_FEED,
  EDIT_FEED,
  REMOVE_FEED,
  LIKE_FEED,
  POST_COMMENT,
  EDIT_COMMENT,
  REMOVE_COMMENT,
} from '../actionTypes';

import { mountPostFeed, mountLikeFeed, mountRemoveCommentFeed } from './feeds';

const initialState = {
  feed: [{ author: {}, comments: [], likes: [] }],
  total: 0,
};

export const myBuilding = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MYBUILDINGS:
      return {
        ...state,
        feed: [...action.myBuilding],
        total: action.myBuilding.length
      };

    case POST_FEED:
      return {
        ...state,
        feed: [action.feed, ...state.feed],
        total: state.feed.length+1
      };

    case POST_COMMENT:
    case EDIT_FEED:
    case EDIT_COMMENT:
      return  {
        ...state,
        feed: mountPostFeed(state.feed, action)
      };

    case REMOVE_FEED:
      return {
        ...state,
        feed: state.feed.filter(feed => feed._id !== action.id),
        total: state.feed.length-1
      };

    case LIKE_FEED:
      return {
        ...state,
        feed: mountLikeFeed(state.feed, action)
      };

    case REMOVE_COMMENT:
      return {
        ...state,
        feed: mountRemoveCommentFeed(state.feed, action)
      };

    default:
      return state;
  }
};
