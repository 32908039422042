import { apiCall } from '../../services/api';
import { addError } from './errors';

export function callConversations(messages) {
  return {
    type: 'GET_CONVERSATION',
    messages,
  };
}

export const getConversations = userId => {
  console.log(userId)
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall('get', `/api/user/${userId}/conversation`);       
        res.map(item =>
          dispatch({
            type: 'LOAD_CHATS',
            id: item._id,
            user: item.users.find(user => user._id !== userId),
            messages: item.messages,
          }),
        );

        dispatch(callConversations(res));
        resolve(res);
      } catch (err) {
        dispatch(addError(err?.message));
        reject();
      }
    });
  };
};

export const openConversation = openConversationId => {
  return {
    type: 'OPEN_CONVERSATION',
    openConversationId,
  };
};

export const openNotificationConversation = userId => {
  return {
    type: 'OPEN_BY_NOTIFICATION',
    userId,
  };
};

export const sendMessage = ({
  userId,
  recipientId,
  conversationId,
  theMessage,
}) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      var conId = '';
      if (!conversationId) {
        // const conversations = await dispatch(getConversations(userId));
        // const hasConversation = conversations.filter(i => {
        //   return i.withUser._id === recipientId
        // })
        // if (hasConversation.length > 0) {
        //   conversationId = hasConversation[0]._id;
        conId = 'new';
      } else {
        conId = conversationId;
      }

      try {
        await apiCall(
          'post',
          `/api/user/${userId}/message/${recipientId}/${conId}`,
          { text: theMessage },
        );

        dispatch(getConversations(userId));
      } catch (err) {
        return addError(err.message);
      }
    });
  };
};

export const archiveConversation = ({ userId, conversationId }) => {
  return async dispatch => {
    try {
      await apiCall(
        'post',
        `api/user/${conversationId}/conversation/${userId}`,
      );
      dispatch(openConversation(null));
      dispatch(getConversations(userId));
    } catch (err) {
      addError(err.message);
    }
  };
};
