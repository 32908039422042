import React from 'react';
import Navbar from '../../Navbar';
import EventFormsModal from './EventFormsModal';
import EventPageContent from './EventPageContent';
import withAuth from '../../../hocs/withAuth';

const EventPage = props => {
  return (
    <>
      <div className="navbarDiv">
        <Navbar modalButton={<EventFormsModal />} />
      </div>
      <div className="content-system">
        <EventPageContent {...props} />
      </div>
    </>
  );
};

export default withAuth(EventPage);
