import { apiCall } from '../../services/api';
import { addError } from './errors';
import { addSuccess } from './success';
import { LOAD_ITEMS, POST_ITEM, REMOVE_ITEM } from '../actionTypes';

// LOAD ITEMS ACTION
export const loadItems = buildingItems => ({
  type: LOAD_ITEMS,
  buildingItems,
});

export const fetchItems = buildingId => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall('get', `/api/building/items/${buildingId}`);
        dispatch(loadItems(res));
        resolve(); // indicate that the API call succeeded
      } catch (err) {
        dispatch(addError(err?.message));
        reject(); // indicate the API call failed
      }
    });
  };
};

export const itemUpdate = newItem => ({
  type: POST_ITEM,
  newItem,
});

//Action to post a feed
export const postItem =
  (buildingId, formData) => async (dispatch, getState) => {
    let { currentUser } = getState();
    const id = currentUser.user.id;
    const fd = new FormData();

    formData.files.map(file => fd.append('files[]', file));
    fd.append('image', formData.image);
    fd.append('description', formData.description);
    fd.append('price', formData.price);
    fd.append('title', formData.title);
    try {
      const res = await apiCall(
        'post',
        `/api/building/items/${buildingId}/${id}`,
        fd,
      );
      dispatch(itemUpdate(res));
      dispatch(addSuccess('You successfully created an item for sale.'));
    } catch (err) {
      return addError(err?.message);
    }
  };
export const removeI = id => ({
  type: REMOVE_ITEM,
  id,
});
export const removeItem = itemId => {
  return async dispatch => {
    try {
      await apiCall('delete', `/api/building/items/${itemId}`);
      return dispatch(removeI(itemId));
    } catch (err) {
      addError(err?.message);
    }
  };
};
