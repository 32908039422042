import React from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { editComment, removeComment } from '../../store/actions/feeds';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import FeedDropDown from './FeedDropDown';

import pi from '../../images/default-profile-image.jpg';
const Comment = ({ comment }) => {
  const user = useSelector(state => state.currentUser.user);
  const { _id, author, createdAt, text, feedId } = comment;

  const groupPage = useMatch('/group/:id');
  const eventPage = useMatch('/event/:id');
  let module = 'feed';
  if (groupPage) {
    module = 'group';
  } else if (eventPage) {
    module = 'event';
  }
  return (
    <div className="commentBox">
      <figure>
        <Link
          to={{ pathname: `/profile/${author._id}` }}
          className="reactRouterLinks"
        >
          <img
            className="userAsideImage"
            src={author.image || pi}
            alt={author.email}
          />
        </Link>
      </figure>
      <section>
        <header>
          <Link
            to={{ pathname: `/profile/${author._id}`, state: `${author._id}` }}
            className="reactRouterLinks"
          >
            {author.firstName}
          </Link>
          <span>-</span>
          <Moment className="text-muted feedDate" fromNow ago>
            {createdAt}
          </Moment>
          {user.id === author._id && (
            <div className="FeedDropDown">
              <FeedDropDown
                id={_id}
                text={text}
                onSubmit={editComment}
                onDelete={removeComment(_id, feedId, module)}
              />
            </div>
          )}
        </header>
        <article>{text}</article>
      </section>
    </div>
  );
};

export default Comment;
