import { apiCall } from '../../services/api';

import { addError } from './errors';
import {
  LOAD_GROUPS,
  CREATE_GROUP,
  JOIN_GROUP,
  REMOVE_GROUP,
  LOAD_GROUP_PAGE,
  // LOAD_MYGROUPINFO,
  POST_GROUPCOMMENT,
  POST_GROUPFEED,
  LOAD_MYGROUPS,
  // REMOVE_GROUPCOMMENTS,
  // REMOVE_GROUPFEEDS,
  LIKE_GROUPFEED,
  LEAVE_GROUP,
} from '../actionTypes';

export const fetchGroups = buildingId => async dispatch => {
  try {
    const response = await apiCall('get', `/api/building/groups/${buildingId}`);
    return dispatch({
      type: LOAD_GROUPS,
      groups: response,
    });
  } catch (err) {
    return addError(err?.message);
  }
};

export const createGroup = data => async (dispatch, getState) => {
  const { currentUser } = getState();
  const userId = currentUser.user.id;
  const fd = new FormData();
  fd.append('image', data.image);
  fd.append('description', data.description);
  fd.append('title', data.title);
  fd.append('name', '');
  try {
    const response = await apiCall(
      'post',
      `/api/building/groups/${data.buildingId}/${userId}`,
      fd,
    );
    return dispatch({
      type: CREATE_GROUP,
      newGroup: response,
    });
  } catch (err) {
    return addError(err?.message);
  }
};

export const removeGroup = groupId => {
  return async dispatch => {
    try {
      await apiCall('delete', `/api/building/groups/${groupId}`);
      return dispatch({
        type: REMOVE_GROUP,
        groupId,
      });
    } catch (err) {
      addError(err?.message);
    }
  };
};

export const joinGroup = (groupId, userId) => {
  return async dispatch => {
    try {
      const response = await apiCall(
        'post',
        `/api/building/groups/${groupId}/join/${userId}`,
      );
      return dispatch({
        type: JOIN_GROUP,
        group: response,
      });
    } catch (err) {
      addError(err?.message);
    }
  };
};

export const fetchMyGroups = userId => async dispatch => {
  try {
    const response = await apiCall('get', `/api/user/${userId}/groups`);
    return dispatch({
      type: LOAD_MYGROUPS,
      groups: response,
    });
  } catch (err) {
    return addError(err?.message);
  }
};

export const fetchGroupPage = groupId => dispatch => {
  try {
    const responsePage = new Promise(async (resolve, reject) => {
      try {
        const response = await apiCall(
          'get',
          `/api/building/${groupId}/groupinfo`,
        );
        resolve(response);
      } catch (err) {
        dispatch(addError(err?.message))
        dispatch({
          type: LOAD_GROUP_PAGE,
          group: {
            page: false,
          },
        });
        reject();
      }
    });
    const responseFeed = new Promise(async (resolve, reject) => {
      try {
        const response = await apiCall('get', `/api/building/${groupId}/group`);
        resolve(response);
      } catch (err) {
        dispatch(addError(err?.message))
        dispatch({
          type: LOAD_GROUP_PAGE,
          group: {
            page: false,
          },
        });
        reject();
      }
    });
    Promise.all([responsePage, responseFeed]).then(response => {
      return dispatch({
        type: LOAD_GROUP_PAGE,
        group: {
          page: response[0],
          feed: response[1],
        },
      });
    });
    return;
  } catch (err) {
    return dispatch(addError(err?.message));
  }
};

export const leaveGroup = (groupId, userId) => async dispatch => {
  try {
    const response = await apiCall(
      'post',
      `/api/building/groups/${groupId}/quit/${userId}`,
    );
    return dispatch({
      type: LEAVE_GROUP,
      group: response,
    });
  } catch (err) {
    return addError(err?.message);
  }
};

export const postGroupComment = (feedId, text) => async (
  dispatch,
  getState,
) => {
  const { currentUser } = getState();
  try {
    const response = await apiCall(
      'post',
      `/api/building/${currentUser.user.id}/group/${feedId}/comment`,
      { text },
    );
    return dispatch({
      type: POST_GROUPCOMMENT,
      feedId,
      feed: response,
    });
  } catch (err) {
    return addError(err?.message);
  }
};

export const postGroupFeed = (groupId, formData) => async (
  dispatch,
  getState,
) => {
  const { currentUser } = getState();
  const fd = new FormData();
  fd.append('text', formData.text);
  if (formData.image) {
    fd.append('image', formData.image);
  }

  try {
    const response = await apiCall(
      'post',
      `/api/building/${currentUser.user.id}/group/${groupId}/feed`,
      fd,
    );
    return dispatch({
      type: POST_GROUPFEED,
      feed: response,
    });
  } catch (err) {
    return addError(err?.message);
  }
};

export const likeGroupFeed = (feedId, userId) => {
  return async dispatch => {
    try {
      const response = await apiCall(
        'post',
        `/api/building/likegroupfeed/${feedId}/${userId}`,
      );
      return dispatch({
        type: LIKE_GROUPFEED,
        feedId,
        likes: response,
      });
    } catch (err) {
      addError(err?.message);
    }
  };
};
