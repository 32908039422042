import { apiCall } from '../../services/api';
import { addError } from './errors';
import {
  LOAD_MYNOTIFICATIONS,
  READ_NOTIFICATION,
  LOAD_MYMESSAGENOTIFICATIONS,
  READ_MESSAGENOTIFICATION,
} from '../actionTypes';

// LOAD Notification action
export const loadNotifications = notifications => ({
  type: LOAD_MYNOTIFICATIONS,
  notifications,
});

export const fetchNotifications = userId => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall('get', `/api/user/${userId}/notifications`);
        dispatch(loadNotifications(res));
        resolve(); // indicate that the API call succeeded
      } catch (err) {
        dispatch(addError(err?.message));
        reject(); // indicate the API call failed
      }
    });
  };
};
// LOAD Notification action
export const loadMessageNotifications = notifications => ({
  type: LOAD_MYMESSAGENOTIFICATIONS,
  notifications,
});

export const fetchMessageNotifications = userId => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall(
          'get',
          `/api/user/${userId}/messageNotifications`,
        );
        // dispatch(loadMessageNotifications(res));
        resolve(); // indicate that the API call succeeded
      } catch (err) {
        // dispatch(addError(err?.message));
        reject(); // indicate the API call failed
      }
    });
  };
};
// READ Notification action
export const notificationIsRead = notificationId => ({
  type: READ_NOTIFICATION,
  notificationId,
});

export const notificationMessageIsRead = notificationId => ({
  type: READ_MESSAGENOTIFICATION,
  notificationId,
});

export const messageNotificationClicked = (
  notificationId,
  currentUserId,
  authorId,
) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall(
          'post',
          `/api/user/notifications/${notificationId}/${currentUserId}/${authorId}/messagenotification`,
        );
        dispatch(notificationIsRead(res));
        dispatch(notificationMessageIsRead(res));
        resolve(); // indicate that the API call succeeded
      } catch (err) {
        dispatch(addError(err?.message));
        reject(); // indicate the API call failed
      }
    });
  };
};
export const notificationClicked = (
  notificationId,
  currentUserId,
  authorId,
) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall(
          'post',
          `/api/user/notifications/${notificationId}/${currentUserId}/${authorId}`,
        );
        dispatch(notificationIsRead(res));
        // dispatch(notificationMessageIsRead(res));
        resolve(); // indicate that the API call succeeded
      } catch (err) {
        dispatch(addError(err?.message));
        reject(); // indicate the API call failed
      }
    });
  };
};