import React from 'react';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';

import UserAside from '../../components/UserAside';

import { ListGroup, ListGroupItem } from 'reactstrap';
import { SideBlock } from '../../../styles/groups';

const EventPageRightSide = () => {
  const page = useSelector(state => state.events.opened);

  return (
    <>
      <SideBlock>
        <h5>Event Information</h5>
        <p>
          Host by {page.author.firstName} {page.author.lastName}
        </p>
        <p>
          When <Moment format="llll">{page.date}</Moment>
        </p>
      </SideBlock>
      <SideBlock>
        <h5>Members</h5>
        <ListGroup className="members-list">
          {page.participants.map(member => (
            <ListGroupItem key={member._id}>
              <UserAside user={member} id={member._id} />
            </ListGroupItem>
          ))}
        </ListGroup>
      </SideBlock>
    </>
  );
};

export default EventPageRightSide;
