import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchProfile } from '../../store/actions/user';
import MyProfilePannel from './MyProfilePannel';
import MyProfileHeader from './MyProfileHeader';
import LeftSide from '../components/LeftSide';
import { Col } from 'reactstrap';

const MyProfile = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const currentUser = useSelector(state => state.currentUser);
  const myProfile = useSelector(state => state.myProfile);
  const errors = useSelector(state => state.errors);

  useEffect(() => {
    dispatch(fetchProfile(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex">
      <LeftSide />
      <Col md="9" className="justify-content-md-center mt-5">
        <MyProfileHeader
          user={myProfile}
          currentUser={currentUser}
          errors={errors}
        />
        {myProfile.id && (
          <MyProfilePannel
            errors={errors}
            user={myProfile}
            lat={myProfile?.address?.lat}
            lng={myProfile?.address?.lng}
            currentUser={currentUser}
          />
        )}
      </Col>
    </div>
  );
};

export default MyProfile;
