import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postFeedback } from '../../store/actions/building';
import mainbackground from '../../images/la22.png';
import Navbar from '../Navbar';
import withAuth from 'hocs/withAuth';

import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';

const TheFeedback = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.currentUser.user);
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    const buildingId = currentUser.address;
    const userId = currentUser.id;
    dispatch(postFeedback(buildingId, userId, { subject, text }));
    setSubject('');
    setText('');
    navigate(`/feedback`, {
      state: `You successfully sent your feedback Thank You!`,
    });
  };

  return (
    <div>
      <Navbar />
      <Row className="container  content-system">
        <img
          src={mainbackground}
          className="signinBackground"
          alt="background feedback"
        />
        <Col md="2"></Col>
        <Col md="8" className="signinContentsRight">
          <h2 className="signinContentHeader">Give your Feedback</h2>

          <Form onSubmit={handleSubmit}>
            <FormGroup row>
              <Label for="examplePassword" sm={2}>
                Subject
              </Label>
              <Col sm={10}>
                <Input
                  autoComplete="off"
                  id="subject"
                  name="subject"
                  onChange={e => setSubject(e.target.value)}
                  type="text"
                  value={subject}
                  placeholder="subject"
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleSelect" sm={2}>
                Feedback
              </Label>
              <Col sm={10}>
                <Input
                  autoComplete="off"
                  id="text"
                  name="text"
                  onChange={e => setText(e.target.value)}
                  type="textarea"
                  rows="6"
                  value={text}
                  placeholder="text"
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup check row>
              <Button color="info" size="sm">
                Submit
              </Button>
            </FormGroup>
          </Form>
        </Col>
        <Col md="2"></Col>
      </Row>
    </div>
  );
};

export default withAuth(TheFeedback);
