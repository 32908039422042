import styled from 'styled-components';

export const ListGroups = styled.div`
  .media {
    margin-bottom: 1rem;
    align-items: stretch;
    background: #fff;

    .media-body {
      padding: 1rem;
    }

    img {
      max-width: 250px;
      object-fit: cover;
      flex: 1;
    }

    h4 {
      font-size: 1rem;
      margin: 0;
    }

    button {
      width: 100px;
      margin-left: 1rem;
      padding: 0;
    }

    small {
      margin-bottom: 0.5rem;
      display: block;
    }

    p {
      margin: 0;
    }
  }

  .card-deck {
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
    gap: 1rem;
  }

  .card-img-top {
    height: 150px;
    object-fit: cover;
  }
`;

export const HeaderGroup = styled.header`
  width: 100%;
  display: inline-block;
  margin-bottom: 1rem;
  color: #333;
  background: #fff;

  .group-cover {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  > div {
    padding: 1rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;

    button:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
    padding: 1rem;
  }

  aside {
    max-width: 50%;
    small {
      display: inline-block;
    }
  }

  ul {
    margin: 0;
    list-style: none;

    li {
      float: right;
      margin-left: -10px;
    }

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

export const SideBlock = styled.div`
  width: 100%;
  display: inline-block;
  padding: 1rem 0;
  margin-bottom: 1rem;
  color: #333;
  overflow-wrap: break-word;
  background: #fff;

  h5 {
    border-bottom: 1px solid #ddd;
    padding: 0 1rem;
  }

  p,
  ul {
    padding: 0 1rem;
    margin: 0;
    list-style: none;
  }

  ul {
    text-transform: lowercase;
  }

  .members-list {
    overflow-x: auto;
    max-height: 300px;
    padding-top: 1rem;
    li {
      padding: 0;
      border: 0;
    }
    li + li {
      margin-top: 1rem;
    }
    a {
      display: flex;
      align-items: center;
      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
      }

      strong {
        font-weight: 400;
      }
    }
  }
`;

export const DiscussionForm = styled.div`
  form {
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    background: #fff;

    > div {
      display: flex;
      align-items: center;
    }

    img {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
      border-radius: 50%;
      object-fit: cover;
    }

    textarea {
      width: 100%;
      height: 80px;
      margin-bottom: 0.5rem;
      padding: 1rem;
      border: 1px solid #ddd;
      background: #fff;
    }

    button {
      padding: 0.25rem 1rem;
    }
  }
`;
export const Discussion = styled.ol`
  list-style: none;

  li {
    display: flex;
    margin-bottom: 1rem;
    background: #fff;
  }

  img {
    width: 2rem;
    height: 2rem;
    margin: 1rem 0 0 1rem;
    border-radius: 50%;
    object-fit: cover;
  }

  section {
    padding: 1rem;
    flex: 1;
  }

  small {
    display: block;
  }

  p {
    margin: 0;
    overflow-wrap: break-word;
  }
`;

export const DiscussionFeed = styled.div`
  .feedBox {
    margin-bottom: 0;
  }
  .feedBoxHead {
    header {
      display: flex;
      .conversation-info {
        display: flex;
        flex-direction: column;
      }

      time {
        font-size: 0.75em;
      }
    }
  }

  .feesText {
    margin: 0;
    padding: 15px;
  }

  .form-reply {
    padding: 15px;
    display: flex;
    flex-direction: column;
    textarea {
      width: 100%;
      height: 2.5rem;
      overflow: hidden;
      margin-bottom: 0.5rem;
      padding: 0.5rem 1rem;
      border: 1px solid #ddd;
      background: #fff;
      transition: 0.2s;

      &:focus,
      &:not(:placeholder-shown) {
        height: 80px;
        overflow: auto;
      }
    }

    button {
      padding: 0.25rem 1rem;
    }
  }

  .commentBox {
    padding: 0 1rem 1rem;
    margin: 0;
    display: flex;
    background: none;

    figure {
      margin: 0 0.5rem 0 0;
    }

    > section {
      flex: 1;
      padding: 0 0.5rem;
      background: rgb(250, 250, 250);
    }

    header {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      > span {
        margin: 0 0.25rem;
      }
      .FeedDropDown {
        margin-left: auto;
      }
    }
  }

  .like-active {
    color: #4169e1;
  }
  .like-text {
    margin-top: 5px;
  }
`;

export const ActionsFeed = styled.div`
  margin: 0 15px;
  padding: 0 0 15px;
  section {
    margin-right: 0.5rem;
    margin-bottom: 10px;
    padding: 5px 0;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    span {
      margin-right: 0.5rem;
      color: #0056b3;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  > div {
    padding: 5px;
    display: inline-block;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      background: #ddd;
    }
  }
`;
