import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardImg,
} from 'reactstrap';
import { editImage } from '../../store/actions/user';

const ProfileImageModal = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newImage, setNewImage] = useState('');
  const [modal, setModal] = useState(false);
  const { image, email, currentUser, userId } = props;
  const toggle = () => setModal(!modal);
  const imageSelectedHandler = event => {
    setNewImage(event.target.files[0]);
  };
  const handleSubmit = event => {
    event.preventDefault();
    // const image = event.target.files[0];
    const id = props.currentUser;
    const { userId } = props;
    console.log(userId);
    dispatch(editImage(id, newImage));
    navigate(`/profile/${userId}`);
    setModal(!modal);
  };
  return (
    <div>
      <Button color="secondary" onClick={toggle} block>
        View image
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className={props.className}
      >
        <Card>
          <ModalHeader>User image </ModalHeader>
          <ModalBody>
            <CardImg top src={image} alt={email} />
            {currentUser === userId && (
              <form onSubmit={handleSubmit}>
                <input
                  type="file"
                  name="file"
                  onChange={imageSelectedHandler}
                />

                <Button type="submit" color="secondary">
                  Change Image
                </Button>
              </form>
            )}
          </ModalBody>
        </Card>
      </Modal>
    </div>
  );
};

export default ProfileImageModal;
