import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const SignUpAside = () => {
  const { image, address } = useSelector(state => state.building);

  if (!address) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      className="pl-lg-5"
    >
      <div className="d-flex">
        <div className="col-6 col-md-8 col-lg-10 mx-auto">
          <img
            src={image[0]}
            alt={address}
            className="img-thumbnail rounded mb-2"
          />
        </div>
      </div>

      <h4 className="mb-2 text-center" style={{ fontSize: 'calc(12px + 1vw)' }}>
        {address}
      </h4>

      <div className="alert alert-light" role="alert">
        <small>
          If this is not your building or you don't have your{' '}
          <strong>Building Code</strong>, please click&nbsp;
          <Link to="/request">here</Link> so we can provide you one.
        </small>
      </div>
    </div>
  );
};

export default SignUpAside;
