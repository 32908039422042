/*global localStorage */

import { apiCall } from '../../services/api';
import { setAuthorizationToken } from './auth';
import { addError } from './errors';
import {
  LOAD_MYCONVERSATION,
  LOAD_MYMESSAGES,
  POST_MESSAGE,
  LOAD_PROFILE,
  LOAD_PROFILE_IMAGE,
  LOAD_MYGROUPS,
  REMOVE_CONVERSATION,
  SET_CURRENT_USER_IMAGE,
  // EDIT_USEREMAIL,
  // LOAD_PROFILEERROR
} from '../actionTypes';

// LOAD CONVERSATION ACTION
export const loadMyConversation = messages => ({
  type: LOAD_MYCONVERSATION,
  messages,
});

export const fetchConversation = userId => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall('get', `/api/user/${userId}/conversation`);
        dispatch(loadMyConversation(res));
        resolve(); // indicate that the API call succeeded
      } catch (err) {
        dispatch(addError(err?.message));
        reject(); // indicate the API call failed
      }
    });
  };
};
// TO REMOVE THE CONVERSATION
export const removeCon = id => ({
  type: REMOVE_CONVERSATION,
  id,
});
export const removeConversation = (userId, conversationId) => {
  return async dispatch => {
    try {
      await apiCall(
        'delete',
        `/api/user/${conversationId}/conversation/${userId}`,
      );
      return dispatch(removeCon(conversationId));
    } catch (err) {
      addError(err.message);
    }
  };
};
// LOAD MESSAGES ACTION
export const loadMyMessages = messages => ({
  type: LOAD_MYMESSAGES,
  messages,
});

export const fetchMessages = conversationId => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall('get', `/api/user/${conversationId}/message`);
        dispatch(loadMyMessages(res));
        resolve(); // indicate that the API call succeeded
      } catch (err) {
        dispatch(addError(err?.message));
        reject(); // indicate the API call failed
      }
    });
  };
};

//Action to post a message
export const messagePost = newMessage => ({
  type: POST_MESSAGE,
  newMessage,
});

export const postMessage = (
  currentUserId,
  audienceId,
  conversationId,
  text,
) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      if (conversationId === 'new') {
        try {
          const res = await apiCall(
            'post',
            `/api/user/${currentUserId}/message/${audienceId}/new`,
            { text },
          );
          dispatch(messagePost(res));
          resolve();
        } catch (err) {
          addError(err.message);
          reject();
        }
      } else {
        try {
          const res = await apiCall(
            'post',
            `/api/user/${currentUserId}/message/${audienceId}/${conversationId}`,
            { text },
          );
          dispatch(messagePost(res));
        } catch (err) {
          return addError(err.message);
        }
      }
    });
  };
};

// PROFILE PAGE ACTIONS
export const loadProfile = profile => ({
  type: LOAD_PROFILE,
  profile,
});

export const fetchProfile = userId => {
  return dispatch => {
    return apiCall('get', `/api/user/${userId}/profile`)
      .then(res => {
        dispatch(loadProfile(res));
      })
      .catch(err => {
        dispatch(addError(err?.message));
      });
  };
};

// EDIT User Profile
export const editProfile = (userId, fields) => {
  return async dispatch => {
    try {
      const res = await apiCall('put', `/api/user/${userId}/profile`, fields);
      return dispatch(loadProfile(res));
    } catch (err) {
      addError(err.message);
    }
  };
};

// EDIT THE IMAGE
export const changeImage = imageURL => ({
  type: LOAD_PROFILE_IMAGE,
  image: imageURL,
});
export const changeImageOnCurrentUser = imageURL => ({
  type: SET_CURRENT_USER_IMAGE,
  image: imageURL,
});

export const editImage = (userId, formData) => {
  return async dispatch => {
    const fd = new FormData();
    fd.append('image', formData);
    try {
      const res = await apiCall('put', `/api/user/${userId}/profile/image`, fd);
      dispatch(changeImage(res.image));
      dispatch(changeImageOnCurrentUser(res.image));
      localStorage.setItem('jwtToken', res.token);
      setAuthorizationToken(res.token);
    } catch (err) {
      addError(err.message);
    }
  };
};

// EDIT THE Password
export const editPassword = (userId, userData) => {
  return async dispatch => {
    try {
      const res = await apiCall('put', `/api/user/${userId}/profile/password`, {
        ...userData,
      });
      if (res === 'You Entered a wrong password') {
        return dispatch(addError(res));
      }
      return dispatch(res);
    } catch (err) {
      addError(err.message);
    }
  };
};

// LOAD THE USERS GROUPS
export const loadMyGroups = groups => ({
  type: LOAD_MYGROUPS,
  groups,
});

export const fetchMyGroups = userId => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall('get', `/api/user/${userId}/groups`);
        dispatch(loadMyGroups(res));
        resolve(); // indicate that the API call succeeded
      } catch (err) {
        dispatch(addError(err?.message));
        reject(); // indicate the API call failed
      }
    });
  };
};
