import {
  LOAD_MYNOTIFICATIONS,
  READ_NOTIFICATION,
  LOAD_MYMESSAGENOTIFICATIONS,
  READ_MESSAGENOTIFICATION,
} from '../actionTypes';

// CONVERSATIONS REDUCER
const initialState = [{ author: {} }];
export const myNotifications = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MYNOTIFICATIONS:
      return [...action.notifications];
    case READ_NOTIFICATION:
      return [
        ...state.filter(
          notification => notification._id !== action.notificationId,
        ),
      ];
    default:
      return state;
  }
};
//Messagenotification reducer
export const myMessageNotifications = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MYMESSAGENOTIFICATIONS:
      return [...action.notifications];
    case READ_MESSAGENOTIFICATION:
      const notificationRef = state.find(
        notification => notification._id === action.notificationId,
      );
      return [
        ...state.filter(
          notification =>
            notification.author._id !== notificationRef.author._id,
        ),
      ];
    default:
      return state;
  }
};
