import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createGroup } from '../../../store/actions/groups';
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from 'reactstrap';

class GroupFormOld extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      image: null,
      name: '',
      title: '',
    };
  }
  handleSubmit = event => {
    const buildingId = this.props.currentUser.user.address;
    const {image, title, description} = this.state;
    event.preventDefault();
    this.props.createGroup({buildingId, image, title, description});
    this.setState({});
    this.props.history.push('/groups');
  };
  imageSelectedHandler = event => {
    this.setState({
      image: event.target.files[0],
    });
  };
  render() {
    const { description, name, title } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup row>
          <Label for="exampleEmail" sm={2}>
            Name
          </Label>
          <Col sm={10}>
            <Input
              autoComplete="off"
              id="name"
              name="name"
              onChange={e => this.setState({ name: e.target.value })}
              type="text"
              value={name}
              placeholder="name"
              required
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="examplePassword" sm={2}>
            Title
          </Label>
          <Col sm={10}>
            <Input
              autoComplete="off"
              id="title"
              name="title"
              onChange={e => this.setState({ title: e.target.value })}
              type="text"
              value={title}
              placeholder="title"
              required
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="exampleSelect" sm={2}>
            Description
          </Label>
          <Col sm={10}>
            <Input
              autoComplete="off"
              id="description"
              name="description"
              onChange={e => this.setState({ description: e.target.value })}
              type="textarea"
              value={description}
              placeholder="description"
              required
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="exampleFile" sm={2}>
            Add Image
          </Label>
          <Col sm={10}>
            <Input
              type="file"
              name="file"
              onChange={this.imageSelectedHandler}
              id="exampleFile"
            />
            <FormText color="muted">
              This is some placeholder block-level help text for the above
              input. It's a bit lighter and easily wraps to a new line.
            </FormText>
          </Col>
        </FormGroup>
        <FormGroup check row>
          <Button color="info" size="sm" block>
            Submit
          </Button>
        </FormGroup>
      </Form>
    );
  }
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    errors: state.errors,
  };
}
export default connect(mapStateToProps, { createGroup })(GroupFormOld);
