import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postContact } from '../../store/actions/building';
import NavMain from 'views/components/Navbar';
import Footer from 'views/components/Footer';
// import mainbackground from "../images/la22.png";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
} from 'reactstrap';

const TheFeedback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [fullname, setFullname] = useState('');
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const handleSubmit = event => {
    event.preventDefault();
    dispatch(postContact({ email, address, fullname, subject, text }));
    setEmail('');
    setAddress('');
    setFullname('');
    setSubject('');
    setText('');
    navigate(`/contactus`);
  };

  return (
    <div>
      <NavMain />
      <Container>
        <Row>
          <Col md="2" sm="12"></Col>
          <Col md="8" sm="12" className="ContactusContentsRight">
            <h2 className="signinContentHeader">Contact us</h2>
            <Form onSubmit={handleSubmit}>
              <FormGroup row>
                <Label for="examplePassword" sm={2}>
                  Full Name
                </Label>
                <Col sm={10}>
                  <Input
                    autoComplete="off"
                    id="name"
                    name="fullname"
                    onChange={e => setFullname(e.target.value)}
                    type="text"
                    value={fullname}
                    placeholder="Full Name"
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="examplePassword" sm={2}>
                  Address
                </Label>
                <Col sm={10}>
                  <Input
                    autoComplete="off"
                    id="address"
                    name="address"
                    onChange={e => setAddress(e.target.value)}
                    type="text"
                    value={address}
                    placeholder="Please Enter your building address"
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="examplePassword" sm={2}>
                  Subject
                </Label>
                <Col sm={10}>
                  <Input
                    autoComplete="off"
                    id="subject"
                    name="subject"
                    onChange={e => setSubject(e.target.value)}
                    type="text"
                    value={subject}
                    placeholder="Subject"
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="examplePassword" sm={2}>
                  Email
                </Label>
                <Col sm={10}>
                  <Input
                    autoComplete="off"
                    id="email"
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    value={email}
                    placeholder="Email"
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleSelect" sm={2}>
                  Your Message
                </Label>
                <Col sm={10}>
                  <Input
                    autoComplete="off"
                    id="text"
                    name="text"
                    onChange={e => setText(e.target.value)}
                    type="textarea"
                    rows="6"
                    value={text}
                    placeholder="We will be happy to hear from you"
                  />
                </Col>
              </FormGroup>
              <FormGroup check row>
                <Button color="info" size="lg">
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </Col>
          <Col md="2" sm="12"></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default TheFeedback;
