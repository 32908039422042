import React from 'react';
import PropTypes from 'prop-types';

export default function CardHeader(props) {
  const { className, children, color, plain, ...rest } = props;

  return <div {...rest}>{children}</div>;
}

CardHeader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['warning', 'success', 'danger', 'info', 'primary']),
  plain: PropTypes.bool,
  children: PropTypes.node,
};
