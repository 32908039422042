import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMatch, useParams } from 'react-router-dom';

import { postFeed } from '../../store/actions/feeds';
import { postGroupFeed } from '../../store/actions/groups';
import { postEventFeed } from '../../store/actions/events';

import { Row, Col, Input, Button } from 'reactstrap';

import { FaPhotoVideo } from 'react-icons/fa';
import { AiOutlineLoading } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';

const FeedForm = () => {
  const dispatch = useDispatch();
  const { address } = useSelector(state => state.currentUser.user);
  const [text, setText] = useState('');
  const [image, setImage] = useState('');
  const [sending, setSending] = useState(false);
  const [attrDisabled, setAttrDisabled] = useState({});
  const inputFile = useRef(null);
  const groupPage = useMatch('/group/:id');
  const eventPage = useMatch('/event/:id');
  const { id } = useParams();

  const handlePostSubmit = async e => {
    e.preventDefault();
    setSending(true);
    setAttrDisabled({ disabled: true });

    if (groupPage) {
      await dispatch(postGroupFeed(id, { text, image }));
    } else if (eventPage) {
      await dispatch(postEventFeed(id, { text, image }));
    } else {
      await dispatch(postFeed(address, { text, image }));
    }

    setText('');
    setSending(false);
    setAttrDisabled({});
    handleClearInputFile();
  };

  const handleClearInputFile = () => {
    inputFile.current.value = '';
    setImage('');
  };

  return (
    <form onSubmit={handlePostSubmit}>
      <h5>Create a post</h5>
      <Row>
        <Col>
          <Input
            type="textarea"
            placeholder="Write here"
            onChange={e => setText(e.target.value)}
            value={text}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            type="button"
            color="primary"
            round
            justIcon
            onClick={() => inputFile.current.click()}
          >
            <FaPhotoVideo />
          </Button>
          <input
            ref={inputFile}
            onChange={e => setImage(e.target.files[0])}
            type="file"
            style={{ display: 'none' }}
          />
          {image && (
            <div className="post-preview" onClick={handleClearInputFile}>
              <img src={URL.createObjectURL(image)} alt="preview" />
              <BsTrash />
            </div>
          )}
        </Col>
        <Col>
          <Button
            type="submit"
            color="success"
            className="float-right"
            {...attrDisabled}
          >
            {sending ? <AiOutlineLoading className="spinner" /> : 'Post'}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default FeedForm;
