import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchItems, removeItem } from '../../../../store/actions/items';
import LeftSide from '../../../components/LeftSide';
import Item from './Item';
import { Row, Col, Alert } from 'reactstrap';
import ItemFormsModal from './ItemFormsModal';
import { removeSuccess } from '../../../../store/actions/success';

const Items = () => {
	const dispatch = useDispatch();
	const errors = useSelector((state) => state.errors);
	const success = useSelector((state) => state.success);
	const items = useSelector((state) => state.items);
	const currentUser = useSelector((state) => state.currentUser);
	useEffect(() => {
		dispatch(fetchItems(currentUser.user.address));
	}, []);
	useEffect(() => {
		let removeS = setTimeout(() => dispatch(removeSuccess()), 2000);
		return () => {
			clearTimeout(removeS);
		};
	}, []);
	return (
		<div className='d-flex'>
			<LeftSide nav='items' modalButton={<ItemFormsModal />} />
			<Col md='9' className='d-flex justify-content-md-center mt-5'>
				<Col md='11' className='p-0'>
					<h5 className='p-10'>List of items for sale in your building</h5>
					{errors.message && <Alert severity='error'>{errors?.message}</Alert>}
					{success.message && (
						<Alert severity='error'>{success?.message}</Alert>
					)}
					<Row>
						{items.map((item) => (
							<Item
								key={item._id}
								id={item._id}
								image={item.image}
								price={item.price}
								title={item.title}
								description={item.description}
								author={item.author}
								isCorrectUser={currentUser.user.id === item.author._id}
								onDelete={removeItem.bind(this, item._id)}
								currentUserId={currentUser.user.id}
								audiencId={item.author._id}
							/>
						))}
					</Row>
				</Col>
			</Col>
		</div>
	);
};
export default Items;
