import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Headroom from 'headroom.js';

import {
	Collapse,
	Button,
	NavbarBrand,
	NavbarToggler,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
} from 'reactstrap';
import logo from 'assets/img/brand/logo.png';

const NavMain = () => {
	const location = useLocation();
	const [collapseOpen, toggleCollapse] = React.useState(false);
	React.useEffect(() => {
		let headroom = new Headroom(document.getElementById('dark-navbar-main'));
		headroom.init();
	});

	return (
		<>
			<Navbar
				className='navbar-main headroom navbar-transparent'
				expand='lg'
				id='dark-navbar-main'>
				<Container>
					<NavbarBrand className='mr-lg-5' to='/' tag={Link}>
						<img alt='...' style={{ height: 50 }} src={logo}></img>
					</NavbarBrand>
					<NavbarToggler onClick={() => toggleCollapse(!collapseOpen)} />
					<Collapse navbar isOpen={collapseOpen}>
						<div className='navbar-collapse-header'>
							<Row>
								<Col className='collapse-brand' xs='6'>
									<Link to='/'>
										<img alt='...' src={logo}></img>
									</Link>
								</Col>
								<Col className='collapse-close' xs='6'>
									<button
										className='navbar-toggler navbarBergerLines'
										onClick={() => toggleCollapse(!collapseOpen)}>
										<span></span>
										<span></span>
										<span></span>
									</button>
								</Col>
							</Row>
						</div>
						<Nav
							className='navbar-nav-hover align-items-lg-center ml-lg-auto'
							navbar>
							<NavLink href='https://www.mylocals.shop/'>
								<Button className='btn-round' color='warning' type='button'>
									local business
								</Button>
							</NavLink>
							<NavLink href='/signup'>
								{location.pathname != '/signup' && (
									<Button className='btn-round' type='button'>
										Sign up
									</Button>
								)}
							</NavLink>
							<NavLink href='/signin'>
								{location.pathname != '/signin' && (
									<Button className='btn-round' type='button'>
										Sign in
									</Button>
								)}
							</NavLink>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		</>
	);
};

NavMain.defaultProps = {
	type: 'white',
};

NavMain.propTypes = {
	type: PropTypes.oneOf(['dark', 'transparent', 'primary', 'white']),
};

export default NavMain;
