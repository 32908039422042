import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import Navbar from '../Navbar';
import { postBuilding } from '../../store/actions/buildings';

const TheBuilding = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const errors = useSelector((state) => state.errors);
	const [address, setAddress] = useState('');
	const [image, setImage] = useState('');
	const [code, setCode] = useState('');
	const handleSubmit = (event) => {
		event.preventDefault();
		dispatch(postBuilding({ address, image, code }));
		setAddress('');
		setImage('');
		setCode('');
		navigate('/buildings');
	};
	const imageSelectedHandler = (event) => {
		setImage(event.target.files[0]);
	};
	const renderPlace = () => {
		loadScript(
			'https://maps.googleapis.com/maps/api/js?key=AIzaSyADEZPBh282-oTuLOBDAEwpfNiIq5vCQYg&libraries=places&callback=activatePlaceSearch'
		);
		window.activatePlaceSearch = activatePlaceSearch;
	};
	const activatePlaceSearch = () => {
		var input = document.getElementById('search_term');
		var autocomplete = new window.google.maps.places.Autocomplete(input);
	};
	useEffect(() => {
		renderPlace();
	}, []);
	return (
		<div>
			<div className='feedForm container'>
				<div className='feedFormHeader'>
					<h6 className='createApost'>Create a Building</h6>
				</div>
				<div className='feedFormInput'>
					<form onSubmit={handleSubmit}>
						{errors.message && (
							<div className='alert alert-danger'>{errors.message}</div>
						)}
						<input onChange={imageSelectedHandler} type='file' />
						<input
							autoComplete='off'
							className='form-control'
							id='search_term'
							name='address'
							onChange={(e) => setAddress(e.target.value)}
							type='text'
							value={address}
							placeholder='address'
							required
						/>
						<input
							autoComplete='off'
							className='form-control'
							id='code'
							name='code'
							onChange={(e) => setCode(e.target.value)}
							type='password'
							value={code}
							placeholder='code'
							required
						/>
						<button type='submit' className='feedFormButton'>
							post
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

function loadScript(url) {
	var index = window.document.getElementsByTagName('script')[0];
	var script = window.document.createElement('script');
	script.src = url;
	script.async = true;
	script.defer = true;
	index.parentNode.insertBefore(script, index);
}

export default TheBuilding;
