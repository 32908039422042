import React, { useState } from 'react';
import UserAside from '../components/UserAside';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const FeedLikeModal = props => {
  const { className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <span>
      <span onClick={toggle}>{props.likeLenght} Likes</span>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className + ' modal-like '}
      >
        <ModalHeader toggle={toggle}>Likes</ModalHeader>
        <ModalBody>
          {props.authors.map(user => (
            <div key={user._id + new Date().getTime()} className="mainFeedBox">
              <UserAside user={user} id={user._id} />
            </div>
          ))}
        </ModalBody>
      </Modal>
    </span>
  );
};

export default FeedLikeModal;
