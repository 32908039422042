import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import MessageForms from '../message/Compose';

const ModalMessaging = props => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button color="info" onClick={toggle} className={props.className}>
        Send a Message
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={props.className}>
        <ModalHeader toggle={toggle}>Send a Message</ModalHeader>
        <ModalBody>
          <MessageForms
            currentUserId={props.currentUserId}
            audienceId={props.audienceId}
            conversationId="new"
            historyRoute={props.historyRoute}
            historyState={props.historyState}
            setModal={setModal}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};
export default ModalMessaging;
// class ModalExample extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       modal: false,
//     };

//     this.toggle = this.toggle.bind(this);
//   }

//   toggle() {
//     this.setState(prevState => ({
//       modal: !prevState.modal,
//     }));
//   }

//   render() {
//     return (
//       <div>
//         <Button
//           color="info"
//           onClick={this.toggle}
//           className={this.props.className}
//         >
//           Send a Message
//         </Button>
//         <Modal
//           isOpen={this.state.modal}
//           toggle={this.toggle}
//           className={this.props.className}
//         >
//           <ModalHeader toggle={this.toggle}>Send a Message</ModalHeader>
//           <ModalBody>
//             <MessageForms
//               currentUserId={this.props.currentUserId}
//               audienceId={this.props.audienceId}
//               conversationId="new"
//               historyRoute={this.props.historyRoute}
//               historyState={this.props.historyState}
//             />
//           </ModalBody>
//         </Modal>
//       </div>
//     );
//   }
// }

// export default ModalExample;
