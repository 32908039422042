import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postForgot } from '../../store/actions/resetPassword';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

import NavMain from 'views/components/Navbar.jsx';
import Footer3 from '../components/Footer3';
// Core Components

function LoginPage(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(postForgot(email));
    setEmail('');
    navigate(`/forgot`, {
      state: `If an account with that Email exist you will receive the reset link`,
    });
  };
  React.useEffect(() => {
    document.body.classList.add('login-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('login-page');
    };
  });
  return (
    <>
      <NavMain type="dark" />
      <div className="section-shaped my-0 skew-separator skew-mini">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                'url(' + require('views/mainPage/Images/1.png') + ')',
            }}
          ></div>
          <Container>
            <div className="header-body text-center">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="8">
                  <h2 className="forgetPageText1">Enter your Email please </h2>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup row>
                      <Col sm={10}>
                        <Input
                          autoComplete="off"
                          id="email"
                          name="email"
                          onChange={e => setEmail(e.target.value)}
                          type="email"
                          value={email}
                          placeholder="Email"
                          required
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup check row>
                      <Button color="info" size="sm">
                        Submit
                      </Button>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
