import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

const FeedDropDown = ({ id, text, onSubmit, onDelete }) => {
  const dispatch = useDispatch();
  const [newText, setNewText] = useState(text);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const groupPage = useMatch('/group/:id');
  const eventPage = useMatch('/event/:id');
  console.log(groupPage, eventPage);
  let module = 'feed';
  if (groupPage) {
    module = 'group';
  } else if (eventPage) {
    module = 'event';
  }

  useEffect(() => {
    setNewText(text);
  }, [text]);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(onSubmit(id, newText, module));
    setNewText('');
  };

  const handleToggleDropDown = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const handleToggleModal = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <button className="close" onClick={handleToggleModal}>
      &times;
    </button>
  );

  return (
    <div>
      <ButtonDropdown isOpen={dropDownOpen} toggle={handleToggleDropDown}>
        <DropdownToggle caret size="sm" color="none" />
        <DropdownMenu>
          <DropdownItem size="sm" onClick={e => dispatch(onDelete)}>
            Delete
          </DropdownItem>
          <DropdownItem size="sm" onClick={handleToggleModal}>
            Edit
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      <div>
        <Modal isOpen={modal} toggle={handleToggleDropDown}>
          <ModalHeader toggle={handleToggleModal} close={closeBtn}>
            Edit your Feed
          </ModalHeader>
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <textarea
                className="feedInput"
                onChange={e => setNewText(e.target.value)}
                value={newText}
                required
              />
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                color="secondary"
                onClick={handleToggleModal}
              >
                Post
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default FeedDropDown;
