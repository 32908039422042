import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import EventForms from './EventForms';

const EventFormsModal = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <div>
      <Button block color="success" onClick={toggle}>
        Held an event
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={props.className}>
        <ModalBody>
          <EventForms />
        </ModalBody>
      </Modal>
    </div>
  );
};
export default EventFormsModal;
