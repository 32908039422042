import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Alert } from 'reactstrap';
import LeftSide from '../components/LeftSide';
import RentGrid from './rent-grid';
import BorrowGrid from './borrow-grid';
import MyItemsGrid from './my-items-grid';
import BorrowForms from './form';
import { removeSuccess } from '../../store/actions/success';
import Banner from './banner';

const PageRentAndBorrow = () => {
  const dispatch = useDispatch();
  const errors = useSelector(state => state.errors);
  const success = useSelector(state => state.success);
  const [toggleTab, setToggleTab] = useState(0);

  const handleToggleTab = item => {
    setToggleTab(item);
  };
  useEffect(() => {
    let removeS = setTimeout(() => dispatch(removeSuccess()), 2000);
    return () => {
      clearTimeout(removeS);
    };
  }, [success]);
  return (
    <div className="d-flex">
      <LeftSide nav="borrows" />
      <Col className="p-0">
        <div
          style={{
            width: '100%',
            background: '#fff',
            boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.2)',
          }}
          className="mb-4"
        >
          <Banner />
          <div className="d-flex mt-4">
            <Col className="mr-auto">
              <h4>Rent and Borrow</h4>
              <p style={{ fontSize: '14px' }}>
                Have you ever thought about being able to rent, borrow or lend
                inside your building community? we made it possible in the
                easiest way.
              </p>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <BorrowForms />
            </Col>
          </div>
          <nav className="nav-borrow">
            <button
              className={toggleTab === 0 && 'active'}
              onClick={() => handleToggleTab(0)}
            >
              For Rent
            </button>
            <button
              className={toggleTab === 1 && 'active'}
              onClick={() => handleToggleTab(1)}
            >
              For Borrow
            </button>
            <button
              className={toggleTab === 2 && 'active'}
              onClick={() => handleToggleTab(2)}
            >
              My Items
            </button>
          </nav>
        </div>

        <div className="d-flex">
          <Col xs={12}>
            {errors.message && (
              <Alert severity="error">{errors?.message}</Alert>
            )}
            {success.message && (
              <Alert severity="error">{success?.message}</Alert>
            )}
          </Col>
        </div>

        <div className="d-flex flex-wrap">
          {toggleTab === 0 && <RentGrid />}
          {toggleTab === 1 && <BorrowGrid />}
          {toggleTab === 2 && <MyItemsGrid />}
        </div>
      </Col>
    </div>
  );
};

export default PageRentAndBorrow;
