import React from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { Col, Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap';
import pi from '../../../images/venice.jpeg';

const Event = ({ event }) => {
  const navigate = useNavigate();

  return (
    <Col xl="4" sm="6" xs="8">
      <Card
        className="rounded-border"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/event/${event._id}`)}
      >
        <CardImg
          className="buildingEventImage"
          src={event.image ? event.image : pi}
        />
        <CardBody>
          <CardTitle className="buildingEventsText ">
            <Moment className="text-muted" format="llll">
              {event.date}
            </Moment>
          </CardTitle>
          <CardText>{event.title}</CardText>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Event;
