import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import NavMain from 'views/components/Navbar.jsx';
import Footer3 from '../components/Footer3';
// Core Components
// import DemoFooter from 'components/footers/DemoFooter.js';
import LoginCard1 from './LoginCard1.js';

function LoginPage(props) {
  React.useEffect(() => {
    document.body.classList.add('login-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('login-page');
    };
  });
  return (
    <>
      <NavMain type="dark" />
      <div className="section-shaped my-0 skew-separator skew-mini">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                'url(' + require('views/signupPage/Images/l18.jpg') + ')',
            }}
          ></div>
          <Container>
            <div className="header-body text-center">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                  <h1 className="text-white">Log in</h1>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <section className="upper">
        <Container>
          <Col className="mx-auto" lg="5" md="8">
            <LoginCard1
              history={props.history}
              errors={props.errors}
              onAuth={props.onAuth}
            />
          </Col>
        </Container>
      </section>
      <Footer3 />
    </>
  );
}

export default LoginPage;
