import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import MessageForms from '../message/Compose';

class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    return (
      <div>
        <Button
          color="info"
          onClick={this.toggle}
          className={this.props.className}
        >
          Send a Message
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Send a Message</ModalHeader>
          <ModalBody>
            <MessageForms
              currentUserId={this.props.currentUserId}
              audienceId={this.props.audienceId}
              history={this.props.history}
              conversationId="new"
              historyRoute={this.props.historyRoute}
              historyState={this.props.historyState}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ModalExample;
