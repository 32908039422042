import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { HeaderGroup } from '../../../styles/groups';
import { removeEvent } from '../../../store/actions/events';
import pi from '../../../images/venice.jpeg';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user } = useSelector(state => state.currentUser);
  const page = useSelector(state => state.events.opened);

  const handleLeave = async e => {
    e.preventDefault();
    // await dispatch(leaveGroup(id, user.id));
    // history.push({
    //   pathname: `/groups`,
    //   state: `You successfuly quited the group`,
    // });
  };

  const handleDelete = async e => {
    e.preventDefault();
    await dispatch(removeEvent(id));
    navigate(`/events`);
  };
  return (
    <HeaderGroup>
      <img
        src={page.image ? page.image : pi}
        alt={page.title}
        className="group-cover"
      />
      <div>
        <h4>
          {page.title} <br />
          {page.author._id !== user.id && (
            <button
              onClick={handleLeave}
              className="btn btn-sm p-0 text-danger"
            >
              Leave this event
            </button>
          )}
          {page.author?._id === user.id && (
            <button
              onClick={handleDelete}
              className="btn btn-sm ml-2 p-0 text-danger"
            >
              Delete this event
            </button>
          )}
        </h4>
      </div>
      <p>{page.description}</p>
    </HeaderGroup>
  );
};

export default Header;
