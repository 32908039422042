import React, { useState, useEffect } from 'react';
import MessageModal from './MessageModal';
import bgCoverImage from '../../images/header2.jpg';
import EditModal from './EditProfileModal';
import pi from '../../images/default-profile-image.jpg';
import { Cover } from '../../styles/user';
import ProfileImageModal from './ProfileImageModal';
const MyProfileHeader = ({ user, currentUser, errors }) => {
  const fullName = `${user.firstName} ${user.lastName}`;
  const bgCover = user.address?.image ? user.address?.image[0] : bgCoverImage;
  const [profileImage, setProfileImage] = useState(() => {
    return user.image ? user.image : pi;
  });

  useEffect(() => {
    if (user.id) {
      setProfileImage(user.image);
    }
  }, [user]);

  return (
    <Cover>
      <figure>
        <img src={bgCover} alt="cover" />
      </figure>
      {errors.message === null ? (
        <>
          <div className={'profile-image'}>
            <img src={profileImage} alt={user.email} />

            <ProfileImageModal
              image={profileImage}
              email={user.email}
              currentUser={currentUser.user.id}
              userId={user.id}
            />
          </div>

          <section>
            <h3>{fullName}</h3>
            {currentUser?.user.id !== user.id ? (
              <MessageModal
                audienceId={user.id}
                currentUserId={currentUser.user.id}
                historyRoute={`profile/${user.id}`}
                historyState={user.id}
              />
            ) : (
              <div>
                <EditModal user={user} buttonLabel="Edit Profile" />
              </div>
            )}
          </section>
        </>
      ) : (
        <div className="alert alert-danger">{errors.message}</div>
      )}
    </Cover>
  );
};

export default MyProfileHeader;
