import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';

import SignUpForm from './form';
import SignUpFindCode from './find-code';

import './SignUpStepper.css'; // Import the CSS file for styling

const steps = ['Find the Building', 'Fill your personal data'];

const SignUpStepper = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className="stepper-root"
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            className={`step ${activeStep === index ? 'active' : ''}`}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={activeStep === 0 ? 'step-content' : 'd-none'}>
        <SignUpFindCode handleStepper={setActiveStep} />
      </div>
      <div className={activeStep !== 0 ? 'step-content' : 'd-none'}>
        <SignUpForm handleStepper={setActiveStep} />
      </div>
    </>
  );
};

export default SignUpStepper;
