import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createGroup } from '../../../store/actions/groups';
import { Button, Form, Input } from 'reactstrap';
import { AiOutlineLoading } from 'react-icons/ai';

const GroupForm = ({handleOpenForm}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.currentUser.user);
  const buildingId = user.address;
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [sending, setSending] = useState(false);
  const [attrDisabled, setAttrDisabled] = useState({});

  const handleSubmit = async e => {
    e.preventDefault();
    setSending(true);
    setAttrDisabled({ 'disabled': true });
    await dispatch(createGroup({buildingId, image, title, description}));
    setSending(false);
    setAttrDisabled({});
    handleOpenForm();
  }

  return (
    <Form onSubmit={handleSubmit}>
      <h5 className="mb-4">Create a new Group</h5>
      <div className="form-group">
        <label>Name</label>
        <Input
          autoComplete="off"
          id="title"
          name="title"
          onChange={e => setTitle(e.target.value)}
          type="text"
          value={title}
          required
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        <Input
          autoComplete="off"
          name="description"
          onChange={e => setDescription(e.target.value)}
          type="textarea"
          value={description}
          required
        />
      </div>
      <div className="form-group">
        <label className="d-block">Cover Image</label>
        <input
          type="file"
          onChange={e => setImage(e.target.files[0])}
        />
      </div>
      <div className="text-right">
        <Button type="button" className="mr-2" color="danger" onClick={handleOpenForm}>
          Cancel
        </Button>
        <Button color="primary" {...attrDisabled}>
          {sending ? <AiOutlineLoading className="spinner" /> : 'Create'}
        </Button>
      </div>
    </Form>
  );
}

export default GroupForm;
