import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import LeftSide from '../components/LeftSide';
import Navbar from '../Navbar';
import Messages from './Messages';
import Chat from './Chat';
import { Inbox } from './styles';

function TheInbox() {
  const [openMobileChat, setOpenMobileChat] = useState(false);
  const inbox = useSelector(state => state.inbox);
  const handleOpenMobileChat = flag => {
    if (isMobile) {
      setOpenMobileChat(flag);
    }
  };

  return (
    <>
      <div className="navbarDiv">
        <Navbar />
      </div>
      <div className="content-system">
        <div className="d-flex">
          <LeftSide nav="inbox" />
          <div className="col-md-9 mt-3">
            <Inbox className="content-system">
              <div className="col-md-12 mt-3">
                <div
                  className="d-flex col-12 col-md-3 pr-md-0"
                  style={{ marginBottom: 56 }}
                >
                  <Messages openMobileChat={handleOpenMobileChat} />
                </div>
                <div className="col-xs-12 col-md-9 pl-md-0">
                  {isMobile && (
                    <>
                      {openMobileChat && (
                        <Chat openMobileChat={handleOpenMobileChat} />
                      )}
                    </>
                  )}
                  {!isMobile && <Chat openMobileChat={handleOpenMobileChat} />}
                </div>
              </div>
            </Inbox>
          </div>
        </div>
      </div>
    </>
  );
}

export default TheInbox;
