import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBorrows } from '../../store/actions/borrows';
import BorrowCard from './card';

const BorrowGrid = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const borrowsList = useSelector(state => state.borrows);

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchBorrows(currentUser.user.address));
    }
  }, []);

  return (
    <>
      {borrowsList
        .filter(item => !item.type || item.type === 'borrow')
        .map(borrow => (
          <BorrowCard key={borrow._id} borrow={borrow} />
        ))}
    </>
  );
};

export default BorrowGrid;
