import { apiCall } from "../../services/api";
import { addError } from "./errors";
import {
  LOAD_EVENTS,
  POST_EVENT,
  REMOVE_EVENT,
  POSTPARTICIPANT_EVENT,
  LOAD_EVENT_PAGE,
  LIKE_EVENT_FEED,
  POST_EVENT_FEED,
  LOAD_EVENT_FEED,
  POST_EVENT_COMMENT,
} from "../actionTypes";

export const fetchEvents = buildingId => async dispatch => {
  try {
    const response = await apiCall("get", `/api/building/events/${buildingId}`);
    return dispatch({
      type: LOAD_EVENTS,
      events: response,
    });
  } catch (err) {
    return addError(err?.message);
  }
};

export const loadEvent = eventId => async (dispatch, getState) => {
  const response = await apiCall("get", `/api/building/${eventId}/eventinfo`);
  return dispatch({
    type: LOAD_EVENT_PAGE,
    event: response,
  });
};

export const likeEventFeed = (feedId, userId) => {
  return async dispatch => {
    try {
      const response = await apiCall(
        "post",
        `/api/building/likeeventfeed/${feedId}/${userId}`,
      );

      return dispatch({
        type: LIKE_EVENT_FEED,
        feedId,
        likes: response,
      });
    } catch (err) {
      addError(err?.message);
    }
  };
};

export const postEventComment = (feedId, text) => async (
  dispatch,
  getState,
) => {
  const { currentUser } = getState();
  try {
    const response = await apiCall(
      "post",
      `/api/building/${currentUser.user.id}/event/${feedId}/comment`,
      { text },
    );
    return dispatch({
      type: POST_EVENT_COMMENT,
      feedId,
      feed: response,
    });
  } catch (err) {
    return addError(err?.message);
  }
};

export const postEventFeed = (eventId, formData) => async (
  dispatch,
  getState,
) => {
  const { currentUser } = getState();
  const fd = new FormData();
  fd.append("text", formData.text);
  if (formData.image) {
    fd.append("image", formData.image);
  }

  try {
    const response = await apiCall(
      "post",
      `/api/building/${currentUser.user.id}/event/${eventId}/feed`,
      fd,
    );
    return dispatch({
      type: POST_EVENT_FEED,
      feed: response,
    });
  } catch (err) {
    return addError(err?.message);
  }
};

export const loadEventFeed = eventId => async dispatch => {
  try {
    const response = await apiCall("get", `/api/building/${eventId}/event`);
    return dispatch({
      type: LOAD_EVENT_FEED,
      feeds: response,
    });
  } catch (err) {
    return addError(err?.message);
  }
};

//  -----------------------------------

export const eventUpdate = newEvent => ({
  type: POST_EVENT,
  newEvent,
});

//Action to post a feed
export const postEvent = (buildingId, eventData) => async (
  dispatch,
  getState,
) => {
  let { currentUser } = getState();
  const id = currentUser.user.id;

  try {
    const res = await apiCall(
      "post",
      `/api/building/events/${buildingId}/${id}`,
      { ...eventData },
    );
    dispatch(eventUpdate(res));
  } catch (err) {
    return addError(err?.message);
  }
};

// Action to remove a event
export const removeEvent = eventId => {
  return async dispatch => {
    try {
      await apiCall("delete", `/api/building/events/${eventId}`);
      return dispatch({
        type: REMOVE_EVENT,
        id: eventId,
      });
    } catch (err) {
      addError(err?.message);
    }
  };
};

// eventgoing button functionality
export const goingE = id => ({
  type: POSTPARTICIPANT_EVENT,
  id,
});
export const goingEvent = (eventId, eventData) => async (
  dispatch,
  getState,
) => {
  let { currentUser } = getState();
  const id = currentUser.user.id;
  try {
    const res = await apiCall(
      "post",
      `/api/building/events/${eventId}/${id}/going`,
      { ...eventData },
    );
    dispatch(goingE(res));
  } catch (err) {
    return addError(err?.message);
  }
};
