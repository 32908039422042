import { apiCall } from '../../services/api';
import { addError } from './errors';
import { LOAD_MYNEIGHBORS } from '../actionTypes';

// to load my neighbors==============
export const loadMyNeighbors = (myBuilding) => ({
  type: LOAD_MYNEIGHBORS,
  myBuilding,
});
export const fetchMyNeighbors = (buildingId) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall(
          'get',
          `api/building/neighbors/${buildingId}`
        );
        dispatch(loadMyNeighbors(res));
        resolve(); // indicate that the API call succeeded
      } catch (err) {
        dispatch(addError(err?.message));
        reject(); // indicate the API call failed
      }
    });
  };
};
