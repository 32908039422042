import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import NavMain from 'views/components/Navbar.jsx';
import Footer3 from '../components/Footer3';
// Core Components

function LoginPage(props) {
  React.useEffect(() => {
    document.body.classList.add('login-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('login-page');
    };
  });
  return (
    <>
      <NavMain type="dark" />
      <div className="section-shaped my-0 skew-separator skew-mini">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                'url(' + require('views/mainPage/Images/1.png') + ')',
            }}
          ></div>
          <Container>
            <div className="header-body text-center">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                  <h1 className="text-white">Terms</h1>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <Container>
        <Row>
          <Col md="8">
            {/* <h6 className="aboutContentsTitle">Terms and Conditions</h6> */}
            <div className="container aboutContent">
              <p className="mt-5 mb-5 ml-5 mr-5">
                Thank you for using Weciny. We welcome you to a secure platform
                and expect you to comply with the terms to have the great
                experience for you and your neighbours. Please read these terms
                and conditions of use carefully before accessing, using any
                information, products and services. By accessing Weciny website
                or mobile app, or any other feature e.g Groups, Sell and Buy,
                Lend and borrow or events or other Weciny platform (Collectively
                “Website”) you agree to be bound by these terms and conditions
                (“Terms”) and our privacy policy. In these terms, “we'', “us” ,
                “our” or “Weciny” refers to Weciny inc. and “you”, “your”, or
                “neighbors” refers to you as a user of our website. Your access
                to and use of the service is conditioned on your acceptance of
                and compliance with these terms. These terms apply to all
                visitors, users and others who access or use the service.
              </p>
              <h6 className="ml-3 mt-5 mb-3">Registration:</h6>
              <div className="ml-5 mr-5">
                <p>
                  To access our services, you are required to create a profile.
                  Weciny’s registration requires a building security code that
                  will be given to you by usl. You MUST not share your building
                  security code with anyone who is not living in your building.
                  You can also request your building security code on our
                  website. During registration and creating a profile, you must
                  agree to our terms and conditions. It is your responsibility
                  to comply with these terms while sharing any information with
                  Weciny. You must be 13 years old, use your real name and agree
                  not to share your password with anyone. Upon becoming aware of
                  any breach of security or unauthorized access you must notify
                  us. Weciny reserves the right to refuse registration to any
                  person or apartment and to suspend, delete and deactivate your
                  account at any time without liability to you.
                </p>
              </div>
              <h6 className="ml-3 mt-5 mb-3">Content and its Consent:</h6>
              <div className="ml-5">
                <p>
                  As a User, you retain all ownership rights to the information
                  you share with us. For example, texts, photos, videos,
                  responses, feedback, messages and comments(collectively, your
                  “content”). You give us consent to use your content as defined
                  in the <a href="/privacy">privacy policy</a>. You will be
                  liable for what you share with us.
                </p>
              </div>
              <h6 className="ml-3 mt-5 mb-3">Deactivating your account:</h6>
              <div className="ml-5">
                <p>
                  Upon deactivating your account, your profile will be
                  deactivated immediately. Your posted data will remain shared
                  with Weciny and third parties, however it will show
                  “Deactivated” on your profile name and your profile will be
                  out of access.
                </p>
              </div>
              <h6 className="ml-3 mt-5 mb-3">
                Violations of someone else’s rights:{' '}
              </h6>
              <div className="ml-5">
                <p>
                  As a member of Weciny you agree not to violate or breach
                  someone else’s rights by posting any material including links
                  to other websites or images which are considered as
                  inappropriate, inaccurate, abusive, false, vulgar, hateful,
                  harassing, ranting, profane, obscene, sexuially oriented,
                  threatening, pronographic, invasive of a person’s privacy,
                  adult material or otherwise in violation of any applicable
                  laws. Hate speech or discrimination on the basis of religion,
                  race, nationality, gender, sexual preference, age, disability
                  of any kind will not be tolerated and user or groups or any
                  involving parties will be removed from Weciny website. Posting
                  copyrighted material is against the law. You agree not to post
                  any copyrighted material unless you own it.
                </p>
              </div>
              <h6 className="ml-3 mt-5 mb-3">
                Liability and its limitations:{' '}
              </h6>
              <div className="ml-5">
                <p>
                  You agree to hold harmless Weciny and its subsidiaries and its
                  employees from or against any claims, losses or liabilities
                  resulting from a violation by you of these terms. This site
                  may contain links to third party websites that are not owned
                  or controlled by Weciny and these entities have no control
                  over and assume no responsibility for, the content, privacy
                  policies, or parties of any third party websites. By using the
                  site, you expressly relieve Weciny from any and all
                  liabilities arising from your use of any third party website.
                  Weciny is not responsible and disclaims any and all liability
                  for content posted by outside contributors to the website.
                </p>
              </div>
              <h6 className="ml-3 mt-5 mb-3">Disclaimer and Changes: </h6>
              <div className="ml-5">
                <p>
                  This site is available without any kind of guarantees and is
                  provided on “AS IS” basis. Weciny and its subsidiaries do not
                  guarantee that: The site will function uninterrupted, secure
                  or available at any specific time and location.
                </p>
                <p>
                  By agreeing to these Terms, you are waving the right to a
                  trial by jury or to participate in a class action. You agree
                  that you may be eligible to bring claims only in your
                  individual capacity, and not as a plaintiff or class member in
                  any purported class or representative proceeding.
                </p>
                <p>
                  Weciny reserves the right to change or modify these terms at
                  any time without prior notification. If these terms are
                  changed or modified, we will post the new Terms (effective
                  immediately) with a change of revision number accordingly.
                  Your access or use of this website following the updated or
                  modified or changed Terms posting constitutes your acceptance
                  of the revised terms.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer3 />
    </>
  );
}

export default LoginPage;
