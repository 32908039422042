import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import image1 from "./img/BorrowHeader1.jpg"
import image2 from "./img/BorrowHeader2.jpg"
import image3 from "./img/BorrowHeader3.jpg"

const items = [
  {
    src: image1,
    altText: '',
    caption: '',
  },
  {
    src: image2,
    altText: '',
    caption: '',
  },
  {
    src: image3,
    altText: '',
    caption: '',
  },
];

const Banner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div
          style={{
            width: '100%',
            height: '365px',
            background: '#333',
            textAlign: 'center',
          }}
        >
          <img
            src={item.src}
            alt={item.altText}
            style={{ maxWidth: '100%', height: '365px', objectFit: 'contain' }}
          />
        </div>
      </CarouselItem>
    );
  });

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default Banner;
