import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { joinGroup } from '../../../store/actions/groups';
import { Button } from 'reactstrap';
import { AiOutlineLoading } from 'react-icons/ai';

const Group = ({ id, image, title, members, mygroup, searchGroup }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.currentUser.user);
  const [sending, setSending] = useState(false);
  const [attrDisabled, setAttrDisabled] = useState({});
  const handleJoinGroup = async e => {
    e.preventDefault();
    setSending(true);
    setAttrDisabled({ disabled: true });
    await dispatch(joinGroup(id, user.id));
    setSending(false);
    setAttrDisabled({});
  };
  return (
    <div className={mygroup && searchGroup === '' ? 'my-group-item' : ''}>
      <figure>
        <img src={image} alt={title} />
      </figure>
      <h4>{title}</h4>
      {/* <p>
        {members.length} member{members.length > 1 && 's'} - last active 2 days
        ago
      </p> */}
      {members.find(m => m._id === user.id) ? (
        <Link to={{ pathname: `/group/${id}` }}>
          <Button size="sm" color="primary" className="w-100">
            Enter
          </Button>
        </Link>
      ) : (
        <Button
          size="sm"
          color="success"
          onClick={handleJoinGroup}
          {...attrDisabled}
        >
          {sending ? <AiOutlineLoading className="spinner" /> : 'Join'}
        </Button>
      )}
    </div>
  );
};

export default Group;
