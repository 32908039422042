import React from 'react';
// reactstrap components
import { Button, NavLink, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
// import { AiFillHeart } from 'react-icons/ai';
import { FaTwitterSquare } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';

// Core Components
function Footer() {
  return (
    <>
      <footer className="footer footer-simple bg-gradient-dark">
        <Container>
          <Row>
            <Col md="4">
              <Container>
                <div className="copyright">
                  {new Date().getFullYear()}, &copy; Weciny Inc.
                </div>
              </Container>
            </Col>
            <Col md="6">
              <div className="column">
                <ul className="inline-menu">
                  <li>
                    <NavLink href="/about">About Us</NavLink>
                  </li>
                  <li>
                    <NavLink href="/terms">Terms</NavLink>
                  </li>
                  <li>
                    <NavLink href="/privacy">Privacy</NavLink>
                  </li>
                  <li>
                    <NavLink href="/contactus">Contact Us</NavLink>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="2" className="text-right">
              <ul className="social-buttons">
                <li>
                  <Link to="https://twitter.com" target="block">
                    <FaTwitterSquare />
                  </Link>{' '}
                </li>
                <li>
                  <Link to="https://twitter.com" target="block">
                    <FaLinkedin />
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
