import React from 'react';
import PropTypes from 'prop-types';
export default function CardBody(props) {
  const { className, children, ...rest } = props;

  return <div {...rest}>{children}</div>;
}

CardBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
