import {
  LOAD_GROUPS,
  CREATE_GROUP,
  REMOVE_GROUP,
  POST_GROUPFEED,
  JOIN_GROUP,
  LOAD_MYGROUPS,
  POST_GROUPCOMMENT,
  LIKE_GROUPFEED,
  LOAD_GROUP_PAGE,
  LEAVE_GROUP,
  EDIT_GROUP_FEED,
  REMOVE_GROUP_FEED,
  REMOVE_GROUP_COMMENT,
  EDIT_GROUP_COMMENT,
} from '../actionTypes';

import { mountPostFeed, mountLikeFeed, mountRemoveCommentFeed } from './feeds';

import pi from '../../images/default-profile-image.jpg';

const initialState = {
  all: [{ image: pi, admin: '', members: [] }],
  my: [],
  opened: {
    page: {},
    feed: [],
  },
};

export const groups = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GROUPS:
      return {
        ...state,
        all: [...action.groups],
      };

    case CREATE_GROUP:
      return {
        ...state,
        all: [...state.all.concat(action.newGroup)],
        my: [...state.my.concat(action.newGroup)],
      };

    case JOIN_GROUP:
      return {
        ...state,
        all: state.all.map(group => {
          if (group._id === action.group._id) {
            return {
              ...group,
              members: [...action.group.members],
            };
          }
          return { ...group };
        }),
        my: [...state.my, action.group],
      };

    case REMOVE_GROUP:
      return {
        ...state,
        all: [...state.all.filter(group => group._id !== action.groupId)],
        my: [...state.my.filter(group => group._id !== action.groupId)],
      };

    case LOAD_MYGROUPS:
      return {
        ...state,
        my: [...action.groups],
      };

    case LOAD_GROUP_PAGE:
      return {
        ...state,
        opened: {
          page: action.group.page,
          feed: action.group.feed,
        },
      };

    case LEAVE_GROUP:
      return {
        ...state,
        my: [...state.my.filter(group => group._id !== action.group._id)],
        opened: {
          page: {},
          feed: [],
        },
      };

    case POST_GROUPFEED:
      return {
        ...state,
        opened: {
          ...state.opened,
          feed: [action.feed, ...state.opened.feed],
        },
      };

    case LIKE_GROUPFEED:
      return {
        ...state,
        opened: {
          ...state.opened,
          feed: mountLikeFeed(state.opened.feed, action),
        },
      };

    case POST_GROUPCOMMENT:
    case EDIT_GROUP_FEED:
    case EDIT_GROUP_COMMENT:
      return {
        ...state,
        opened: {
          ...state.opened,
          feed: mountPostFeed(state.opened.feed, action),
        },
      };

    case REMOVE_GROUP_FEED:
      return {
        ...state,
        opened: {
          ...state.opened,
          feed: state.opened.feed.filter(feed => feed._id !== action.id),
        },
      };

    case REMOVE_GROUP_COMMENT:
      return {
        ...state,
        opened: {
          ...state.opened,
          feed: mountRemoveCommentFeed(state.opened.feed, action),
        },
      };

    default:
      return state;
  }
};
