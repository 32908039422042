import { createGlobalStyle } from 'styled-components';
import leftArrow from '../images/left-arrow.png';
import rightArrow from '../images/right-arrow.png';
import closeButton from '../images/close.png';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html{
    overflow-y: scroll
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input , button {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }

  button {
    cursor: pointer;
  }

  textarea {
    resize: none;
  }

  .content-system {
    padding-top: 60px;
    min-height: 100vh;

  }

  .col-3.aside-max-width {
    max-width: 260px;
  }

  .nav-borrow {
    padding: 0 15px;
    display: flex;
    button {
      padding: 0 15px 8px;
      margin: 0 5px;
      color: #666;
      border: 0;
      border-bottom: 2px solid transparent;
      text-decoration: none;
      background: none;
      &.active,
      &:hover {
        border-color: #333;
        text-decoration: none;
      }

      &.active {
        color: #333;
        font-weight: 600;
      }
    }
  }

  .img-round {
    border-radius: 50%;
    object-fit: contain;
  }

  .spinner {
    animation: spinner 1s infinite linear;
  }

  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  /* Nav========================================================================== */
  .navbar-opened {
    height: 100%;
    overflow: scroll;
    align-items: initial!important;
  }

  .navbarBergerLines {
    color: black;
  }
  .nav-mobile {
    padding-bottom: 30px;
    div {
      > nav {
        padding-bottom: 0;
      }
    }
  }
  .reactRouterLinks {
    text-decoration: none;
  }
  .keyHolderList {
    list-style: none;
  }
  .navBarMain {
    color: #de6b1f;
  }
  .navbarLogo {
    height: 36px;
    width: 36px;
    border-radius: 5px;
    margin-right: 5px;
  }
  /* ================================================================================ */
.icon-nav-left {
   width: 40px;
    height: 40px;
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;
    background: no-repeat center center;
    background-size: contain;
    background-image: url(${leftArrow});
  }
  .icon-nav-right{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;
    background: no-repeat center center;
    background-size: contain;
    background-image: url(${rightArrow});
  }
.icon-nav-close{
    width: 55px;
    height: 55px;
    opacity: 0.8;
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;
    background: no-repeat center center;
    background-size: contain;
    background-image: url(${closeButton});
}
.icon-nav-close:hover{
  opacity: 1;
}
  .buildingItemImage {
    object-fit: cover;
    margin: 0;
  }

  .eventsHeader {
    position: relative;
    left: 14px;
  }

  .buildingEventsContainer {
    width: 100%
  }
  .buildingEvent {
    width: 17.5em;
    height: 16rem;
    left: 0.5rem;
    position: relative;
    margin-bottom: 1rem;
    border: 1px;

  }

  .buildingEventsRow{
    position: relative;
    left: 0.4rem;
    margin-bottom: 1rem;
  }

  .buildingEventsText{
    font-size: medium;
    font-weight: bold;
  }
  .buildingEventImage{
    height: 8rem;
  }

  .userEventsImage {
    width: 12rem;
    filter: grayscale(100%);
    object-fit: cover;
  }

  .userEventsText {
    font-size: small;

    right: 15px;
  }

  .userEventsCard {
    position: relative;
    left: 1rem;
    width: 15rem;
    cursor: pointer;
  }

  .userEventsButton {
    position: relative;
    top: 1rem;
  }

  .buildingEventsRow {

  }

  .items-image{
     box-shadow: 0px 0px 4px #a9a9a9;
  }
  #messages .list-group-item {
    display: flex;
    align-items: flex-start;
    padding: 7px 20px 12px;
  }
  #messages:not(.no-hover) .list-group-item:hover {
    background-color: #e6ecf0;
  }
  /* FEEDS UI========================================================== */

  .post-preview {
    margin-left: 5px;
    display: inline-block;
    position: relative;

    img {
      width: 41px;
      height: 41px;
      border-radius: 50%;
      object-fit: cover;
      transition: .2s;
      cursor: pointer;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      margin-top: -8px;
      margin-left: -7px;
      color: #fff;
      opacity: 0;
      transition: .2s;
      cursor: pointer;
    }

    &:hover {
      img {
        filter: blur(2px);
      }
      svg {
        opacity: 1;
      }
    }

  }

  .feedRightSide {
    padding: 15px 0;
    width: 20%;
    height: 100%;
    border: hidden;
    border-radius: 5px;
    position: fixed;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .feedRightSide {
      display: none;
    }
  }
  .feesRightSide {
    background-color: #fff;
    box-shadow: -2px 2px 2px gray;
    width: 100%;
    height: 100%;
    border: hidden;
    border-radius: 5px;
  }
  .feedFormHeader {
    background-color: #e6ecf0;
    background-size: cover;
    background-position: center;
    height: 50px;
  }
  .FeedDropDown {
    float: right;
  }
  .createApost {
    padding-top: 13px;
  }
  .feedInput {
    border: hidden;
    padding: 10px 20px;
    width: 100%;
  }
  .feedFormInput {
    background-color: #fff;
  }
  .feedForm {
    box-shadow: 0 0 2px gray;
  }
  .feedFormButton {
    width: 100%;
    background-color: #e6ecf0;
    border: hidden;
    height: 35px;
  }
  .gridRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .gridRowConversationList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0px;
  }
  .feedPannel {
    background-color: gainsboro;
    border-radius: 8px;
  }

  .feedDate{
    font-size: .80em;
  }
  /* feed Box Css ================================================================*/
  .mainFeedBox {
    background-color: #fff;
    box-shadow: 0 0 2px gray;
    border-radius: 5px;
    padding: 0 0 5px 0;
  }
  .feedBox {
    margin: 10px 0 10px 0;
  }
  .feedBoxHead {
    padding: 10px 15px 0 15px;
  }
  .feedBoxText {
    box-shadow: 0 0 .5px rgb(248, 248, 248);
    padding: 15px 15px 0 15px;
    background-color:#fff;
    word-break: break-all;
    > a {
      box-shadow: none!important;
    }
  }
  .feedImage {
    width: 100%;
    height: auto;
    object-fit: contain;
    /* margin-bottom: 20px; */
    background-color: #c7c7ca;
  }
  .feedPhotoText{
    margin: 15px 10px 0px 35px;
    padding: 10px 0 15px 0;
    white-space: pre-line;
  }
  .feedLinkText{
    margin: 15px 10px 0px 35px;
    padding: 10px 0 0px 0;
    white-space: pre-line;
  }
  .feesText {
    margin: 15px 10px 10px 35px;
    white-space: pre-line;
  }
  .feedLinkButton{
    background-color: rgb(255, 255, 255);
    text-align: left;

    margin: 0px 10px 10px 25px;

  }
  .momentFeed{
    font-size: .75em;
    float: right;
    margin-right: 5px;
    margin-left: 45px;

  }
  /* CommentBox ==========================================================================*/
  .commentBox {
    margin: 5px 30px 5px 30px;
    background-color: rgb(250, 250, 250);
    border-radius: 10px;
  }
  .commentInput {
    width: 60%;
    border-radius: 3px;
    padding: 5px;
    border: hidden;
    box-shadow: 0 0 1px gray;
    background-color: rgb(235, 240, 245);
      margin-left: 15%;
    }
  .CommentBoxText {
    padding: 0 15px 0px 60px;
  }
  .CommentText {
    color:#4e4e4e;
    font-size: .85em;
    white-space: pre-line;
  }
  .commentButton {
    box-shadow: 0 0 1px gray;
    margin: 0 0 25px 5px;
  }
  /* user aside==================================================================================== */
  .showImage {
    object-fit: cover;
    height: 500px;
    width: 100%;
  }
  .userAsideImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

  .userAsideSections {
    margin-bottom: 3px;
  }
  .userAsideSection {
    display: flex;
    color: black;
    align-items: center;
  }
  .userAsideName {
    padding-top: 5px;
    padding-left: 10px;
  }
  /* usera side on notifications */
  .userAsideImageNotification {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  .userAsideUsernameNotifications {
    font-size: 12px;
    color: gray;
  }
  .conversation-title {
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0;
  }
  /* LEFTSIDE BAR ===========================================================================*/

  .myLinks {
    list-style: none;
    padding: 15px 0px 15px 50px;
  }
  .myLinks li {
    padding: 4px 0;
  }

  /* MESSAGE PAGE============================================================================= */
  .messageGrid {
    justify-content: center;
    text-align: center;
    border: 1px solid gray;
    width: 100%;
    height: 100vh;
    position: relative;
    margin: auto 0px;
    padding: auto 0px;
    box-shadow: 0px 0px 2px black;
  }
  .groupMessenger {
    display: grid;
    width: 100%;
    height: 100vh;
    background: #eeeef1;
    grid-template-rows: 60px auto 60px;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
  }

  .compose {
    padding: 10px;
    display: flex;
    align-items: center;
    background: white;
    border-top: 1px solid #eeeef1;
    position: static;
    width: 100%;
    bottom: 0px;
  }

  @supports (backdrop-filter: blur(20px)) {
    .compose {
      border: none;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(20px);
    }
  }
  .compose-form {
    width: 100%;
  }
  .compose-input {
    flex: 1;
    width: 100%;
    border: none;
    font-size: 14px;
    height: 40px;
    background: none;
  }
  .compose-input::placeholder {
    opacity: 0.3;
  }
  .compose-input {
    border: 1px solid #bbbbbf;
  }
  .compose .toolbar-button {
    color: #bbbbbf;
    margin-left: 15px;
  }

  .compose .toolbar-button:hover {
    color: #99999c;
  }

  /* PROFILE PAGE */
  .profilePageImage {
    width: 100px;
    height: 100px;
    border-radius: 15px;
  }
  /* GOOGLE MAP ===========================================================================*/
  #map {
    height: 400px;
    width: 100%;
  }
  /* ITEM PAGE============================================================================== */
  .itemBox {
    width: auto;
    height: 310px;
    padding: 0;
    margin: 10px 10px;
    box-shadow: 0px 0px 2px gray;
  }
  .itemsImage {
    width: 200px;
    height: 220px;
    position: inherit;
    overflow: auto;
  }
  .itemsShowImage {
    width: auto;
    height: 240px;
    position: inherit;
    overflow: auto;
  }
  .ItemShowBox {
    height: 620px;
    width: 860px;
    background-color: #fff;
    border-radius: 20px;
  }
  /* PROFILE PAGE IMAGE============================================= */
  .profileHeader{
    position: relative;
    width: 100%;
    height: 250px;
  }
  .profilePTags{
    margin-left: 20px;

    color:gray;
  }
  .myAccountButtons{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .profileImage{
   width: 150px;
    height: 150px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
    text-align: center;
    position: absolute;
    top: 170px;
     left: 5%;
    z-index: 4;
  }
  .profileName{
    top: 320px;
    position: absolute;
    left: 6%;
  }
  .editProfileButton{
    float:right;
    margin-right: 10%;
  }

  .profilePannelSection{
    top: 400px;
    position: absolute;

  }
  # map {
  height: 400px;
}
  .imageContainer {
    position: relative;
    /* width: 50%; */
  }
  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }
  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  .imageContainer:hover .image {
    opacity: 0.3;
  }
  .imageContainer:hover .middle {
    opacity: 1;
  }
  /* Signin page======================================================= */
  .signinBackground {
    z-index: -1;
    width: 100%;
    height: 70vh;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .signinBackground {
      display: none;
    }
  }
  .signinContentsRight {
    border-radius: 5px;
    margin: 100px 0 80px 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 20px;
  }
  .ContactusContentsRight{
    border-radius: 5px;
    margin: 150px 0 80px 0;
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.4); */
    padding: 20px;
  }
  .requestContentsRight{
    border-radius: 5px;
    margin: 160px 0 80px 0;
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.4); */
    padding: 20px;
  }
  .contactusLeftSid{
  margin-top: 150px;
  margin-left: 60px;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .contactusLeftSid {
      margin-top: 0;
    }
  }
  .requestLeftSid{
    margin-top: 200px;
  margin-left: 20px;
  color: #535353;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .requestLeftSid {
      margin-top: 0;
    }
  }
  .signinContentsLeft {
    color: gray;
    margin-top: 8%;
    width: 80%;
    text-shadow: 1px 1px 1px rgb(165, 165, 165);
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .signinContentsLeft {
      margin: 30px 0 20px 0;
      width: 100%;
      text-shadow: 1px 1px 5px rgb(187, 187, 187);
    }
  }
  .buildingInfoContents {
    color: gray;
    margin-top: 25%;
    width: 80%;
    text-shadow: 1px 1px 1px rgb(165, 165, 165);
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .buildingInfoContents {
      margin: 30px 0 20px 0;
      width: 100%;
      text-shadow: 1px 1px 5px rgb(187, 187, 187);
    }
  }
  .addressLeftSignup{
    margin-top: 40px;
  }
  .signinContentHeader {
    /* color: white;
    text-shadow: 1px 1px 3px #a8a8a8; */
    margin-bottom: 30px;
  }

  .signinButton {
    width: 80%;
  }
  .signinHr{
    width: 50%;
  }
  .signinInputs{
    margin-bottom: 40px;
  }
  /* Signup================================================== */
  .validationError{
    color:red;
  }
  .signupBackground {
    z-index: -1;
    object-fit: cover;
    width: 100%;
    height:350px;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .signupBackground {
      display: none;
    }
  }
  .starrequired{
    color: rgb(180, 52, 52);
    padding-left: 4px;
  }
  .signupContentsRight {
    margin: 100px 0 0px 0;
    width: 100%;
    padding: 20px;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .signupContentsRight {
      margin-top: 0;
    }
  }
  .signupContentsLeft {
    width: 100%;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .signupContentsLeft {
      margin: 0;
    }
  }

  /* ABOUT/TERMS/PRIVACY/CONTACTUS================================================== */
  .aboutBackground{
    z-index: -1;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    right: 0;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .aboutBackground {
      object-fit: cover;
      height: 1000px;
    }
  }
  .aboutTitle{
    font-size: 4em;
    color: white;
    text-shadow: 0px 0px 5px black;
    text-align: center;
    margin: 150px 0px 150px 0px;
  }
  .aboutContent{
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .aboutContentsTitle{
    font-size: 1.5em;
    margin: 30px 0 20px 0px;
  }
  .aboutContentsBoletpoints{
    margin-left: 45px;
  }

  /* footer=================================================================== */
  .landingSectionfooter {
    background-color: rgba(49, 49, 49, 0.9);
    height: 120px;
    align-content: center;
    box-shadow: 0 0 4px gainsboro;
    bottom: 0;
    margin-top: 120px;
  width: 100%;
  }

  .footerRowOne{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footerRowTwo{
    display: flex;
    flex-direction: row;
  }
  /* links=========================================== */
  .navbarLinks{
    text-decoration: none;
    color: rgb(175, 175, 175);
    text-shadow: 0 0 2px gray;
    /* font-weight: bold; */
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .navbarLinks {
      font-weight: normal;
    }
  }
  .navbarLinks:hover{
    color: rgb(255, 255, 255);
    text-shadow: 0 0 2px black;
  }
  .signupLinks{
    text-decoration: none;
    color: rgb(13, 86, 221);
    /* text-shadow: 0 0 2px gray; */
    font-weight: bold;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .signupLinks {
      font-weight: normal;
    }
  }
  .signupLinks:hover{
    color: rgb(126, 178, 255);
    /* text-shadow: 0 0 2px black; */
  }
  .BrandNameSignup{
    width: 30%;
    margin-top: 40px;
  }
  .BrandNameSignin{
    width: 30%;
    margin-bottom: 30px;
  }

  .footerLinks{
    text-decoration: none;
    color: rgb(114, 155, 231);
    text-shadow: 0 0 2px gray;
    font-weight: bolder;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .footerLinks {
      font-weight: normal;
    }
  }
  .footerLinks:hover{
    color: rgb(44, 121, 0);
    text-shadow: 0 0 2px black;
  }
  .infoLink{
    text-decoration: none;
    color: rgb(38, 56, 211);
    text-shadow: 0 0 2px gray;
    font-weight: bolder;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .infoLink {
      font-weight: normal;
    }
  }
  .infoLink:hover{
    color: rgb(255, 255, 255);
    text-shadow: 0 0 2px black;
  }
  .successLink{
    text-decoration: none;
    color: rgb(114, 155, 231);
    text-shadow: 0 0 2px gray;
    font-weight: bold;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .successLink {
      font-weight: normal;
    }
  }
  .successLink:hover{
  color: rgb(34, 155, 84);
    text-shadow: 0 0 2px black;
  }
  /* landing==================================================================== */
  .LandingLinks{
    color: #eeeef1;
  }
  .buildingItemImage{
    width: 100%;
    height: 200px
  }
  .cantFindBuilding{
    float: right;
    margin-top: 10px;
    color: #555;
  }
  .cantFindBuilding:hover{
    color: black;
    font-size: 0.98em;
  }
  .landingSectionForms {
    border-radius: 3px;
    margin: 150px 0 150px 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 30px;
  }
  .landingSlideImage{
    height: 530px;
    width: 100%;
  }
  @media only screen and (max-width: 770px) and (min-width: 320px) {
    .landingSlideImage {
      object-fit: cover;
    }
  }
  .landingAbout3 {
    font-size: 1.3em;
    font-weight: bold;
    margin: 20px 20px;
    text-shadow: 0px 0px 2px rgb(0, 0, 0);
    color: white;
  }
  @media only screen and (max-width: 770px) and (min-width: 320px) {
    .landingAbout3 {
      font-size: 1.07em;
      font-weight: bold;
      margin: 10px 10px;
      text-shadow: 0px 0px 2px rgb(0, 0, 0);
      color: white;
    }
  }
  .landingAbout2 {
    text-shadow: 0px 0px 1px black;
    color: white;
  }
  .buildingGridItems{
    margin: 0px 0px 40px 0px;
  }
  .landingBuildingButton {
    margin: 0;
  }
  .landingBuildingText {
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-shadow: 0 0 4px black;
    float: inline-end;
  }
  .landingBuildingLink{
    color: rgb(255, 255, 255);
    font-size: 1.1em;
    text-shadow: 0 0 2px black;
    position: absolute;
    bottom: 0;
  }
  .landingBuildingLink:hover{
    color: white;
    text-shadow: 0 0 5px black;
    text-decoration: none;
  }
 .landingBuildingImg:hover{
  box-shadow: 0 0 5px gray;
  }
  /* lANDING SEARCH FORM PAGE ============================================================ */
  .SearchFormsLanding{
    padding-bottom: 40px;
  }
  .buildingItemTitlle{
    color:black;
  }
  .searchFormLable{
    padding: 70px 0 20px 0;
    font-size: 20px;
    color:rgb(122, 122, 122);
    text-shadow: 1px 1px 4px rgb(190, 190, 190);
  }
  /* THE BUILDING PAGE================================================================= */
  .theBuildingPageImages{
    height: 500px;
    width: 100%;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .theBuildingPageImages {
      height: 350px;
    }
  }
  .buildingInfoPic {
    border-radius: 5px;
    margin: 100px 0 0px 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 20px;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .buildingInfoPic {
      height: 400px;
      margin: 0;
    }
  }
  /* Forgot page ======================================================================== */
  .forgetPageText1{
   color:white;
  }
  /* Footer ======================================================================== */
  .footerHr{
    width: 100%;
    display: block;
    border: 0;
    border-top: 1px solid black;
    padding: 0;
  }
  .footerLobbea{
    margin-right: 40%;
    color: #bbbbbf;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .footerLobbea {
      font-size: 0.6em;
    }
  }
  .footerIcons{
    width: 30%;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .footerIcons {
      width: 50%;
    }
  }

  .footerIcons2{
    width: 30%;
    margin-left: 20px;
  }
  @media only screen and (max-width: 600px) and (min-width: 320px) {
    .footerIcons2 {
      width: 50%;
      margin-right: 0px;
    }
  }
  footer{
    border-top: 1px solid gray;
  }

  .PageNotFound{
    margin:10% 20%;
  }

  .dropdown-menu.show {
    left: inherit!important;
    right: 0!important;
    top: 100%!important;
    transform: none!important;
  }

  .modal-like {
    .mainFeedBox {
      margin-bottom: .5rem;
      box-shadow: none;
      > div {
        display: inline-block;
      }
    }
  }

  .sell-item {
    a {
      text-decoration: none;
      color: #333;
      &:hover {
        figure {
          border-radius: 10px;
          svg,
          &:before {
            opacity: 1;
          }
        }
      }
    }

    figure {
      overflow: hidden;
      transition: .2s;
      margin-bottom: 0.5rem;
      position: relative;
      svg {
        margin: -16px 0 0 -16px;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 10;
        color: #fff;
        font-size: 2rem;
        opacity: 0;
        transition: .2s;
      }

      &:before {
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .3);
        opacity: 0;
        transition: .2s;
      }
    }

    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .item-page {
    .carousel-item img {
      width: 100%;
    }
  }

  .carousel-gallery-item {
    background-color: #ddd;

    .close-item {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 200;
      color: #000;
      border-radius: 50%;
      svg {
        cursor: pointer;
        font-size: 1.5rem;
      }
    }

    img {
      width: 100%;
      height: 100vh;
      object-fit: contain;
    }

    .carousel-control-prev,
    .carousel-control-next {
      transition: .2s;
      svg {
        cursor: pointer;
      }

      &:hover {
        background: rgba(255, 255, 255, .2)
      }
    }


  }




  .item-page-content {
    .seller {
      width: 100%;
      margin-bottom: 1rem;
      padding: .5rem 0;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }



    .message-to-seller {
      .compose {
        background: none;
        padding: 0;
      }
    }

    textarea {
      width: 100%;
      padding: .5rem;
      resize: none;
    }

    button {
      width: 100%;
    }
  }

  .form-item-preview {
    display: flex;
    flex-wrap: wrap;
    img {
      width: 100px;
      height: 100px;
      margin: 0.25rem;
      padding: .25rem;
      border: 1px solid #ddd;
      object-fit: contain;
    }
  }
  .map-icon{
    color:blue
  }
  .map-marker {
    position: 'absolute',
    width: 40,
    height: 40,
    left: -40 / 2,
    top: -40 / 2,
    border: '5px solid #f44336',
    borderRadius: 40,
    backgroundColor: 'white',
    textAlign: 'center',
    color: '#3f51b5',
    fontSize: 16,
    font-weight: 'bolder',
    padding: 4
  }

.my-groups-aside {
  margin-top: -20px;
  padding: 0;
  box-shadow: 10px 0 10px 1px #ddd;
  color: #fff;
  background: #343a40;

  > nav {
    margin-top: 1px;
    padding: 30px 15px 100px;
    /* border-top: 1px solid #666; */
    overflow: initial;
    left: 0;
    background: #343a40;
  }

  h4 {
    font-size: 18px;
    font-size: 400;
  }
}

  .my-groups-sidebar {
    min-height: 250px;
    overflow-y: auto;
    flex: 1;
    li {
      width: 100%;
      padding: 10px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      transition: .2s;
      &:hover {
        background: #444;
      }

      &.group-active {
        background: #444;
      }

      figure {
        margin: 0;
      }

      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 5px;
        margin-right: 10px;
        background-color: #fff;
      }

      a {
        color: #fff;
        &:hover {
          text-decoration: none;
        }
      }

      p {
        margin: 0;
      }

      strong {
        font-weight: 500;
      }
    }
  }

  .groups-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    .my-group-item {
      display: none;
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      .my-group-item {
        display: block;
      }
    }

    @media (max-width: 460px) {
      grid-template-columns: repeat(1, 1fr);
      .my-group-item {
        display: block;
      }
    }

    figure {
      text-align: center;
      background-color: #343a40;
    }

    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }

    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }

    > div {
      padding: 15px;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      box-shadow: 0 0 10px #ddd;
      button {
        margin-top: auto;
      }
    }
  }

  button.btn-link-hover {
    &:hover {
      background: rgba(25, 118, 210, 0.04);
      text-decoration: none;
    }
 }
`;
