import {
  FEEDS_OPENED
} from '../actionTypes';

const initialState = [];
export const feedsOpened = (state = initialState, action) => {
  switch (action.type) {
    case FEEDS_OPENED:
      const id = action.feedId;
      let formsOpened = state;
      if (state.includes(id)) {
        formsOpened = formsOpened.filter(item => item !== id);
      } else {
        formsOpened.push(id);
      }
      return [...formsOpened];
    default:
      return state;
  }
};

export const mountPostFeed = (feed, action) => {
  return feed.map(feed => {
    if (action.comment) {
      return mountCommentFeed(feed, action);
    } else {
      if (feed._id === action.feed._id) {
        return { ...action.feed };
      }
      return { ...feed };
    }
  });
};

export const mountCommentFeed = (feed, action) => {
  return {
    ...feed,
    comments: feed.comments.map(comment => {
      if (comment._id === action.comment._id) {
        return { ...action.comment };
      }
      return { ...comment };
    })
  };
}

export const mountLikeFeed = (feed, action) => {
  return feed.map(feed => {
    if (feed._id === action.feedId) {
      return {
        ...feed,
        likes: action.likes,
      };
    }
    return { ...feed };
  })
}

export const mountRemoveCommentFeed = (feed, action) => {
  return feed.map(feed => {
    if (feed._id === action.feedId) {
      return {
        ...feed,
        comments: feed.comments.filter(comment => comment._id !== action.commentId)
      }
    }
    return { ...feed };
  })
}
