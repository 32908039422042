import styled from 'styled-components';
import navHome from '../images/home-icon.png';
import navInbox from '../images/messages-icon.png';
import navNeighbors from '../images/neighbor-list.png';
import navGroups from '../images/group-icon.png';
import navSell from '../images/market-icon2.png';
import navLendBorrow from '../images/borrow-icon.png';
import navEvents from '../images/events-icon.png';
import navFeedback from '../images/feedback-icon.png';

export const AsideColumn = styled.nav`
  width: 100%;
  height: 100%;
  padding: 0 0 100px;
  border: 0;
  border-radius: 5px;
  position: fixed !important;
  left: 0;
  z-index: 10;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  background: #fff;
  .aside-group-link {
    margin-bottom: 3px;
  }

  > div {
    margin: 0 0 0.5rem;
    padding: 0.5rem 0.25rem;
    border-radius: 0.25rem;
    transition: 0.2s;
    &:hover {
      background: #ddd;
    }
  }

  > a {
    margin: 0.15rem 0;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    color: #000;
    transition: 0.2s;
    border-radius: 0.25rem;
    text-decoration: none;
    font-weight: 500;
    svg {
      transition: 0.2s;
      color: #000;
      margin-right: 0.25rem;
    }

    &.active,
    &:hover {
      background: #ddd;
      svg {
        color: #336799;
      }
    }
  }

  .icon-nav {
    width: 34px;
    height: 34px;
    margin-right: 10px;
    display: inline-block;
    background: no-repeat center center;
    background-size: contain;
  }

  .icon-nav-home {
    background-image: url(${navHome});
    -webkit-filter: drop-shadow(1px 1px 2px #a9a9a9);
    filter: drop-shadow(1px 1px 2px #a9a9a9);
  }
  .icon-nav-inbox {
    background-image: url(${navInbox});
    -webkit-filter: drop-shadow(1px 1px 2px #a9a9a9);
    filter: drop-shadow(1px 1px 2px #a9a9a9);
  }
  .icon-nav-neighbors {
    background-image: url(${navNeighbors});
    -webkit-filter: drop-shadow(1px 1px 2px #a9a9a9);
    filter: drop-shadow(1px 1px 2px #a9a9a9);
  }
  .icon-nav-groups {
    background-image: url(${navGroups});
    -webkit-filter: drop-shadow(1px 1px 2px #a9a9a9);
    filter: drop-shadow(1px 1px 2px #a9a9a9);
  }
  .icon-nav-sell {
    background-image: url(${navSell});
    -webkit-filter: drop-shadow(1px 1px 2px #a9a9a9);
    filter: drop-shadow(1px 1px 2px #a9a9a9);
  }
  .icon-nav-lend-borrow {
    background-image: url(${navLendBorrow});
    -webkit-filter: drop-shadow(1px 1px 2px #a9a9a9);
    filter: drop-shadow(1px 1px 2px #a9a9a9);
  }
  .icon-nav-events {
    background-image: url(${navEvents});
    -webkit-filter: drop-shadow(1px 1px 2px #a9a9a9);
    filter: drop-shadow(1px 1px 2px #a9a9a9);
  }
  .icon-nav-feedBack {
    background-image: url(${navFeedback});
    -webkit-filter: drop-shadow(1px 1px 2px #a9a9a9);
    filter: drop-shadow(1px 1px 2px #a9a9a9);
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    width: 100%;
    height: 100%;
    position: static !important;
  }
`;

export const UserAsideEdit = styled.div`
  a,
  small {
    color: #333;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
  }

  small {
    opacity: 0;
    transition: 0.2s;
    svg {
      margin-right: 5px;
    }
  }

  &:hover {
    small {
      opacity: 1;
    }
  }
`;
