import { apiCall } from '../../services/api';
import { addError } from './errors';
import {
  POST_FEED,
  EDIT_FEED,
  LIKE_FEED,
  POST_COMMENT,
  EDIT_COMMENT,
  REMOVE_COMMENT,
  FEEDS_OPENED,
  REMOVE_FEED,
  REMOVE_EVENT_FEED,
  REMOVE_GROUP_FEED,
  EDIT_GROUP_FEED,
  EDIT_EVENT_FEED,
  EDIT_GROUP_COMMENT,
  REMOVE_GROUP_COMMENT,
  REMOVE_EVENT_COMMENT,
} from '../actionTypes';

export const postFeed = (buildingId, formData) => async (
  dispatch,
  getState,
) => {
  let { currentUser } = getState();
  const id = currentUser.user.id;
  const fd = new FormData();
  fd.append('text', formData.text);
  if(formData.image) {
    fd.append('image', formData.image);
  }

  try {
    const response = await apiCall(
      'post',
      `/api/building/feed/${buildingId}/${id}/image`,
      fd,
    );

    dispatch({
      type: POST_FEED,
      feed: response
    })
    return response;
  } catch (err) {
    return addError(err?.message);
  }
};

export const editFeed = (feedId, text, module) => {
  return async dispatch => {
    try {
      const response = await apiCall('put', `/api/building/feed/${feedId}`, {
        text,
      });

      let type = EDIT_FEED;
      if (module === "group") {
        type = EDIT_GROUP_FEED;
      } else if (module === "event") {
        type = EDIT_EVENT_FEED;
      }

      dispatch({
        type,
        feed: response,
      });

      return response;
    } catch (err) {
      addError(err?.message);
    }
  };
};

export const removeFeed = (feedId, module) => {
  return async dispatch => {
    try {
      const response = await apiCall('delete', `/api/building/feed/${feedId}`);
      if (module === "group") {
        dispatch({
          type: REMOVE_GROUP_FEED,
          id: feedId,
        });
      } else if (module === "event") {
        dispatch({
          type: REMOVE_EVENT_FEED,
          id: feedId,
        });
      } else {
        dispatch({
          type: REMOVE_FEED,
          id: feedId,
        });
      }
      return response;
    } catch (err) {
      addError(err?.message);
    }
  }
};

export const likeFeed = (feedId, userId) => {
  return async dispatch => {
    try {
      const response = await apiCall('post', `api/building/likefeed/${feedId}/${userId}`);
      return dispatch({
        type: LIKE_FEED,
        feedId,
        likes: response
      })
    } catch (err) {
      addError(err?.message);
    }
  };
};

export const postComment = (feedId, text) => async (dispatch, getState) => {
  let { currentUser } = getState();
  const id = currentUser.user.id;
  const buildingId = currentUser.user.address;
  try {
    const response = await apiCall('post', `/api/building/feed/${buildingId}/${id}/comment/${feedId}`, { text });
    return dispatch({
      type: POST_COMMENT,
      feedId,
      feed: response,
    })
  } catch (err) {
    addError(err?.message);
  }
};

export const editComment = (commentId, text, module) => {
  return async dispatch => {
    try {
      const response = await apiCall(
        "put",
        `/api/building/feed/comment/${commentId}`,
        { text },
      );

      let type = EDIT_COMMENT;
      if (module === "group") {
        type = EDIT_GROUP_COMMENT;
      } else if (module === "event") {
        type = EDIT_EVENT_FEED;
      }

      dispatch({
        type,
        comment: response,
      });

      return response;
    } catch (err) {
      addError(err?.message);
    }
  };
};

export const removeComment = (commentId, feedId, module) => {
  return async dispatch => {
    try {
      await apiCall('delete', `/api/building/feed/comment/${commentId}`);

      let type = REMOVE_COMMENT;
      if (module === "group") {
        type = REMOVE_GROUP_COMMENT;
      } else if (module === "event") {
        type = REMOVE_EVENT_COMMENT;
      }


      return dispatch({
        type,
        commentId,
        feedId
      });
    } catch (err) {
      addError(err?.message);
    }
  }
};

export const openCommentForm = (feedId) => {
  return dispatch => {
    try {
      return dispatch({
        type: FEEDS_OPENED,
        feedId
      })
    } catch (err) {
      addError(err?.message);
    }
  };
};
