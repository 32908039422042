import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Button } from 'reactstrap';
import { AsideColumn } from '../../../styles/leftside';
import pi from '../../../images/default-profile-image.png';

class GroupLeftSide extends Component {
  render() {
    const { myGroups, groupActiveId, mobile, handleOpenForm, handleSearchGroup, attrBtnCreateNew, internalPage  } = this.props;
    return (
      <Col className={mobile ? 'p-0' : 'd-none d-md-block col-md-3 my-groups-aside'}>
        <AsideColumn className={mobile ? '' : 'col-3'}>
          {!internalPage && (
            <>
              <h4>Groups</h4>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={e => handleSearchGroup(e.target.value)}
                {...attrBtnCreateNew}
                />
              <hr/>
              <Button
              block
              color="success"
              onClick={handleOpenForm}
              {...attrBtnCreateNew}
              >
                Make a new Group
              </Button>
              <hr/>
            </>
          )}
          <h4>My Groups</h4>
          <ol className="my-groups-sidebar">
          {myGroups.filter(group => group._id !== null).map(group => (
            <li key={group._id} className={(groupActiveId === group._id) ? 'group-active' : ''}>
              <figure>
                <Link to={{ pathname: `/group/${group._id}` }}><img src={group.image || pi} alt={group.name} /></Link>
              </figure>
              <Link to={{ pathname: `/group/${group._id}` }}>
                <strong>{group.title}</strong>
                {/* <p><small>Last active 3 weeks ago</small></p> */}
              </Link>
            </li>
            )
          )}
          </ol>
        </AsideColumn>
      </Col>
    );
  }
}
export default GroupLeftSide;
