import styled from 'styled-components';
// import bgCover from '../images/header2.jpg';


export const Cover = styled.header`
  padding: 0 15px;
  position: relative;
  figure {
    width: 100%;
    margin: 0;
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }

  .profile-image {
    position: absolute;
    left: 30px;
    bottom: 70px;
    z-index: 5;

    &:before,
    img {
      width: 150px;
      height: 150px;
      margin: 0;
      border: 5px solid #ddd;
      border-radius: 50%;
      object-fit: cover;
      text-align: center;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 30px;
      color: #fff;
      margin-top: -15px;
      margin-left: -15px;
      z-index: 5;
      opacity: 0;
      transition: .2s;
    }

    &:before {
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, .8);
      opacity: 0;
      transition: .2s;
    }

    &.edit-image {
      cursor: pointer;
      &:hover {
        &:before,
        svg {
          opacity: 1;
        }
      }
    }
  }

  section {
    width: 100%;
    padding: 20px 0;

    &, > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    > div button {
      margin-left: 10px;
    }
  }
`
