import React from 'react';
import PageRentAndBorrow from './page';
import Navbar from '../Navbar';
import withAuth from '../../hocs/withAuth';

const TheBuildingBorrows = props => {
  return (
    <div>
      <div className="navbarDiv">
        <Navbar />
      </div>
      <div className="content-system">
        <PageRentAndBorrow {...props} />
      </div>
    </div>
  );
};

export default withAuth(TheBuildingBorrows);
