import { apiCall } from '../../services/api';
import { addError } from './errors';
import { addSuccess } from './success';
import { LOAD_BORROWS, POST_BORROW, REMOVE_BORROW } from '../actionTypes';

// LOAD BORROW ACTION
export const loadBorrows = buildingBorrows => ({
  type: LOAD_BORROWS,
  buildingBorrows,
});

export const fetchBorrows = buildingId => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiCall('get', `api/building/borrows/${buildingId}`);
        dispatch(loadBorrows(res));
        resolve(); // indicate that the API call succeeded
      } catch (err) {
        dispatch(addError(err?.message));
        reject(); // indicate the API call failed
      }
    });
  };
};

export const borrowUpdate = newBorrow => ({
  type: POST_BORROW,
  newBorrow,
});

//Action to post a feed
export const postBorrow =
  (buildingId, formData) => async (dispatch, getState) => {
    let { currentUser } = getState();
    const id = currentUser.user.id;
    const fd = new FormData();
    fd.append('image', formData.image);
    fd.append('type', formData.type);
    fd.append('price', formData.price);
    fd.append('category', formData.category);
    fd.append('description', formData.description);
    fd.append('dateOfBorrow', formData.dateOfBorrow);
    fd.append('dateOfReturn', formData.dateOfReturn);
    fd.append('title', formData.title);
    try {
      const res = await apiCall(
        'post',
        `/api/building/borrows/${buildingId}/${id}`,
        fd,
      );
      dispatch(borrowUpdate(res));
      dispatch(addSuccess('You successfully created an item for rent.'));
    } catch (err) {
      return addError(err?.message);
    }
  };
// TO REMOVE THE BORROW
export const removeB = id => ({
  type: REMOVE_BORROW,
  id,
});
export const removeBorrow = borrowId => {
  return async dispatch => {
    try {
      await apiCall('delete', `api/building/borrows/${borrowId}`);
      return dispatch(removeB(borrowId));
    } catch (err) {
      addError(err?.message);
    }
  };
};
