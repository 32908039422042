import React, { useState } from 'react';
import axios from 'axios';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
	MainContainer,
	ChatContainer,
	MessageList,
	Message,
	MessageInput,
	TypingIndicator,
} from '@chatscope/chat-ui-kit-react';

const YourComponent = () => {
	const [inputText, setInputText] = useState('');
	const [generatedText, setGeneratedText] = useState('');
	const [typing, setTyping] = useState(false);
	const [messages, setMessages] = useState([
		{
			message: 'Hello, I am chatGPT',
			sender: 'ChatGPT',
		},
	]);
	const API_KEY = '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';

	const handleSend = async (message) => {
		const newMessage = {
			message: message,
			sender: 'user',
			direction: 'outGoing',
		};
		const newMessages = [...messages, newMessage];
		// update our messages state
		setMessages(newMessages);
		// se the typing indicator
		setTyping(true);
		// process message with chatGPT
		await processMessageToChatGPT(newMessages);
	};

	async function processMessageToChatGPT(chatMessages) {
		let apiMessage = chatMessages.map((messageObject) => {
			let role = '';

			if (messageObject.sender === 'ChatGPT') {
				role = 'assistant';
			} else {
				role = 'user';
			}
			return { role: role, content: messageObject.message };
		});
		const systemMessage = {
			role: 'system',
			content: 'explain this messages like i am 15 years old',
		};
		const apiRequestBody = {
			model: 'gpt-4-turbo-preview', // Use the gpt-3.5-turbo model
			messages: [systemMessage, ...apiMessage],
		};

		await fetch('https://api.openai.com/v1/chat/completions', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + API_KEY,
			},
			body: JSON.stringify(apiRequestBody),
		})
			.then((data) => {
				return data.json();
			})
			.then((data) => {
				console.log(data);
				setMessages([
					...chatMessages,
					{ message: data.choices[0].message.content, sender: 'ChatGPT' },
				]);
				setTyping(false);
			});
	}

	// const generateText = async () => {
	// 	try {
	// 		const response = await axios.post(
	// 			'https://api.openai.com/v1/chat/completions',
	// 			{
	// 				model: 'gpt-4-0125-preview', // Use the GPT-3.5-turbo model
	// 				prompt: inputText,
	// 				max_tokens: 50,
	// 				temperature: 0.7,
	// 				n: 1,
	// 			},
	// 			{
	// 				headers: {
	// 					'Content-Type': 'application/json',
	// 					Authorization: `Bearer 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`,
	// 				},
	// 			}
	// 		);

	// 		setGeneratedText(response.data.choices[0].text);
	// 	} catch (error) {
	// 		console.error('Error generating text:', error);
	// 	}
	// };

	return (
		<div className='App'>
			<div style={{ position: 'relative', height: '800px', width: '100%' }}>
				<MainContainer>
					<ChatContainer>
						<MessageList
							typingIndicator={
								typing ? <TypingIndicator content='chatGPT is typing' /> : null
							}>
							{messages.map((message, i) => {
								return <Message key={i} model={message} />;
							})}
						</MessageList>
						<MessageInput placeholder='Type Message Here' onSend={handleSend} />
					</ChatContainer>
				</MainContainer>
			</div>
		</div>
	);
};

export default YourComponent;
{
	/* <input
				type='text'
				value={inputText}
				onChange={(e) => setInputText(e.target.value)}
				placeholder='Enter your text here'
			/>
			<button onClick={generateText}>Generate Text</button>
			<p>{generatedText}</p> */
}
