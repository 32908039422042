import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { logout } from '../store/actions/auth';

const NavBarLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let id = useParams();
  const user = useSelector(state => state.currentUser.user);
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        caret
        size="sm"
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#fff',
          background: 'transparent',
          border: 0,
          boxShadow: 'none',
        }}
      >
        <img
          src={user.image}
          alt={user.email}
          className="img-fluid"
          style={{
            width: 34,
            height: 34,
            marginRight: 5,
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />{' '}
        <strong
          className="text-truncate d-inline-block"
          style={{ maxWidth: 80 }}
        >
          {user.firstName}
        </strong>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            navigate(`/profile/${user.id}`);
            navigate(0);
          }}
        >
          My Profile
        </DropdownItem>
        <DropdownItem onClick={() => navigate(`/inbox`)}>Inbox</DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default NavBarLogout;
