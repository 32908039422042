import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import * as yup from 'yup';
import { useFormik } from 'formik';

import { FormControl, TextField } from '@mui/material';
import { Button } from 'reactstrap';
import { AiOutlineLoading } from 'react-icons/ai';

import { buildingCode } from '../../store/actions/building';
import {
  saveBuildingStorage,
  removeBuildingStorage,
} from '../../store/actions/landing';

const schema = yup.object({
  code: yup
    .string()
    .nullable()
    .length(4, 'Must contain 4 characters.')
    .required('This a required field'),
});

const SignUpFindCode = ({ handleStepper }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const buildingLS = useSelector(state => state.landing.building);
  const building = useSelector(state => state.building);
  const foundBuilding = useSelector(state => state.foundBuilding);
  const [founded, setFounded] = useState(false);

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: schema,
    onSubmit: async formData => {
      const response = await dispatch(buildingCode(formData));
      if (response?.id) {
        navigate(`/signup/${response.id}`, { replace: true });
        setFounded(true);
        dispatch(saveBuildingStorage(response.id, formData.code));
      }
      return;
    },
  });
  useEffect(() => {
    if (buildingLS.id === id || (!id && buildingLS.id)) {
      setFounded(true);
      formik.setFieldValue('code', buildingLS.code);
    }
  }, [buildingLS.id]);

  const handleReset = () => {
    setFounded(false);
    formik.setFieldValue('code', '');
    dispatch(removeBuildingStorage());
    navigate(`/signup`);
  };

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <FormControl fullWidth={true}>
        <TextField
          error={formik.errors.code}
          onChange={e => formik.setFieldValue('code', e.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.code}
          fullWidth
          label="Enter your building code"
          variant="outlined"
          helperText={formik.errors.code}
        />
      </FormControl>
      <div className="row justify-content-end mt-5">
        {!founded ? (
          <div className="col-12 col-md-6">
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              type="submit"
              style={{ width: '100%' }}
            >
              {formik.isSubmitting ? (
                <AiOutlineLoading className="spinner" />
              ) : (
                'Verify'
              )}
            </Button>
          </div>
        ) : (
          <>
            <div className="col-12 col-md-6">
              <Button
                color="danger"
                type="button"
                onClick={handleReset}
                style={{ width: '100%' }}
              >
                Not my building
              </Button>
            </div>
            <div className="col-12 col-md-6">
              <Button
                color="primary"
                type="button"
                onClick={() => handleStepper(true)}
                style={{ width: '100%' }}
              >
                Continue
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default SignUpFindCode;
