import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import pi from '../../../../images/default-profile-image.jpg';

// the main feed component
const Item = ({
  id,
  image,
  title,
  price,
  // description,
  // author,
  // history,
  // onDelete,
  // isCorrectUser,
  // currentUserId,
  // audiencId,
}) => (
  <Col md="3" sm="6" xs="6" className="sell-item">
    <Link to={{ pathname: `/item/${id}` }}>
      <figure className="items-image">
        <img src={image[0] || pi} alt={`${title}`} />
      </figure>

      <strong>{price} $</strong>
      <p>{title}</p>
    </Link>
  </Col>
);

export default Item;
